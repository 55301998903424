import React, { useState, useEffect } from 'react'
import noImage from '../../../assets/images/image-up.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { postSlice } from '../../../slice/postSlice';
import { sendUserData } from '../../../slice/postSlice';
import { getDataFromApi } from '../../../slice/getDataSlice';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Company_Header from '../company/company-header'
import MetaTag from '../meta-tag';

function Profile() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  },[dispatch]);

  const [imageSrc, setImageSrc] = useState('');
  const [profile, setProfile] = useState([]);
  const [userProfileData, setUserProfileData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    pincode: '',
    state: '',
    city: '',
    city_id: '',
    state_id: '',
    country_id: '',
    qualification: '',
    total_experience: '',
    designation: '',
    date_of_birth: '',
    gender: ''
  });
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [stateid, setStateid] = useState(0);
  const [date, setDate] = useState(new Date());
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (date) {
      const d = new Date(date).toLocaleDateString('fr-FR');
      handleInputChange(null, d);
    }
  }, [date]);
  useEffect(() => {
    const fetchData = () => {

      const dynamicUrl = process.env.REACT_APP_GET_PROFILE_API_URL;
      const login_token = localStorage.getItem('items');

      dispatch(sendUserData({ token: login_token, pageurl: dynamicUrl }))
        .then((action) => {
          const getProfileData = action.payload;

          if (getProfileData) {
            setProfile(getProfileData.data);
            console.log('l-64', getProfileData.data);
            setDate(getProfileData.data.date_of_birth)
            setUserProfileData(
              {
                name: getProfileData.data.name,
                phone: getProfileData.data.phone,
                email: getProfileData.data.email,
                address: getProfileData.data.address,
                pincode: getProfileData.data.pincode,
                state: getProfileData.data.state,
                city: getProfileData.data.city,
                city_id: getProfileData.data.city_id,
                state_id: getProfileData.data.state_id,
                country_id: getProfileData.data.country_id,
                qualification: getProfileData.data.qualification,
                total_experience: getProfileData.data.total_experience,
                designation: getProfileData.data.designation,
                date_of_birth: date ? date : getProfileData.data.date_of_birth,
                gender: getProfileData.data.gender,
              }
            )
            setLoading(true);
        
            setStateid(getProfileData.data.state_id);

          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);


  //fetch state data
  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_STATE_API_URL;
      dispatch(getDataFromApi({ Url: dynamicUrl, country_id: 101 }))
        .then((action) => {
          const updatedStateData = action.payload;
          if (updatedStateData) {
            setState(updatedStateData.data);
          } else {
            setState([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);

  //change stateid
  const changeStateId = (e) => {
    setStateid(e.target.value);
  }

  //fetch city data
  useEffect(() => {
    const fetchData = () => {
      console.log(stateid);
      const dynamicUrl = process.env.REACT_APP_CITY_API_URL;
      if (stateid > 0) {
        dispatch(getDataFromApi({ Url: dynamicUrl, country_id: 101, state_id: stateid }))
          .then((action) => {
            const updatedCityData = action.payload;
            if (updatedCityData) {
              setCity(updatedCityData.data);

            } else {
              setCity([]);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }
    }
    fetchData();
  }, [stateid]);

  const url = process.env.REACT_APP_UPDATE_PROFILE_API_URL

  const handleDateChange = (selectedDate) => {
    // Check if selectedDate is valid
    if (!selectedDate) {
      console.error("Invalid date");
      return;
    }
    setDate(selectedDate);

    // Format the date as yyyy-mm-dd
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
    const day = String(selectedDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    console.log('l-178',formattedDate);
    // Update the state with the formatted date
    setUserProfileData({
      ...userProfileData,
      date_of_birth: formattedDate // Assuming date_of_birth is the key for date of birth in userProfileData
    });

    console.log('l-174', formattedDate);
  };


  const handleInputChange = (e) => {
    console.log('l-180', e, e?.target?.name, e?.target?.value);


    setUserProfileData({
      ...userProfileData,
      [e?.target?.name]: e?.target?.value,
    });

  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('l-191', userProfileData);
    try {
      dispatch(sendUserData({ data: userProfileData, pageurl: url }))
        .then((action) => {
          withReactContent(Swal).fire({
            title: <i>profile updated successfully</i>,
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

    } catch (error) {
      console.error('Error sending data to API:', error.message);
    }
  };

  return (
    <>
      <MetaTag title="My Profile on Spot In Job Consultancy" name="description" content="Your Profile" />

    
      <Company_Header  title={userProfileData?.name ? userProfileData?.name : 'Your Profile'} description={userProfileData.designation ?userProfileData.designation : 'Update Your Profile...' } contactus_header="contactus_header"/>
      
        <section className='flat-dashboard-setting flat-dashboard-setting2'>

          <div className='container'>
            <div className='themes-container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='profile-setting bg-white'>


                    <div className='form-infor-profile'>
                      <h1 className='title-info h3'>Information</h1>
                      {loading ?
                        <form className="form" onSubmit={handleSubmit}>
                          <div className='col-md-4 d-flex justify-content-between mb-4 d-none'>
                            <div className='img-box relative'>
                              {imageSrc ?
                                <img className='avatar' id="profileimg" alt='Selected Image' src={imageSrc}></img>
                                : <img className='avatar' id="profileimg" alt='Selected Image' src={noImage}></img>}

                            </div>
                            <div id="upload-profile" >
                              <h5 className='fw-6'>Upload a new avatar: </h5>
                              <h6>JPG 80x80px</h6>
                              <input
                                type='file'
                                id="tf-upload-img"
                                className='up-file'
                                name='profile'
                                onChange={handleImageChange}
                              />
                            </div>

                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Full Name</label>
                                <input type="text" placeholder="Enter Your Name" name='name' value={userProfileData.name} className={profile.name && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Date Of Birth</label>
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  name='date_of_birth'
                                  value={date}
                                  selected={date}
                                  onChange={(date) => handleDateChange(date)} // Pass the date directly to handleInputChange
                                />

                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Phone Number</label>
                                <input type="text" name='phone' placeholder="Phone Number" value={userProfileData.phone} className={profile.phone && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Email</label>
                                <input type="email" name='email' placeholder="Enter Email" value={userProfileData.email} className={profile.email && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Gender</label>
                                <select name="gender" id="gender" className="react-dropdown select-location" onChange={handleInputChange}>
                                  {(!userProfileData.gender) && <option value="">Select Gender</option>}
                                  {(userProfileData.gender == 'female') ? <option value="female" selected>Female</option> : <option value="female">Female</option>}
                                  {(userProfileData.gender == 'male') ? <option value="male" selected>Male</option> : <option value="male">Male</option>}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Address</label>
                                <input type="text" name='address' placeholder="Enter Address" value={userProfileData.address} className={(profile.address) && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>


                            <div className="col-md-6">

                              <div className="form-group">
                                <label for="name">State</label>
                                <select name="state_id" id="state" className="react-dropdown select-location" onChange={changeStateId}>
                                  {(!profile.state_id) ? <option value="">Select State</option> : <option value={userProfileData.state_id} selected>{userProfileData.state}</option>}
                                  {
                                    state.map((items) =>
                                      <option key={items.id}
                                        value={items.id}
                                        className="react-dropdown select-location"

                                      >
                                        {items.name}
                                      </option>
                                    )

                                  }
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">

                              <div className="form-group">
                                <label for="name">City</label>
                                <select name="city_id" id="city" className="react-dropdown select-location" onChange={handleInputChange}>
                                  {(!profile.city_id) && <option value="">Select city</option>}
                                  {
                                    (stateid && city) ?

                                      city.map((items) =>
                                        <option key={items.id}
                                          value={items.id}
                                          className="react-dropdown select-location"

                                        >
                                          {items.name}
                                        </option>
                                      ) :
                                      <option value={userProfileData.city_id} selected>{userProfileData.city}</option>
                                  }

                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Pin Code</label>
                                <input type="text" name='pincode' placeholder="Enter Pincode" value={userProfileData.pincode} className={(profile.pincode) && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Qualification</label>

                                <input type="text" name='qualification' placeholder="Enter Qualification" value={userProfileData.qualification} className={profile.qualification && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Experience time</label>
                                <input type="text" name="total_experience" placeholder="Enter Experience time" value={userProfileData.total_experience} className={userProfileData.total_experience && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label for="name">Job Title</label>
                                <input type="text" name="designation" placeholder="Enter Job Title" value={userProfileData.designation} className={profile.designation && 'text-dark h6'} onChange={handleInputChange} />
                              </div>
                            </div>
                            <div className='col-md-4 '>
                              <div className='mt-4 profile-button col-md-4'>
                                <button>Save Profile</button>
                              </div>
                            </div>
                          </div>
                        </form>
                        :
                        <div className='spinner-container'>
   
                          <div className="spinner" />
                        </div>
   
                       }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
    </>
  )
}

export default Profile