import React, { useState } from 'react'
import Search from '../components/main/search'
import FeaturedJobs from '../components/main/featured_jobs'
// import handlePopup from '../components/main/featured_jobs/pop-up'
import Trusted_by_employee from '../components/main/trusted_by_employee';
import MetaTag from '../components/main/meta-tag';
import Breadcrumb from '../components/google_schema/breadcrumb';
import { useParams } from 'react-router';
import Commingsoon from '../components/main/comming_soon';



function Find_jobs() {

  const base_url = process.env.REACT_APP_LOCAL_URL;
  const home = process.env.REACT_APP_ROUTES_HOME;
  const find_jobs = process.env.REACT_APP_ROUTES_FIND_JOBS;

  const breadcrumb = [
    {
      home: base_url + home,
      find_jobs: base_url + find_jobs,
    }
  ];

  return (
    <>

      <div>
        <MetaTag title="Career & Job Recruitment in India | Spot In Job Consultancy" name="find jobs page" content="Explore exciting job opportunities at YourCompany. Browse and apply for jobs in various fields and locations. Start your career with us!" />
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <div>
        
        <FeaturedJobs itemPerPage="12"/>
        <Trusted_by_employee />
      </div>
    </>
  )
}

export default Find_jobs