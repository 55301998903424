// store.js

import { configureStore } from '@reduxjs/toolkit';

import getDataReducer from '../slice/getDataSlice'; 
import { sendUserData } from '../slice/postSlice';
import { thunk } from 'redux-thunk';

const store = configureStore({
  reducer: {
    getdata: getDataReducer,
    sendData:sendUserData,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    thunk,
    serializableCheck: false,
  }),
});

export default store;
