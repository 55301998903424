import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch } from 'react-redux';
import { sendUserData } from '../../../slice/postSlice';
import Cookies from 'js-cookie';
import LinkedIn from './linkedIn';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { GoogleLogin } from '@react-oauth/google';
import CustomGoogleSignInButton from './google_login';
import Header from '../company/company-header';
import MetaTag from '../meta-tag';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [candidateLoginData, setCandidateLoginData] = useState({
    email: Cookies.get('remember_id') || '',
    password: Cookies.get('remember_password') || '',
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [candidateLoginWithFacebookData, setCandidateLoginWithFacebookData] = useState({
    name: '',
    email: '',
    register_by: '',
    oauth_id: '',
    oauth_type: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCandidateLoginData({
      ...candidateLoginData,
      [name]: value,
    });
  };

  const sendDataToApi = (data, url) => {
    dispatch(sendUserData({ data, pageurl: url }))
      .then((action) => {
        const result = action.payload.data;
        localStorage.setItem('items', JSON.stringify(result.token));
        const items = JSON.parse(localStorage.getItem('items'));
        sessionStorage.setItem('login_user_desigantion', JSON.stringify(result.designation));
        sessionStorage.setItem('login_user_id', JSON.stringify(result.user_id));

        if (items) {
          if (window.history.length > 2) {
            window.history.back();
          } else {
            navigate(process.env.REACT_APP_ROUTES_HOME);
          }
        }
      })
      .catch(() => {
        withReactContent(Swal).fire({
          title: "Somethings went wrong!",
          text: "Try Again"
        });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = process.env.REACT_APP_LOGIN_API_URL;
    sendDataToApi(candidateLoginData, url);
  };

  const responseFacebook = (response) => {
    if (response && response.name && response.id) {
      setCandidateLoginWithFacebookData({
        name: response.name,
        email: 'demo@gmail.com',
        register_by: 'candidate',
        oauth_id: response.id,
        oauth_type: 'facebook'
      });
    }
  };

  useEffect(() => {
    if (candidateLoginWithFacebookData.name) {
      sendDataToApi(candidateLoginWithFacebookData, process.env.REACT_APP_EASY_SOCIAL_LOGIN_API_URL);
    }
  }, [candidateLoginWithFacebookData]);

  return (
    <>
      <MetaTag title="Login in Spot In Job Consultancy" name="description" content="Connect With Us For Start Your Better Career" />
      <Header title="Welcome Back!!!" description="Login to Your Account" contactus_header="contactus_header" />

      <section className="mt-5 account-section tf-slider sl7 over-flow-hidden opportunityType">
        <div className="tf-container">
          <div className="row">
            <div className="wd-form-login">
              <h2 className='h4'>Log In</h2>
              <form onSubmit={handleSubmit}>
                <div className="ip">
                  <label>Email address<span> *</span></label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={candidateLoginData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="ip">
                  <label>Password<span>*</span></label>
                  <div className="inputs-group auth-pass-inputgroup">
                    <input
                      type={showPass ? "text" : "password"}
                      className="input-form password-input"
                      placeholder="Password"
                      name="password"
                      value={candidateLoginData.password}
                      onChange={handleInputChange}
                      required
                    />
                    <Link
                      title={showPass ? "Hide Password" : "Show Password"}
                      className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                      onClick={() => setShowPass(!showPass)}
                    />
                  </div>
                </div>
                <div className="group-ant-choice">
                  <div className="sub-ip">
                    <input type="checkbox" onChange={() => setRememberMe(!rememberMe)} />
                    Remember me
                  </div>
                  <Link title='Forgot password' to={process.env.REACT_APP_ROUTES_FORGOT_PASSWORD} className="forgot">
                    Forgot password?
                  </Link>
                </div>
                <button className='wd-form-login-button' type="submit">Login</button>

                <p className='text-secondary m-0 mt-3'>Or Login With Using This App</p>
                <LinkedIn />
                <FacebookLogin
                  appId="710034944536349"
                  fields="name,email,picture"
                  render={renderProps => (
                    <button onClick={renderProps.onClick} className='border-0 p-0'>
                      <svg height="32px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                        <linearGradient id="a" x1="-277.375" x2="-277.375" y1="406.6018" y2="407.5726" gradientTransform="matrix(40 0 0 -39.7778 11115.001 16212.334)" gradientUnits="userSpaceOnUse">
                          <stop offset="0" stopColor="#0062e0" />
                          <stop offset="1" stopColor="#19afff" />
                        </linearGradient>
                        <path fill="url(#a)" d="M16.7 39.8C7.2 38.1 0 29.9 0 20 0 9 9 0 20 0s20 9 20 20c0 9.9-7.2 18.1-16.7 19.8l-1.1-.9h-4.4l-1.1.9z" />
                        <path fill="#fff" d="m27.8 25.6.9-5.6h-5.3v-3.9c0-1.6.6-2.8 3-2.8H29V8.2c-1.4-.2-3-.4-4.4-.4-4.6 0-7.8 2.8-7.8 7.8V20h-5v5.6h5v14.1c1.1.2 2.2.3 3.3.3 1.1 0 2.2-.1 3.3-.3V25.6h4.4z" />
                      </svg>
                    </button>
                  )}
                  onSuccess={(response) => {
                    localStorage.setItem('facebook_token', response.accessToken);
                  }}
                  onFail={(error) => {
                    // console.log('Login Failed!', error);
                  }}
                  onProfileSuccess={responseFacebook}
                />
                <CustomGoogleSignInButton />
                <br />
                <div className="sign-up">
                  Not registered yet?
                  <Link to={process.env.REACT_APP_ROUTES_REGISTER} className='ml-2' title='Sign Up'>Sign Up</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
