import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function skeleton() {
  return (

    <div  className='col-md-2'>    
    <div className='w-100 d-flex justify-content-center'>
    <div className='' style={{height:"70px", width:"70px"}}>
   <div className=''>
         <Skeleton circle style={{height:"70px",width:"70px"}} />
    </div>
    </div>
    </div>
    <p className='text-center mt-2'><Skeleton style={{width:"80px"}}/></p>
  </div>
  )
}

export default skeleton