
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const login_token = localStorage.getItem('items');
const token = login_token?.replaceAll('"', '');


export const sendUserData = createAsyncThunk('company/sendUserData', async ({data,pageurl,slug}) => {
  if(slug){
    pageurl=pageurl+slug
  }
  // console.log(data,pageurl);
  try {

    const base_url = process.env.REACT_APP_BASE_URL;
    // console.log(base_url+pageurl);
    const response = await fetch(base_url + `${pageurl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        
      },
      body:data && JSON.stringify(data),
    });

    const responseData = await response.json();
    // console.log('response',responseData);
    return responseData;
  } catch (error) {

  }
});



// export const sendUserData = async(data,url)=>{ 
//   try{
//     const base_url=process.env.REACT_APP_BASE_URL;
   
//     const response = await fetch(base_url + url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     })
//     .then((response) => response.json())
//     .then((result) => {
//         return result;
//     });

//     if (!response.ok) {
//       throw new Error('Failed to send data');
//     }
//     else{
//       const responseData = await response.json();
      
//     }

   
//   }
//   catch(error){
//     console.error('Error sending data to API:', "hello");
//   }
// }

export const sendDataSlice = createSlice({
  name: 'company',
  initialState: { apiData: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.apiData = action.payload;
      })
      .addCase(sendUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sendDataSlice.reducer;
