import React from 'react'
import Search from '../components/main/search'
import Company from '../components/main/company'
import Trusted_by_employee from '../components/main/trusted_by_employee'
import MetaTag from '../components/main/meta-tag';
import Breadcrumb from '../components/google_schema/breadcrumb';
import Company_Header from '../components/main/company/company-header';

function Find_company() {
  const base_url = process.env.REACT_APP_LOCAL_URL;
  const home = process.env.REACT_APP_ROUTES_HOME;
  const find_company = process.env.REACT_APP_ROUTES_FIND_COMPANY;

  const breadcrumb = [
    {
      home: base_url + home,
      find_company: base_url + find_company,
    }
  ];
  return (
    <>

      <div>
        <MetaTag title="Career & Job Recruitment in India | Spot In Job Consultancy" name="find company page" content="Explore exciting job opportunities at YourCompany. Browse and apply for jobs in various fields and locations. Start your career with us!" />
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <Company_Header title="Find Company" description="Resume-Library is a true performance-based job board. Enjoy custom hiring products and access to up to 10,000 new resume registrations daily, with no subscriptions or user licences."/>
      <div>

        <Company title="Featured Company" className="tf-slider sl7 over-flow-hidden opportunityType mt-5" />
        <Trusted_by_employee />
      </div>
    </>
  )
}

export default Find_company