import React from 'react'
import Footer_links from '../footer_links';

import banner from '../../../assets/images/singlejob.jpg';
import Company_Header from '../company/company-header'
import MetaTag from '../meta-tag';

function Contact() {
   
    const contacts = JSON.parse(process.env.REACT_APP_CONTACTS || '[]');
    

  return (
    <>

      <MetaTag
        title="Contact Us for Recruitment, Staffing Services | Spot In Job Consultancy"
          name="description"
          content="Contact Us for Recruitment, Staffing Services. For Placement Services at Rajkot & Ahmedabad Location. Mail - info@spotinjob.com or Call - +9174879 23934."
        />
      {/* <section className="single-job-thumb tf-slider sl7 over-flow-hidden opportunityType">
        <img
          src={banner}
          alt="images"
        />
      </section> */}
      <Company_Header  title="Contact Us" description="Thank you for visiting Spot In Job Consultancy!!!" contactus_header="contactus_header"/>
    <div className='tf-container'>
    <div className='row'>
        <div className='col-md-12'>
            <div className='static-container'>

                <div className='static-content-box'>
                    <div className='text-center'>
                       
                       
                        <div className='cont-box mb-3'>
                            <div className='row '>
                                <div className='col-md-6 mr-3'>
                                    <h2 className='heading'>Job Seekers</h2>
                                    <p className='paragraph'>
                                    If you are a job seeker and would like to contact us, call us at
                                    <br />
                                    <br />
                                    <span>Toll Free</span>
                                    {contacts[0]} &nbsp;&nbsp;
                                    {contacts[1]}
                                    </p>
                                    <p className='paragraph'>
                                    Office Address :
                                    <br />
                                    <span>
                                    Spot In Job Consultancy Office -535,<br />
                                     The City Center, Old Amrapali Cinema, <br />
                                     Raiya Road, Rajkot - 360007
                                    </span>
                                    </p>
                                </div>
                                <div className='col-md-6'>
                                    <h2 className='heading'>Employers</h2>
                                    <h3 className='paragraph'>
                                    If you are an employer and would like to contact us, call us at:
                                    <br />
                                    <br />
                                    <span>Toll Free : </span>
                                    {contacts[0]} &nbsp;&nbsp;
                                    {contacts[1]}
                                    </h3>
                                    <p className='paragraph'>
                                    Office Address :
                                    <br />
                                    <span>
                                    Spot In Job Consultancy Office -535,<br />
                                     The City Center, Old Amrapali Cinema, <br />
                                     Raiya Road, Rajkot - 360007
                                    </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
    {/* <Footer_links /> */}
    </div>
    </>
  )
}

export default Contact