import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDispatch } from 'react-redux';
import { sendUserData } from '../../../slice/postSlice';
import Header from '../company/company-header';
import MetaTag from "../meta-tag";
import DragDrop from "../drag&drop";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

function Register({details}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [candidateData, setCandidateData] = useState({
    register_by: 'candidate',
    name: details ? details.name : '',
    email: details ? details.email : '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCandidateData({ ...candidateData, [name]: value });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (candidateData.password === confirmPassword && isChecked) {
      try {
        const result = await dispatch(sendUserData({ data: candidateData, pageurl: process.env.REACT_APP_REGISTER_API_URL })).unwrap();
        if (result.status) {
          navigate(process.env.REACT_APP_ROUTES_LOGIN);
        } else {
          withReactContent(Swal).fire({
            title: <i>Something went wrong</i>,
          });
        }
      } catch (error) {
        console.error('Error sending data to API:', error.message);
      }
    } else {
      withReactContent(Swal).fire({
        title: <i>Passwords do not match or terms not accepted</i>,
      });
    }
  };

  return (
    <>
      <MetaTag title="Register in Spot In Job Consultancy" name="description" content="Register Your Self With Us for Better Your Career" />
      {details ? <section className="mt-5 account-section tf-slider sl7 over-flow-hidden opportunityType">
        <div className="tf-container">
          <div className="row">
            <div className="wd-form-login">
              <h2 className='h4'>Register</h2>
              <form onSubmit={handleSubmit}>
                <div className="ip">
                  <label>User Name<span>*</span></label>
                  <input
                    type="text"
                    placeholder="Enter UserName"
                    name="name"
                    value={candidateData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="ip">
                  <label>Email address<span>*</span></label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={candidateData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="ip">
                  <label>Password<span>*</span></label>
                  <div className="inputs-group auth-pass-inputgroup">
                    <input
                      type={showPass ? "text" : "password"}
                      className="input-form password-input"
                      placeholder="Password"
                      name='password'
                      value={candidateData.password}
                      onChange={handleInputChange}
                      required
                    />
                    <Link
                      title={showPass ? "Hide Password" : "Show Password"}
                      className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"}`}
                      onClick={() => setShowPass(!showPass)}
                    />
                  </div>
                </div>
                <div className="ip">
                  <label>Confirm Password<span>*</span></label>
                  <div className="inputs-group auth-pass-inputgroup">
                    <input
                      type={showPass2 ? "text" : "password"}
                      className="input-form password-input"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                    <Link
                      title={showPass2 ? "Hide Password" : "Show Password"}
                      className={`password-addon ${showPass2 ? "icon-eye" : "icon-eye-off"}`}
                      onClick={() => setShowPass2(!showPass2)}
                    />
                  </div>
                </div>
                <div className="group-ant-choice st">
                  <div className="sub-ip">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      id="terms"
                      name="terms"
                    />
                    I agree to the
                    <Link to={process.env.REACT_APP_ROUTES_TERMS_AND_SERVICES} title="Terms of User"> Terms of User</Link>
                  </div>
                </div>
                <button className="register-button" type="submit">Register</button>
                <div className="sign-up">
                  Already have an account?
                  <Link to={process.env.REACT_APP_ROUTES_LOGIN} title="Login">&nbsp;Login Here</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> :
      <DragDrop />
      }
    </>
  );
}

export default Register;
