import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { sendUserData } from '../../../slice/postSlice';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';
import Skeletopn from "../company/skeleton";
import DragDrop from "../drag&drop"
import Dropdown from "react-dropdown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link as ScrollLink, Element } from 'react-scroll';
import JobBlockComponent from '../featured_jobs/index'
import '@fortawesome/fontawesome-free/css/all.min.css';

function JobsBlock({ perPageData, filterOpportunityType, blockHeight }) {
    const [click, setClick] = useState(false)
    console.log(window.location.href);
    const closeMenu = () => setClick(false)
    console.log('L-20', window.location.href);
    const cate = [
        { value: "", label: "Job Type" },
        { value: "FULL_TIME", label: "Full Time" },
        { value: "PART_TIME", label: "Part Time" },
        { value: "CONTRACTOR", label: "Contractor" },
        { value: "TEMPORARY", label: "Termporary" },
        { value: "INTERN", label: "Intern" },
        { value: "VOLUNTEER", label: "Volunteer" },
        { value: "remote", label: "Remote" },
        { value: "OTHER", label: "Other" },
    ];
    const salary = [
        { value: "", label: "Salary" },
        { value: "10000-20000", label: "10K - 20K" },
        { value: "21000-30000", label: "21K - 30K" },
    ];
    const stipent = [
        { value: "", label: "Stipent" },
        { value: "0", label: "Unpaid" },
        { value: "0-5000", label: "0-5k" },
        { value: "5000-10000", label: "5k-10k" },
        { value: "10000", label: "above 10k" },
    ];
    const experience = [
        { value: "", label: "Experience" },
        { value: "1-5", label: "1 year- 5 year" },
        { value: "6-10", label: "6 year - 10 years" },
    ]
    const intern_duration = [
        { value: "", label: "Duration" },
        { value: "1", label: "1 Month" },
        { value: "2", label: "2 Month" },
        { value: "3", label: "3 Month" },
        { value: "4", label: "4 Month" },
        { value: "5", label: "5 Month" },
        { value: "6", label: "6 Month" },
    ]
    const [details, setDetails] = useState(null);
    const [dataFetch, setDataFetch] = useState(0);
    const [noDataText, setNoDataText] = useState(false);
    const [salaryRange, setSalaryRange] = useState({
        minimum_salary: "",
        maximum_salary: ""
    });
    const [experienceRange, setExperienceRange] = useState({
        minimum_experience: "",
        maximum_experience: ""
    });
    const [industry, setIndustry] = useState([]);
    const [companyName, setCompanyName] = useState();
    const [industrydata, setIndustrydata] = useState();
    const [companyTypeName, setCompanyTypeName] = useState([]);

    const [companyTypeData, setCompanyTypeData] = useState();
    const [opportunityTypeData, setOpportunityTypeData] = useState();
    const handleDataReceived = (data) => {
        setDetails(data);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [jobType, setJobType] = useState();
    const [internDuration, setInternDuration] = useState();
    const [internStipent, setInternStipent] = useState({
        minimum_stipent: "",
        maximum_stipent: ""
    });

    const opportunity_slug_name = filterOpportunityType ? filterOpportunityType : location.state;
    sessionStorage.setItem('Opportunity_type_slug', opportunity_slug_name)
    const [opportunityData, setOpportunityData] = useState([]);
    const [OpportunityTypeName, setOpportunityTypeName] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [modal, setModal] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState('');
    const token = localStorage.getItem('items');
    const [showButton, setShowButton] = useState(true);
    const [easyApplyData, setEasyApplyData] = useState({
        opportunity_slug: "",
        name: details ? details.name : '',
        email: details ? details.email : '',
        phone: details ? details.phone : '',
    });
    useEffect(() => {
        setEasyApplyData({
            opportunity_slug: "",
            name: details ? details.name : '',
            email: details ? details.email : '',
            phone: details ? details.phone : '',
        })
    }, [details])
    const [userProfileData, setUserProfileData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        pincode: '',
        state: '',
        city: '',
        city_id: '',
        state_id: '',
        country_id: '',
        qualification: '',
        total_experience: '',
        designation: '',
        date_of_birth: ''
    });
    const capitalizeFirstLetterOfEachWord = (text) => {
        return text.replace(/\b\w/g, char => char.toUpperCase());
    };
    const [today, setToday] = useState(new Date()); // State for today's date
    const [givenDate, setGivenDate] = useState(new Date('2024-06-28')); // State for the given date
    const [differenceInDays, setDifferenceInDays] = useState(0);
    // console.log('l-34 per', perPageData);
    useEffect(() => {
        console.log('L-129', location.state);
        if (location.state?.companySlug) {
            setCompanyName(location.state.companySlug);
        }
        if (location.state?.jobType) {

            setJobType(location.state?.jobType);
        }
        if (location.state?.minimum_salary) {
            setSalaryRange({
                minimum_salary: location.state?.minimum_salary
            });
        }
        if (location.state?.maximum_salary) {
            setSalaryRange({
                maximum_salary: location.state?.maximum_salary
            });
        }
        if (location.state?.minimum_experience || location.state?.maximum_experience) {

            setExperienceRange({
                minimum_experience: location.state?.minimum_experience,
                maximum_experience: location.state?.maximum_experience
            });
        }

    }, [window.location.href])

    useEffect(() => {
        setItemsPerPage(perPageData)
    }, [perPageData])

    const [opportunitySlug, setOpportunitySlug] = useState();
    const paginateData = () => {
        setShowButton(false);
        setCurrentPage(currentPage + 1);
    }

    const desigantion_based_job = sessionStorage.getItem('login_user_desigantion');
    useEffect(() => {
        if (location.state?.company_slug) {
            setCompanyName(location.state.company_slug);
        }
    }, [dispatch])
    useEffect(() => {

        const fetchData = () => {

            const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_DETAILS_API_URL;
            let payload = {};
            payload.per_page = itemsPerPage;
            payload.page = currentPage;

            if (companyName) {
                payload.filter_by_company_name = companyName;
            }
            if (jobType) {
                payload.filter_by_job_type = jobType;
            }
            if (internStipent.minimum_stipent && internStipent.maximum_stipent) {
                payload.minimum_stipent = internStipent.minimum_stipent;
                payload.maximum_stipent = internStipent.maximum_stipent;
            }
            if (internDuration) {
                payload.filter_by_intern_duration = internDuration;
            }
            if (industrydata) {
                payload.filter_by_industry = industrydata.value;
            }
            if (companyTypeData) {
                payload.filter_by_company_type = companyTypeData.value;
            }
            if (opportunityTypeData) {
                payload.filter_by_opportunity_type = opportunityTypeData.value;
            }
            if (salaryRange.minimum_salary && salaryRange.maximum_salary) {
                payload.minimum_salary = salaryRange.minimum_salary;
                payload.maximum_salary = salaryRange.maximum_salary;
            }
            console.log('L-200', experienceRange.minimum_experience, experienceRange.maximum_experience);
            if (experienceRange.minimum_experience && experienceRange.maximum_experience) {
                payload.minimum_experience = experienceRange.minimum_experience;
                payload.maximum_experience = experienceRange.maximum_experience;
            }
            if (opportunitySlug) {
                payload.filter_by_opportunity_type = opportunitySlug;
            } else if (desigantion_based_job) {
                let formatted_job = desigantion_based_job.replace(/ /g, "_").replace(/\"/g, "");
                payload.filter_by_opportunity_type = formatted_job;
                payload = { filter_by_opportunity_type: formatted_job };
            }
            console.log('L-212', payload);
            if (itemsPerPage) {

                console.log('L-231', payload);
                dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                    .then((action) => {
                        const updatedOpportunityData = action.payload;

                        if (updatedOpportunityData) {
                            if (updatedOpportunityData?.data?.items?.length) {

                                setDataFetch(1);
                                //if item_per_page is 4 for related jobs
                                if (itemsPerPage == 4) {
                                    setOpportunityData(updatedOpportunityData.data.items);
                                }
                                else {
                                    setOpportunityData(prevOpportunity => [...prevOpportunity, ...updatedOpportunityData.data.items]);
                                    setSelectedJob(updatedOpportunityData.data.items[0].opportunity_slug);
                                }
                                // console.log('l-95',updatedOpportunityData?.data?.total);
                                sessionStorage.setItem('total_jobs', updatedOpportunityData?.data?.total)
                                setShowButton(true);
                                setDataFetch(1);
                                setNoDataText(false);

                            }
                            else {
                                setOpportunityData([]);
                                setShowButton(false);
                                setNoDataText(true);
                            }
                        } else {
                            setOpportunityData([]);
                        }
                    })
                    .catch((error) => {

                    });
            }
        };
        fetchData();
    }, [dispatch, companyName, currentPage, filterOpportunityType, itemsPerPage, jobType, salaryRange, experienceRange, industrydata, companyTypeData, opportunityTypeData, internDuration, internStipent]);


    //fetch industry data

    useEffect(() => {
        const fetchIndustryData = () => {
            const dynamicUrl = process.env.REACT_APP_INDUSTRY_API_URL;
            let payload = {};  // assuming payload is an empty object, modify if needed
            if (dynamicUrl || payload) {

                dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                    .then((action) => {
                        const updatedOpportunityData = action.payload;
                        if (updatedOpportunityData && updatedOpportunityData.data) {
                            // Assuming updatedOpportunityData.data is an array
                            const industryOptions = updatedOpportunityData.data.map(industry => ({
                                key: industry.industry_name,
                                value: industry.industry_name,
                            }));
                            setIndustry(industryOptions);
                        } else {
                            setIndustry([]);
                        }
                    })
                    .catch((error) => {
                        // Handle error if needed
                    });
            }
        };
        const fetchOpportunityTypeData = () => {
            const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_API_URL;
            let payload = {};
            if (dynamicUrl || payload) {

                dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                    .then((action) => {
                        const updatedOpportunityData = action.payload;
                        if (updatedOpportunityData && updatedOpportunityData.data) {
                            console.log('L-311', updatedOpportunityData);
                            const opportunityTypeNameOptions = updatedOpportunityData.data.map(OpportunityTypeName => ({
                                key: OpportunityTypeName.name,
                                value: OpportunityTypeName.name,
                            }));
                            setOpportunityTypeName(opportunityTypeNameOptions);
                        } else {
                            setOpportunityTypeName([]);
                        }
                    })
                    .catch((error) => {
                        // Handle error if needed
                    });
            }
        };
        const fetchCompanyTypeData = () => {
            const dynamicUrl = process.env.REACT_APP_COMPANY_TYPE_API_URL;
            let payload = {};
            if (dynamicUrl || payload) {
                dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                    .then((action) => {
                        const updatedCompanyTypeData = action.payload;
                        // console.log('L-298', updatedCompanyTypeData);
                        if (updatedCompanyTypeData && updatedCompanyTypeData.data) {
                            const CompanyTypeNameOptions = updatedCompanyTypeData.data.map(CompanyTypeName => ({
                                key: CompanyTypeName.name,
                                value: CompanyTypeName.name,
                            }));
                            setCompanyTypeName(CompanyTypeNameOptions);
                        } else {
                            setCompanyTypeName([]);
                        }
                    })
                    .catch((error) => {
                        // Handle error if needed
                    });
            }
        };

        fetchIndustryData();
        fetchOpportunityTypeData();
        fetchCompanyTypeData();
    }, [dispatch]);



    const modelOpen = (slug) => {

        setModal(true);
        setOpportunitySlug(slug);
    }
    const checkProfile = (slug) => {
        const dynamicUrl = process.env.REACT_APP_GET_PROFILE_API_URL;
        const opportunity_slug = slug ? slug : '';

        dispatch(sendUserData({ token: token, pageurl: dynamicUrl }))
            .then((action) => {
                const getProfileData = action.payload;

                if (getProfileData) {
                    // console.log('l-64', getProfileData.data);

                    setUserProfileData(
                        {
                            name: getProfileData.data.name,
                            phone: getProfileData.data.phone,
                            email: getProfileData.data.email,
                            address: getProfileData.data.address,
                            pincode: getProfileData.data.pincode,
                            state: getProfileData.data.state,
                            city: getProfileData.data.city,
                            city_id: getProfileData.data.city_id,
                            state_id: getProfileData.data.state_id,
                            country_id: getProfileData.data.country_id,
                            qualification: getProfileData.data.qualification,
                            total_experience: getProfileData.data.total_experience,
                            designation: getProfileData.data.designation,
                            date_of_birth: getProfileData.data.date_of_birth
                        }
                    )
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        const isAnyValueNull = Object.values(userProfileData).some(value => value === null);

        if (isAnyValueNull) {
            // console.log('There is at least one null value in userProfileData.');
            withReactContent(Swal).fire({
                title: "Somethings wents Wrong!!!",
                text: "Please Complete Your Profile"
            });
            navigate('/profile')

        } else {
            // console.log('All values in userProfileData are not null.'); 
            const dynamicUrl = process.env.REACT_APP_SIMPLE_APPLY_API_URL;

            dispatch(sendUserData({ token: token, pageurl: dynamicUrl, slug: opportunity_slug }))
                .then((action) => {
                    const success_message = action.payload;
                    withReactContent(Swal).fire({
                        title: success_message.message,
                    });
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    withReactContent(Swal).fire({
                        title: "Somethings wents Wrong!!!",
                        text: "Please Complete Your Profile"
                    });
                });

        }

    }
    const handleChange = (selectedOption) => {
        setJobType(selectedOption.value);
    };
    const handleInternDurationChange = (selectedOption) => {
        setInternDuration(selectedOption.value);
    };
    const handleIndustryChange = (selectedOption) => {
        // console.log('L-314', selectedOption);
        setIndustrydata(selectedOption);

    };
    const handleCompanyTypeChange = (selectedOption) => {
        // console.log('L-314', selectedOption);
        setCompanyTypeData(selectedOption);

    };
    const handleOpportunityChange = (selectedOption) => {
        // console.log('L-314', selectedOption);
        setOpportunityTypeData(selectedOption);

    };

    const handleSalaryChange = (selectedOption) => {
        // console.log('L-257', selectedOption);
        if (selectedOption && selectedOption.value) {
            const [min, max] = selectedOption.value.split('-');
            setSalaryRange({
                minimum_salary: min,
                maximum_salary: max
            });
        }
    };
    const handleStipentChange = (selectedOption) => {
        // console.log('L-257', selectedOption);
        if (selectedOption && selectedOption.value) {
            const [min, max] = selectedOption.value.split('-');
            setInternStipent({
                minimum_stipent: min,
                maximum_stipent: max
            });
        }
    };
    const handleExperienceChange = (selectedOption) => {
        // console.log('L-257', selectedOption);
        if (selectedOption && selectedOption.value) {
            const [min, max] = selectedOption.value.split('-');
            setExperienceRange({
                minimum_experience: min,
                maximum_experience: max
            });
        }
    };
    const handleInputChange = (e) => {

        const { name, value } = e.target;
        const updatedData = {
            ...easyApplyData,
            [name]: value,
            opportunity_slug: opportunitySlug ? opportunitySlug : null
        };
        setEasyApplyData(updatedData);

    }


    const easyApply = (event) => {

        event.preventDefault();
        const url = process.env.REACT_APP_EASY_APPLY_API_URL

        try {
            if (easyApplyData) {
                dispatch(sendUserData({ data: easyApplyData, pageurl: url }))
                    .then((action) => {

                        const result = action.payload.message;

                        withReactContent(Swal).fire({
                            title: "Success",
                            text: result

                        });

                    })
                    .catch((error) => {
                        withReactContent(Swal).fire({
                            title: "Error!!!",
                            text: "SomeThing Wents To Wrong"

                        });
                    });
            } else {
                alert('SomeThing Wents To Wrong');

            }
        } catch (error) {
            console.error('Error sending data to API:', error.message);
        }
    }
    const [selectedJob, setSelectedJob] = useState();
    const [jobDetails, setJobDetails] = useState();
    const handleSelectJob = (job) => {
        setSelectedJob(job);
    };
    useEffect(() => {
        const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_JOBS_DETAILS_API_URL + selectedJob;
        const payload = {}
        if (selectedJob != undefined) {

            dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                .then((action) => {
                    const updatedOpportunityDetails = action.payload;
                    // console.log('L-501', updatedOpportunityDetails);
                    if (updatedOpportunityDetails && updatedOpportunityDetails.data) {
                        setJobDetails(updatedOpportunityDetails.data);
                    }
                    const differenceInTime = today.getTime() - updatedOpportunityDetails.data.live_from.getTime();
                    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

                    // Update state with the difference
                    setDifferenceInDays(differenceInDays);
                })
                .catch((error) => {
                    // Handle error if needed
                });
        }
    }, [selectedJob, dispatch])

    const [activeTab, setActiveTab] = useState('tab1'); // State to keep track of active tab


    return (
        <>
            {!blockHeight &&
                <section className="form-sticky form-meta2 is-fixed1">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="meta-category-job fixed-content">
                                    <ul className="category-filter">
                                        <li className="custom-dropdown-wrapper">
                                            <Dropdown
                                                options={cate}
                                                className="react-dropdown"
                                                value={jobType ? jobType : "JobType"}
                                                onChange={handleChange}
                                                arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                            />
                                        </li>
                                        {jobType ? jobType : "jobType" == 'INTERN' &&
                                            <>
                                                <li>
                                                    <Dropdown
                                                        key={intern_duration}
                                                        options={intern_duration}
                                                        className="react-dropdown"
                                                        value={internDuration ? internDuration : 'Duration'}
                                                        onChange={handleInternDurationChange}
                                                        arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                        arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                                    />
                                                </li>
                                                <li>
                                                    <Dropdown
                                                        key={stipent}
                                                        options={stipent}
                                                        className="react-dropdown"
                                                        value={internStipent.minimum_stipent && internStipent.maximum_stipent ? `${internStipent.minimum_stipent}-${internStipent.maximum_stipent}` : 'Stipent'}
                                                        onChange={handleStipentChange}
                                                        arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                        arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                                    />
                                                </li>
                                            </>
                                        }
                                        {jobType != 'INTERN' &&
                                            <li>
                                                <Dropdown
                                                    key={salary}
                                                    options={salary}
                                                    className="react-dropdown"
                                                    value={salaryRange.minimum_salary && salaryRange.maximum_salary ? `${salaryRange.minimum_salary}-${salaryRange.maximum_salary}` : 'Salary'}
                                                    onChange={handleSalaryChange}
                                                    arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                    arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                                />
                                            </li>
                                        }
                                        <li>
                                            <Dropdown
                                                key={experience}
                                                options={experience}
                                                className="react-dropdown"
                                                value={experienceRange.minimum_experience && experienceRange.maximum_experience ? `${experienceRange.minimum_experience}-${experienceRange.maximum_experience}` : 'Experience'}
                                                onChange={handleExperienceChange}
                                                arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                            />
                                        </li>

                                        <li>
                                            <Dropdown
                                                key={companyTypeName}
                                                options={companyTypeName}
                                                className="react-dropdown"
                                                value={companyTypeData ? companyTypeData : 'Company Type'}
                                                onChange={handleCompanyTypeChange}
                                                arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                            />
                                        </li>
                                        {(OpportunityTypeName) &&
                                            <li>
                                                <Dropdown
                                                    key={OpportunityTypeName}
                                                    options={OpportunityTypeName}
                                                    className="react-dropdown"
                                                    value={(OpportunityTypeName.value) ? OpportunityTypeName.value : "Opportunity"}
                                                    onChange={handleOpportunityChange}
                                                    arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                    arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                                />
                                            </li>
                                        }
                                        <li>
                                            <Dropdown
                                                key={industry}
                                                options={industry}
                                                className="react-dropdown dropdown-industry"
                                                value={industrydata ? industrydata : 'Industry'}
                                                onChange={handleIndustryChange}
                                                arrowClosed={<span className="icon"><i className="fas fa-chevron-down"></i></span>}
                                                arrowOpen={<span className="icon"><i className="fas fa-chevron-up"></i></span>}
                                            />
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <section className="tab-section">
                <div className="tf-container">
                    <Tabs className="row tf-tab">
                        <div className={blockHeight + " col-xl-5 col-lg-5 col-md-12"}>
                            <div className="group-job-sidebar">
                                <TabList className="list-job menu-tab">
                                    {

                                        opportunityData.map((idx) => (
                                            <Tab key={idx.id} onClick={() => handleSelectJob(idx.opportunity_slug)} className="features-job mg-bt-0 ct-tab w-100">
                                                <div className="job-archive-header">
                                                    <div className="inner-box">
                                                        <div className="logo-company">
                                                            <img src={idx.company_logo_url} alt="Jobtex" />
                                                        </div>
                                                        <div className="box-content">
                                                            <h4 className="opportunityTypeCompanyName" style={{ marginBottom: "0px" }}>
                                                                <Link to={`/company-details/${idx.company_slug}`} state={idx.company_slug} title={idx.company_name}>{idx.company_name}</Link>
                                                            </h4>
                                                            <h3 className="opportunityTypeName">
                                                                <Link
                                                                    to={`/featured-jobs-details/${idx.opportunity_slug}`}
                                                                    title={idx.title}
                                                                    state={idx.opportunity_slug}
                                                                    tabIndex="0"
                                                                >{idx.title}</Link>&nbsp;
                                                                <span className="icon-bolt"></span>
                                                            </h3>
                                                            <ul>
                                                                <li>
                                                                    {
                                                                        (idx.city_name || idx.state_name) &&
                                                                        <li>
                                                                            <span className="icon-map-pin"></span>
                                                                            &nbsp;
                                                                            {idx.city_name}
                                                                            {(idx.city_name) && ','}
                                                                            {idx.state_name}
                                                                        </li>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <span className="icon-calendar"></span>
                                                                    <span>Total opening - &nbsp;</span>
                                                                    <span>{idx.number_of_opening}</span>
                                                                </li>
                                                                <li>
                                                                    {(idx.minimum_experience || idx.maximum_experience) &&
                                                                        <li>
                                                                            <span className="icon-calendar"></span> &nbsp;
                                                                            <Link
                                                                                to={`${process.env.REACT_APP_ROUTES_FIND_JOBS_PARAMETER}${idx.minimum_experience}to${idx.maximum_experience}`}
                                                                                title="Experience"
                                                                                class="job-box-experience"
                                                                                state={{
                                                                                    minimum_experience: idx.minimum_experience,
                                                                                    maximum_experience: idx.maximum_experience
                                                                                }}
                                                                                tabIndex="0"

                                                                            ><span>Experience - </span>
                                                                                <span className=""> {idx.minimum_experience}</span>
                                                                                {(idx.maximum_experience) && ' to'}
                                                                                <span className="">{idx.maximum_experience} years</span>
                                                                            </Link>
                                                                        </li>
                                                                    }
                                                                </li>
                                                            </ul>
                                                            <span className="icon-heart"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="job-archive-footer">
                                                    <div className="job-footer-left">
                                                        <ul className="job-tag">
                                                            <li>
                                                                <Link
                                                                    to={`${process.env.REACT_APP_ROUTES_FIND_JOBS_PARAMETER}${idx.job_type}`}
                                                                    title={idx.job_type}
                                                                    state={{ jobType: idx.job_type }}
                                                                    tabIndex="0"
                                                                >{idx.job_type}</Link>
                                                            </li>
                                                        </ul>
                                                        <ul className="different_days">
                                                            <li className="text-dark">{differenceInDays + " days ago"}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="job-footer-right">
                                                        <div className="price">
                                                            <Link
                                                                to={`${process.env.REACT_APP_ROUTES_FIND_JOBS_PARAMETER}${idx.minimum_salary * 12}to${idx.maximum_salary * 12}`}
                                                                title={idx.opportunity_slug}
                                                                state={{
                                                                    minimum_salary: idx.minimum_salary * 12,  // Annual minimum salary in LPA
                                                                    maximum_salary: idx.maximum_salary * 12   // Annual maximum salary in LPA
                                                                }}
                                                                tabIndex="0"
                                                            >
                                                                <span className="text-dark">₹</span>&nbsp;
                                                                <span className="text-dark">
                                                                    {parseFloat((idx.minimum_salary * 12 / 100000).toFixed(1))}
                                                                    {idx.minimum_salary && '-'}
                                                                    {parseFloat((idx.maximum_salary * 12 / 100000).toFixed(1))} Lacs P.A.
                                                                </span>
                                                            </Link>
                                                        </div>


                                                        <p className="days">   {
                                                            // token ?
                                                            //     <button className="job-block-apply-button" onClick={() => checkProfile(idx.opportunity_slug)}>Apply</button>
                                                            //     :
                                                            //     <div className="easy-apply-div ">

                                                            //         <Link title="Easy Apply" to="#" className="p-2 easy-apply-button" onClick={(opportunity_slug) => modelOpen(idx.opportunity_slug)}>
                                                            //             Apply
                                                            //         </Link>
                                                            //     </div>

                                                            token ?
                                                                <Link
                                                                    className="btn-apply btn-popup"
                                                                    onClick={() => checkProfile(jobDetails.opportunity_slug)}
                                                                >
                                                                    <span className="icon-send"></span>
                                                                    Apply
                                                                </Link>
                                                                :
                                                                <Link
                                                                    className="btn-apply btn-popup"
                                                                    onClick={(opportunity_slug) => modelOpen(jobDetails.opportunity_slug)}
                                                                >
                                                                    <span className="icon-send"></span>
                                                                    Apply Now
                                                                </Link>


                                                        }</p>
                                                    </div>
                                                </div>
                                            </Tab>
                                        ))

                                    }
                                    {(opportunityData.length >= 12) && (
                                        <div className="col-md-12" id="show_more_button">
                                            <div className="wrap-button">

                                                {
                                                    showButton && <button className="load-more-button" onClick={paginateData}>Load More</button>
                                                }
                                                {
                                                    !showButton &&
                                                    <div className="spinner" />
                                                }
                                            </div>
                                        </div>
                                    )}
                                    {

                                        opportunityData.length <= 0 &&
                                        (!noDataText) &&
                                        <>
                                            <Skeletopn />
                                            {/* <Skeletopn />
                                            <Skeletopn /> */}
                                        </>
                                    }

                                </TabList>
                            </div>
                        </div>
                        <div className="job-details-tab col-xl-7 col-lg-7 col-md-12" id="zIndex">
                            {(jobDetails && !noDataText) &&
                                <TabPanel className="content-tab sticky-home2 po-sticky add-mgt">

                                    <div className="features-job mg-bt-0 ct-tab w-100">

                                        <div className="job-archive-header">
                                            <div className="inner-box">
                                                <div className="logo-company">
                                                    <img src={jobDetails.company_logo_url} alt="Jobtex" />
                                                </div>
                                                <div className="box-content">
                                                    <h4 className="opportunityTypeCompanyName" style={{ marginBottom: "0px" }}>
                                                        <Link to={`${process.env.REACT_APP_ROUTES_COMPANY_DETAILS_WITHOUT_PARAMETER}${jobDetails.company_slug}`} state={jobDetails.company_slug} title={jobDetails.company_name}>{jobDetails.company_name}</Link>
                                                    </h4>
                                                    <h3 className="opportunityTypeName">
                                                        <Link
                                                            to={`${process.env.REACT_APP_ROUTES_DETAILS_OF_JOBS}${jobDetails.opportunity_slug}`}
                                                            title={jobDetails.title}
                                                            state={jobDetails.opportunity_slug}
                                                            tabIndex="0"
                                                        >{jobDetails.title}</Link>&nbsp;
                                                        <span className="icon-bolt"></span>
                                                    </h3>
                                                    <ul className="w-75">
                                                        <li>
                                                            {
                                                                (jobDetails.city_name || jobDetails.state_name) &&
                                                                <li>
                                                                    <span className="icon-map-pin"></span>
                                                                    &nbsp;
                                                                    {jobDetails.city_name}
                                                                    {(jobDetails.city_name) && ','}
                                                                    {jobDetails.state_name}
                                                                </li>
                                                            }
                                                        </li>
                                                        <li>
                                                            <span className="icon-calendar"></span>
                                                            <span>Total opening - &nbsp;</span>
                                                            <span>{jobDetails.number_of_opening}</span>
                                                        </li>
                                                        <li>
                                                            {(jobDetails.minimum_experience || jobDetails.maximum_experience) &&
                                                                <li>
                                                                    <span className="icon-calendar"></span> &nbsp;
                                                                    <Link
                                                                        to={`${process.env.REACT_APP_ROUTES_FIND_JOBS_PARAMETER}${jobDetails.minimum_experience}to${jobDetails.maximum_experience}`}
                                                                        title="Experience"
                                                                        class="job-box-experience"
                                                                        state={{
                                                                            minimum_experience: jobDetails.minimum_experience,
                                                                            maximum_experience: jobDetails.maximum_experience
                                                                        }}
                                                                        tabIndex="0"

                                                                    ><span>Experience - </span>
                                                                        <span className=""> {jobDetails.minimum_experience}</span>
                                                                        {(jobDetails.maximum_experience) && ' to'}
                                                                        <span className="">{jobDetails.maximum_experience} years</span>

                                                                    </Link>
                                                                </li>
                                                            }
                                                        </li>
                                                    </ul>
                                                    <span className="icon-heart"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="job-archive-footer">
                                            <div className="job-footer-left">
                                                <ul className="job-tag">
                                                    <li>
                                                        <Link
                                                            to={`${process.env.REACT_APP_ROUTES_FIND_JOBS_PARAMETER}${jobDetails.job_type}`}
                                                            title={jobDetails.job_type}
                                                            state={{ jobType: jobDetails.job_type }}
                                                            tabIndex="0"
                                                        >{jobDetails.job_type}</Link>
                                                    </li>
                                                </ul>
                                                <ul className="different_days">
                                                    <li className="text-dark h6">{differenceInDays + " days ago"}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="job-footer-right">

                                                <Link

                                                    to={`${process.env.REACT_APP_ROUTES_FIND_JOBS_PARAMETER}${jobDetails.minimum_salary * 12}to${jobDetails.maximum_salary * 12}`}
                                                    title={jobDetails.opportunity_slug}
                                                    state={{
                                                        minimum_salary: jobDetails.minimum_salary * 12,  // Annual minimum salary in LPA
                                                        maximum_salary: jobDetails.maximum_salary * 12   // Annual maximum salary in LPA
                                                    }}
                                                    tabIndex="0"
                                                > <span className="text-dark h6">₹</span>&nbsp;
                                                    <span className="text-dark h6">
                                                        {parseFloat((jobDetails.minimum_salary * 12 / 100000).toFixed(1))}
                                                        {jobDetails.minimum_salary && '-'}
                                                        {parseFloat((jobDetails.maximum_salary * 12 / 100000).toFixed(1))} Lacs P.A.
                                                    </span>
                                                </Link>
                                                <p className="days">   {
                                                    // token ?
                                                    //     <button className="job-block-apply-button" onClick={() => checkProfile(idx.opportunity_slug)}>Apply</button>
                                                    //     :
                                                    //     <div className="easy-apply-div ">

                                                    //         <Link title="Easy Apply" to="#" className="p-2 easy-apply-button" onClick={(opportunity_slug) => modelOpen(idx.opportunity_slug)}>
                                                    //             Apply
                                                    //         </Link>
                                                    //     </div>

                                                    token ?
                                                        <Link
                                                            className="btn-apply btn-popup"
                                                            onClick={() => checkProfile(jobDetails.opportunity_slug)}
                                                        >
                                                            <span className="icon-send"></span>
                                                            Apply
                                                        </Link>
                                                        :
                                                        <Link
                                                            className="btn-apply btn-popup"
                                                            onClick={(opportunity_slug) => modelOpen(jobDetails.opportunity_slug)}
                                                        >
                                                            <span className="icon-send"></span>
                                                            Apply Now
                                                        </Link>


                                                }</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="inner-content home2">

                                        <Tabs className="job-article tf-tab single-job single-employer" onSelect={(index) => setActiveTab(index)}>
                                            <TabList className="menu-tab">
                                                {
                                                    jobDetails.overview &&
                                                    <div className="ct-tab">
                                                        <ScrollLink to="overview" smooth={true} duration={500} onClick={closeMenu}>
                                                            Overview
                                                        </ScrollLink>
                                                    </div>
                                                }
                                                {jobDetails.job_description &&
                                                    <div className="ct-tab">
                                                        <ScrollLink to="jobDescription" smooth={true} duration={500} onClick={closeMenu}>
                                                            Job Description
                                                        </ScrollLink>
                                                    </div>
                                                }
                                                {jobDetails?.required_skills &&
                                                    <div className="ct-tab">
                                                        <ScrollLink to="requiredSkills" smooth={true} duration={500} onClick={closeMenu}>
                                                            Required Skills
                                                        </ScrollLink>
                                                    </div>
                                                }
                                                {
                                                    jobDetails?.benefits &&
                                                    <div className="ct-tab">
                                                        <ScrollLink to="benefits" smooth={true} duration={500} onClick={closeMenu}>
                                                            Benefits
                                                        </ScrollLink>
                                                    </div>
                                                }
                                            </TabList>
                                            <div>
                                                {
                                                    jobDetails.overview &&
                                                    <Element name="overview">
                                                        <div className='about' id='overview'>
                                                            <div className="opportunity-subheading">
                                                                <h5 className="opportunity-description-subheading">Overview</h5>
                                                                <p
                                                                    className="opportunity-description"
                                                                    dangerouslySetInnerHTML={{ __html: jobDetails.overview }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Element>
                                                }
                                                {
                                                    jobDetails.job_description &&
                                                    <Element name="jobDescription">
                                                        <div className='about' id='jobDescription'>
                                                            <div className="opportunity-subheading">
                                                                <h5 className="opportunity-description-subheading">Job Description</h5>
                                                                <p
                                                                    className="opportunity-description"
                                                                    dangerouslySetInnerHTML={{ __html: jobDetails.job_description }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Element>
                                                }
                                                {
                                                    jobDetails?.required_skills &&
                                                    <Element name="requiredSkills">
                                                        <div className='about' id='requiredSkills'>
                                                            <div className="opportunity-subheading">
                                                                {
                                                                    <>
                                                                        <h5 className="opportunity-description-subheading">Required Skills</h5>
                                                                        <p className=" opportunity-description" dangerouslySetInnerHTML={{ __html: jobDetails?.required_skills }}>
                                                                        </p>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Element>
                                                }

                                                {
                                                    jobDetails?.benefits &&
                                                    <Element name="benefits">
                                                        <div className='about' id='benefits'>
                                                            <div className="opportunity-subheading">
                                                                {
                                                                    <>
                                                                        <h5 className="opportunity-description-subheading">Benefits</h5>


                                                                        <p className=" opportunity-description benefits" dangerouslySetInnerHTML={{ __html: capitalizeFirstLetterOfEachWord(jobDetails?.benefits) }}>
                                                                        </p>

                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Element>
                                                }
                                            </div>
                                            <JobBlockComponent title="Related Jobs" itemPerPage="4" relatedJobs="yes" blockHeight='w-100' />

                                        </Tabs>

                                    </div>




                                </TabPanel>
                            }
                        </div>
                        {
                            noDataText &&
                            <div className="no-vacancy-container">
                                <div className="no-vacancy-message">
                                    <h5>Right now there is no vacancy for your profile, so we will update you when there will be vacancy</h5>
                                </div>
                            </div>
                        }
                    </Tabs>
                </div >
            </section >
        </>
    );
}

export default JobsBlock;
