import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Routes, Route, useNavigate } from 'react-router-dom';
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonComponent from "./skeleton";
function OpportunityCategory({
  currentItems,
  selectedLetter,
  fetchSortedData,
  showSkeletonComponent,
  urlData,
}) {
  let filteredAndSortedData = currentItems;
  // console.log('L-14', currentItems);
  const navigate = useNavigate();
  const url = window.location.href;

  let sorteditem = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  let currentItemsArray = [];
  if (selectedLetter) {
    filteredAndSortedData = currentItems
      .filter(
        (item) =>
          selectedLetter === " " ||
          item.name.toLowerCase().startsWith(selectedLetter.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  } else {
    filteredAndSortedData = currentItems;
  }

  currentItems.forEach((element) => {
    const firstChar = element.name.charAt(0).toUpperCase();
    currentItemsArray.push(firstChar);
  });

  return (
    <>

      <div className="">
        <div className="row">
          <div className=" mb-5 w-100" >
            {urlData && (
              <>
                <button
                  style={{ textAlign: "center" }}
                  onClick={() => fetchSortedData("")}
                >
                  All
                </button>

                {sorteditem.map((letter) => (

                  <button
                    key={letter}
                    style={{ width: "40px", padding: "7px" }}
                    className={!currentItemsArray.includes(letter) && 'opacity-50'}
                    onClick={() => currentItemsArray.includes(letter) && fetchSortedData(letter)}
                  >
                    {letter}
                  </button>

                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">


          <div className="group-category-job wow fadeInUp animated">
            {filteredAndSortedData.map((idx, index) => {
              const itemsPerRow = 5; // Adjusted to 5 items per row
              const rowIndex = Math.floor(index / itemsPerRow);
              const colIndex = index % itemsPerRow;

              const isEvenRow = rowIndex % 2 === 0;
              const isEvenCol = colIndex % 2 === 0;

              const bgColorClass = (isEvenRow && isEvenCol) || (!isEvenRow && !isEvenCol)
                ? 'job-category-box-bg-color'
                : 'job-category-box-bg-color-even';

              const textColorClass = (isEvenRow && isEvenCol) || (!isEvenRow && !isEvenCol)
                ? 'text-dark'
                : 'text-light';

              const buttonClass = (isEvenRow && isEvenCol) || (!isEvenRow && !isEvenCol)
                ? 'btn-category-job explore_jobs'
                : 'text-light explore_jobs';

              return (
                <div className={bgColorClass} key={index}>
                  <Link
                    title={idx.slug}
                    to={`${process.env.REACT_APP_ROUTES_OPPORTUNITY_TYPE}/${idx.slug}`}
                    state={idx.slug}
                    className="job-category-link"
                  >
                    <div>
                      <div className="job-category-header">
                        <h3 className={`opportunity-name ${textColorClass}`}>
                          {idx.name.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                        </h3>
                        <p className={textColorClass}>{idx.count} Jobs available</p>
                      </div>
                      <div className={buttonClass}>
                        Explore Jobs <span className="icon-keyboard_arrow_right"></span>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}

            {showSkeletonComponent && (
              <>
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
              </>
            )}
          </div>

        </div>
      </div>


    </>
  );
}

export default OpportunityCategory;
