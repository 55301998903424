import React, { useEffect, useState } from "react";
import CourseCategory from "./typeOfCourse";
import { useDispatch, useSelector } from "react-redux";
import { getDataFromApi } from "../../../slice/getDataSlice";
import { Link, useLocation } from "react-router-dom";
import CourseDetails from '../../../main_module/find_jobs';
import UniversityList from '../university_list';
import Skeleton from "../opportunity/skeleton";

function Course({ currentItemsHome }) {
  const dispatch = useDispatch();
  const url = process.env.APP_URL;
  const [Course, setCourse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLetter, setSelectedLetter] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(
    currentItemsHome ? currentItemsHome : 12
  );
  const [urlData, setUrlData] = useState("");
  const base_url = process.env.REACT_APP_BASE_URL;
  const course_url = process.env.REACT_APP_ROUTES_COURSE_URL;
  const [currenturl, setCurrenturl] = useState(process.env.REACT_APP_ROUTES_HOME);

  const [loading, setLoading] = useState(true);
  const location = useLocation();
  let parent_slug = location.state ? location.state : 0;

  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [currentItemsHome]);


  useEffect(() => {
    const fetchData = () => {
      const url = window.location.href;



      if (url === course_url) {

        parent_slug = location.state ? location.state : 0;
        setUrlData(url + parent_slug);
      }



      const dynamicUrl = process.env.REACT_APP_COURSE_API_URL;

      dispatch(getDataFromApi({ Url: dynamicUrl, parent_slug: parent_slug?.id }))
        .then((action) => {
          const updatedCourseData = action.payload;

          if (updatedCourseData) {
            setCourse(updatedCourseData.data);

          } else {
            setCourse([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, [dispatch, currentPage, itemsPerPage, parent_slug]);
  const totalPages = Math.ceil(Course.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let currentItems = Course.slice(0, endIndex);

  const paginateData = () => {
    setCurrentPage(currentPage + 1);
  };
  function fetchSortedData(e) {
    setSelectedLetter(e);
  }

  return (
    <>
      {
        !loading ?
          ((currentItems.length > 0) ? (
            <section className="tf-slider sl7 over-flow-hidden opportunityType">
              <div className="tf-container">
                <div className="tf-title style-2">
                  <div className="group-title mt-5">
                    <h1>Choose Best Course</h1>
                    <p> Find Colleges & Courses that are Best for You</p>
                  </div>
                </div>


                <CourseCategory
                  currentItems={currentItems}
                  urlData={urlData}
                  fetchSortedData={fetchSortedData}
                />

                {urlData && currentPage < totalPages && (
                  <div className="col-md-12" id="show_more_button">
                    <div className="wrap-button">
                      <button className="load-more-button" onClick={paginateData}>
                        Load More
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          ) :
            // <UniversityList />
            <></>
          ) : (<Skeleton />)
      }


    </>
  );
}

export default Course;
