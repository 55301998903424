import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import banner from '../../../assets/images/singlejob.jpg'
import { getDataFromApi } from "../../../slice/getDataSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import MetaTag from "../meta-tag";
import Breadcrumb from "../../google_schema/breadcrumb";
import Job_posting_google_schema from "../../google_schema/job_posting_google_schema";
import img1 from '../../../assets/images/blog-detail-02.jpg'
import img2 from '../../../assets/images/blog-detail-03.jpg'
import img3 from '../../../assets/images/blog.jpg';
import RelatedCompany from '../company/index';
import FaqItem from "../faq";
import Swal from 'sweetalert2'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import withReactContent from 'sweetalert2-react-content'
import {
  FacebookShareButton,
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { sendUserData } from '../../../slice/postSlice';
import Iframe from 'react-iframe'

function CompanyDetails({ params }) {
  const marKers = [
    {
      id: 1,
      title: "Rockstar Games New York",
      name: "Senior UI/UX Designer",
      address: "Las Vegas, NV 89107, USA",
      longitude: -74.00122,
      latitude: 40.71023,
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const slug_name = location.state;
  console.log('L-51',slug_name);
  const [location_link, setLocation_link] = useState('');
  const [opportunity, setOpportunityData] = useState([]);
  const image = [img1, img2, img3];
  const [reportReason, setReportReason] = useState({
    userid: sessionStorage.getItem('login_user_id') ? sessionStorage.getItem('login_user_id') : '',
    company_slug: location.state ? location.state : '',
    reason: ""
  });
  const [reportModal, setReportModal] = useState(false);
  const dispatch = useDispatch();
  const [Company, setCompany] = useState([]);
  const [totalEmployee, setTotalCompany] = useState();
  const base_url = process.env.REACT_APP_BASE_URL;
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {

    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_COMPANY_API_URL + slug_name;
      const payload = {};
      dispatch(getDataFromApi({ API_URL: dynamicUrl,payload:payload }))
        .then((action) => {
          const updatedCompanyData = action.payload;
          // console.log(updatedCompanyData.status === false);
          if (updatedCompanyData.status === false) {
            navigate(process.env.REACT_APP_ROUTES_OPPORTUNITY_DETAILS);
            withReactContent(Swal).fire({
              title: "Error!!!",
              text: "Company Page Not Found"

            });
          }
          if (updatedCompanyData) {
            console.log('L-85',updatedCompanyData);
            setCompany(updatedCompanyData.data);
            setLocation_link(`${location_link}`)
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);

        });

    };

    fetchData();
  }, [dispatch]);

  const [iframeUrl, setIframeUrl] = useState('');
  function isValidURL(url) {
    const urlRegex = /^(http|https):\/\/[^\s]+/; // Basic URL validation (check for protocol and some content)
    return urlRegex.test(url);
  }

  useEffect(() => {
    const fetchData = () => {

      const dynamicUrl = process.env.REACT_APP_COMPANY_API_URL + slug_name;
      dispatch(getDataFromApi({ Url: dynamicUrl }))
        .then((action) => {
          const updatedOpportunityData = action.payload;
          if (updatedOpportunityData.status === false) {
            navigate(-1);
          }
          if (updatedOpportunityData) {
            setOpportunityData(updatedOpportunityData.data.items);

          } else {
            setOpportunityData([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    fetchData();
  }, [dispatch, slug_name]);
  const totalEmployeeCount = () => {
    if (Company) {
      setTotalCompany(Company.how_many_female + Company.how_many_male);
    }
  }
  useEffect(() => {
    totalEmployeeCount();
  }, [Company])

  const home = process.env.REACT_APP_ROUTES_HOME;
  const company_type = process.env.REACT_APP_ROUTES_COMPANY_TYPE;

  const breadcrumb = [
    {
      home: base_url + home,
      'company-type': base_url + company_type,
      'company-details': base_url + slug_name,
    }
  ];
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const reportjob = (event) => {
    event.preventDefault();
    const url = process.env.REACT_APP_REPORT_JOB_API_URL

    try {
      if (reportReason) {
        // console.log(reportReason);
        dispatch(sendUserData({ data: reportReason, pageurl: url }))
          .then((action) => {

            const result = action.payload.message;
            if (result) {
              setReportModal(false);
            }
            withReactContent(Swal).fire({
              title: "Success",
              text: result
            });

          })
          .catch((error) => {
            withReactContent(Swal).fire({
              title: "Error!!!",
              text: "SomeThing Wents To Wrong"

            });
          });
      } else {
        alert('SomeThing Wents To Wrong');

      }
    } catch (error) {
      console.error('Error sending data to API:', error.message);
    }


  }
  return (
    <>
      {
        Company &&
        <>

          <div className="wrapper">

            <MetaTag title={(Company && Company.company_name) ? Company.company_name : 'Spot In Job Consultancy'} name="company page" content="Explore exciting job opportunities at YourCompany. Browse and apply for jobs in various fields and locations. Start your career with us!" />

            <Breadcrumb breadcrumb={breadcrumb} />
            <Job_posting_google_schema data={Company} />

          </div>
          <section className="single-job-thumb tf-slider sl7 over-flow-hidden opportunityType">
            <img
              src={banner}
              alt="banner"
            />
          </section>

          <section className="form-sticky fixed-space">

            <div className="row">
              <div className="col-lg-12">
                <div className="wd-job-author stc-em">
                  <div className="inner-job-left">
                    <img
                      src={Company?.company_logo_url}
                      alt="Jobtex"
                      className="logo-company"
                    />
                    <div className="content">
                      <h1 className="companyDetails">
                        <Link to="#" className="company-name" title={Company.company_name}>{Company.company_name}</Link>&nbsp;

                      </h1>
                      <div className="job-info">
                        <span className="icon-map-pin"></span>&nbsp;
                        <span>{Company.company_street_address + ', ' + Company.city_name}</span>
                      </div>
                      <div className="group-btn">
                        <button className="tf-btn">2 job openings</button>
                        <button type="button" className="job-type-btn" onClick={() => setReportModal(true)}>Report Company</button>

                      </div>
                      <Modal
                        size="lg"
                        isOpen={reportModal}
                        toggle={() => setReportModal(!reportModal)}
                      >
                        <ModalHeader
                          toggle={() => setReportModal(!reportModal)}
                        >
                          Enter Your Details
                        </ModalHeader>
                        <ModalBody>
                          {
                            (localStorage.getItem('items')) ?
                              <form onSubmit={reportjob}>
                                <div>
                                  <label>Reason For Report Job</label>
                                  <textarea
                                    value={reportReason.reason}
                                    onChange={(e) => setReportReason({
                                      ...reportReason,
                                      reason: e.target.value
                                    })}
                                  ></textarea>
                                </div>
                                <button type="submit">
                                  Apply
                                </button>
                              </form>
                              :
                              <div>Need to report this opportunity? You Need to Login</div>
                          }
                        </ModalBody>
                      </Modal>
                    </div>
                  </div>
                  <div className="content-right">
                    <div className="top">
                      {showPopup && (
                        <div className="popup-company">
                          <button><FacebookShareButton url={window.location.href} ><FacebookIcon size={32} round={true} /></FacebookShareButton></button>
                          <button><TwitterShareButton url={window.location.href}><TwitterIcon size={32} round={true} /></TwitterShareButton></button>
                          <button><LinkedinShareButton url={window.location.href}><LinkedinIcon size={32} round={true} /></LinkedinShareButton></button>
                          <button><WhatsappShareButton url={window.location.href}><WhatsappIcon size={32} round={true} /></WhatsappShareButton></button>
                          <button onClick={togglePopup}>

                            <span role="img" aria-label="Close" className="close-button-company" >&#10006;</span>
                          </button>
                        </div>
                      )}
                      <Link to="#" className="share" onClick={togglePopup} title="Share">
                        <i className="icon-share2" />
                      </Link>
                      <Link to="#" className="wishlist" title="Wishlist">
                        <i className="icon-heart" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>

          <section className="inner-employer-section">
            <div className="tf-container">
              <div className="row">
                <div className="col-lg-8">
                  <Tabs className="job-article tf-tab single-job single-employer">
                    <TabList className="menu-tab">
                      {/* <Tab className="ct-tab">Jobs</Tab> */}
                      <Tab className="ct-tab">AboutUs</Tab>
                      <Tab className="ct-tab">Gallery</Tab>
                      <Tab className="ct-tab">FAQ</Tab>

                    </TabList>
                    <div className="content-tab">
                      {/* <TabPanel className="inner-content animation-tab ">
                    {
                  opportunity.map((idx) => (

                    <div key={idx.id} className="col-lg-8 ">
                      <div className="row">
                       
                      <div className="features-job">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img src={idx.opportunity_type_logo_url} alt="jobtex" />
                            </div>
                            <div className="box-content ">
                              <h4 className="opportunityTypeCompanyName" style={{ marginBottom: "0px" }}>
                                <Link to="#">{idx.company_name}</Link>
                              </h4>
                              <h3 className="opportunityTypeName">
                                <Link to="#"> {idx.title} </Link>

                              </h3>
                              <ul>
                                <li>
                                  <span className="icon-map-pin"></span>
                                  &nbsp;
                                  {idx.city_name},{idx.state_name}
                                </li>
                                <li>
                                  <span className="icon-calendar"></span>
                                  &nbsp;
                                  <span>total opening &nbsp;</span>
                                  {idx.number_of_opening}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left">
                            <ul className="job-tag">
                              <li>
                                <Link to="#">{idx.job_type}</Link>
                              </li>
                            </ul>
                            <div className="star">
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                              <span className="icon-star-full"></span>
                            </div>
                          </div>
                          <div className="job-footer-right">
                            <div className="price">

                              <p>
                                {idx.minimum_salary}-{idx.maximum_salary}
                                <span className="year">/month</span>
                              </p>
                            </div>
                            <Link
                             to={`/apply-form`}
                             state={idx.opportunity_type_slug}
                             className="days">
                              Apply
                            </Link>
                          </div>
                        </div>
                       
                      </div>
                     </div> 
                    </div>

                  ))

                }
                
               
                    
                  </TabPanel> */}
                      <TabPanel className="inner-content animation-tab">
                        <h2 className="h5">About Company</h2>
                        <p>
                          WE BELIEVE IN THE POWER OF DESIGN, THE STRENGTH OF STRATEGY, AND THE ABILITY OF TECHNOLOGY TO TRANSFORM BUSINESSES AND LIVES.
                        </p>

                      </TabPanel>
                      <TabPanel className="inner-content animation-tab">


                        <div className="">
                          {image.map((item) => (

                            <img src={item} key={item} alt="Spot In Job Consultancy" style={{ width: "150px", height: "150px", marginRight: "10px" }} />

                          ))}
                        </div>

                      </TabPanel>
                      <TabPanel>
                        <FaqItem />
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
                <div className="col-lg-4">
                  <div className="cv-form-details po-sticky job-sg single-stick">
                    {Company.location_link ?
                    <div className="google-map"
                      dangerouslySetInnerHTML={{ __html: Company.location_link }}>
                    </div>
                    :
                    <div className="google-map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.438346606197!2d70.77983987339688!3d22.299255879687347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959ca27bcac697f%3A0x50eaaebb92b9790b!2sSpot%20In%20Job%20Consultancy!5e0!3m2!1sen!2sin!4v1719463375863!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    }
                    <ul className="list-infor">

                      {
                        Company.website_link && (
                          <li>
                            <div className="category">Website</div>
                            <div className="detail">
                              <Link to={Company.website_link} title={Company.website_link}>
                                {Company.website_link}
                              </Link>
                            </div>
                          </li>
                        )}
                      {
                        Company.company_email && (
                          <li>
                            <div className="category">Email</div>
                            <div className="detail">
                              <Link to={`mailto:${Company.company_email}`}>{Company.company_email}</Link>

                            </div>
                          </li>
                        )
                      }

                      {
                        totalEmployee != 0 && (
                          <li>
                            <div className="category">Company size</div>
                            <div className="detail">{totalEmployee} employees</div>
                          </li>
                        )
                      }
                      {
                        Company.company_street_address && (
                          <li>
                            <div className="category">Headquarters</div>
                            <div className="detail">{Company.company_street_address + ', ' + Company.city_name}</div>
                          </li>
                        )
                      }

                    </ul>

                    <div className="wd-social d-flex aln-center">
                      {(Company.linkedin_link || Company.facebook_link || Company.google_shared_link) && (
                        <span>Socials:</span>
                      )}

                      <ul className="list-social d-flex aln-center">
                        {
                          Company.facebook_link && (
                            <li>
                              <Link to={`${Company.facebook_link}`} title="Facebook">
                                <i className="icon-facebook"></i>
                              </Link>
                            </li>
                          )
                        }
                        {
                          Company.linkedin_link && (
                            <li>
                              <Link to={`${Company.linkedin_link}`} title="LinkedIn">
                                <i className="icon-linkedin2"></i>
                              </Link>
                            </li>
                          )
                        }
                        {
                          Company.google_shared_link && (
                            <li>
                              <Link to={`${Company.google_shared_link}`} title="google">
                                <FontAwesomeIcon icon={faGoogle} />
                              </Link>
                            </li>
                          )
                        }
                      </ul>
                    </div>
                    <div className="form-job-single">
                      <h6>Contact Us</h6>
                      <form action="post">
                        <input type="text" placeholder="Subject" />
                        <input type="text" placeholder="Name" />
                        <input type="email" placeholder="Email" />
                        <textarea placeholder="Message..."></textarea>
                        <button type="button">Send Message</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <RelatedCompany title="Related Company" PerPage="3" />
        </>
      }
    </>
  );
}

export default CompanyDetails;
