import React, { useState, useEffect } from "react";
import banner from '../../../assets/images/singlejob.jpg'
import { useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { getDataFromApi } from "../../../slice/getDataSlice";
import { sendUserData } from '../../../slice/postSlice';
import Select from 'react-select';


function Apply_form() {
    const experience = ['Less than 1 year', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
    const noticePeriod = ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'];
    const [profile, setProfile] = useState([]);

    const [opportunity, setOpportunityData] = useState([]);
    const dispatch = useDispatch();
    const [loginToken, setLoginToken] = useState('');
    const location = useLocation();
    const opportunity_slug = location.state;
    const login_token = (localStorage.getItem('items'));
    const [userProfileData, setUserProfileData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        pincode: '',
        state: '',
        city: '',
        city_id: '',
        state_id: '',
        country_id: '',
        qualification: '',
        total_experience: '',
        designation: '',
        date_of_birth: ''
    });
    const [Opportunity, setOpportunity] = useState([]);
    const [selectDesignation, setSelectDesignation] = useState();
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [stateid, setStateid] = useState(0);
    if (opportunity_slug) {
        const store_slug = sessionStorage.setItem('opportunity_slug', opportunity_slug);

    }
    useEffect(() => {
        setLoginToken(login_token);
    }, [])

  
    useEffect(() => {
        const fetchDesignation = () => {

            const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_API_URL;
            dispatch(getDataFromApi({ API_URL: dynamicUrl }))
                .then((action) => {
                    const updatedOpportunityData = action.payload;
                    if (updatedOpportunityData) {
                        setOpportunity(updatedOpportunityData.data);
                    } else {
                        setOpportunity([]);
                    }
                    // console.log('l-58', Opportunity.name);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
        const fetchState = () => {
            const dynamicUrl = process.env.REACT_APP_STATE_API_URL;
            const payload = {country_id:101}
            dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                .then((action) => {
                    const updatedStateData = action.payload;
                    if (updatedStateData) {
                        setState(updatedStateData.data);
                    } else {
                        setState([]);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
        const senddata = () => {

            const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_DETAILS_API_URL;
            const opportunity_name = (sessionStorage.getItem('opportunity_slug'));
            dispatch(getDataFromApi({ Url: dynamicUrl, slug: opportunity_name }))
                .then((action) => {
                    const updatedOpportunityData = action.payload;
                    if (updatedOpportunityData) {
                        setOpportunityData(updatedOpportunityData.data.items);

                    } else {
                        setOpportunityData([]);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        };
        const getUserData = () => {

            const dynamicUrl = process.env.REACT_APP_GET_PROFILE_API_URL;
            const login_token = localStorage.getItem('items');

            dispatch(sendUserData({ token: login_token, pageurl: dynamicUrl }))
                .then((action) => {
                    const getProfileData = action.payload;

                    if (getProfileData) {
                        setProfile(getProfileData.data);

                        setUserProfileData(
                            {
                                name: getProfileData.data.name,
                                phone: getProfileData.data.phone,
                                email: getProfileData.data.email,
                                address: getProfileData.data.address,
                                pincode: getProfileData.data.pincode,
                                state: getProfileData.data.state,
                                city: getProfileData.data.city,
                                city_id: getProfileData.data.city_id,
                                state_id: getProfileData.data.state_id,
                                country_id: getProfileData.data.country_id,
                                qualification: getProfileData.data.qualification,
                                total_experience: getProfileData.data.total_experience,
                                designation: getProfileData.data.designation,
                                date_of_birth: getProfileData.data.date_of_birth
                            }
                        )

                        setStateid(getProfileData.data.state_id);

                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
        fetchDesignation();
        fetchState();
        senddata();
        getUserData();
    }, [dispatch]);

    const changeStateId = (e) => {
        setStateid(e.target.value);
    }

    //fetch city data
    useEffect(() => {
        const fetchCity = () => {
            const dynamicUrl = process.env.REACT_APP_CITY_API_URL;
            const payload= {country_id:101 , state_id:stateid}
            dispatch(getDataFromApi({ API_URL: dynamicUrl, payload:payload }))
                .then((action) => {
                    const updatedCityData = action.payload;
                    if (updatedCityData) {
                        setCity(updatedCityData.data);

                    } else {
                        setCity([]);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
        fetchCity();
    }, [dispatch, stateid]);


    return (
        <>
            <section className=" tf-slider sl7  opportunityType mb-5">
                <img
                    src={banner}
                    alt="apply-job-images"
                />
            </section>

            <section className="container">
                <div className="row">
                    <h1 className="text-center">Apply For Job</h1>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Full Name <span className="tx-danger">*</span></label>
                            <input
                                type="text"
                                id="fname"
                                name="fname"
                                placeholder="Enter Full Name"
                                value={profile.name}
                                required
                            />
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Location <span className="tx-danger">*</span></label>
                            <input
                                type="text"
                                id="location"
                                name="location"
                                placeholder="Enter Location"
                                value={profile.address + "," + profile.city + "," + profile.state}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-6">

                        <div className="form-group">
                            <label for="name">State</label>
                            <select name="state_id" id="state" className="react-dropdown select-location" onChange={changeStateId}>
                                {(!profile.state_id) ? <option value="">Select State</option> : <option value={userProfileData.state_id} selected>{userProfileData.state}</option>}
                                {
                                    state.map((items) =>
                                        <option key={items.id}
                                            value={items.id}
                                            className="react-dropdown select-location"

                                        >
                                            {items.name}
                                        </option>
                                    )

                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">

                        <div className="form-group">
                            <label for="name">City</label>
                            <select name="city_id" id="city" className="react-dropdown select-location" >

                                {(!profile.city_id) ? <option value="">Select State</option> : <option value={userProfileData.city_id} selected>{userProfileData.city}</option>}

                                {
                                    (stateid && city) ?

                                        city.map((items) =>
                                            <option key={items.id}
                                                value={items.id}
                                                className="react-dropdown select-location"

                                            >
                                                {items.name}
                                            </option>
                                        ) :
                                        <option value={userProfileData.city_id} selected>{userProfileData.city}</option>
                                }

                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Email <span className="tx-danger">*</span></label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter Email"
                                value={profile.email}
                                required
                            />
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Phone <span className="tx-danger">*</span></label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder="Enter Phone"
                                value={profile.phone}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Designation <span className="tx-danger">*</span></label>
                            <div style={{height:"50px"}}>
                                <Select 
                                   
                                    options={Opportunity.map(option => ({
                                        value: option.slug, 
                                        label: option.name
                                    }))}
                                    value={selectDesignation}
                                    onChange={setSelectDesignation}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Experience  <span className="tx-danger">*</span></label>
                            <select name="experience" id="experience">
                                <option value="">---- Select Any Option ----</option>

                                {
                                    experience.map((experience) => <option value={experience + '+years'}>{experience + '+years'}</option>)
                                }

                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Current Salary (CTC Per Month)<span className="tx-danger"></span></label>
                            <input
                                type="number"
                                id="currentSalary"
                                name="currentSalary"
                                placeholder="Enter Current Salary"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Expected Salary (CTC Per Month)<span className="tx-danger"></span></label>
                            <input
                                type="number"
                                id="expectedSalary"
                                name="expectedSalary"
                                placeholder="Enter Expected Salary"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Notice Period  <span className="tx-danger">*</span></label>
                            <select name="noticePeriod" id="noticeperiod">
                                <option value="">---- Select Any Option ----</option>
                                {
                                    noticePeriod.map((noticePeriod) => <option value={noticePeriod + '-days'}>{noticePeriod + '-days'}</option>)
                                }

                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Gender <span className="tx-danger">*</span></label>
                            <select name="gender" id="gender">
                                <option value="">---- Select Any Option ----</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label for="title">
                                Marital Status <span className="tx-danger">*</span></label>
                            <select name="marital_status" id="marital_status">
                                <option value="">---- Select Any Option ----</option>
                                <option value="married">Married</option>
                                <option value="unmarried">Unmarried</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label for="title">
                                Upload CV <span className="tx-danger">* (Note: Upload file with extensions .docx, .doc, .pdf only. Upload upto 2MB only.)</span></label>
                            <input
                                type="file"
                                id="cv"
                                name="cv"
                                style={{ border: "1px solid #e5e5e5", height: "50px", padding: "12px 30px", width: "100%" }}
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="loginButton">Send</button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Apply_form;
