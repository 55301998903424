import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import SearchComponent from '../search'
import { getDataFromApi, getDataSlice } from "../../../slice/getDataSlice";
import { useSelector, useDispatch } from 'react-redux';


function Banner09() {
  const popularSearches = ["Designer", "Developer", "Tester", "Writing..."];
  const userImages = ["bran4.webp", "bran1.webp", "bran2.webp", "bran3.webp"];
  const icons = ["icon11.webp", "icon22.webp", "icon3.webp", "icon2.webp"];
  const dispatch = useDispatch();
  const [opportunity, setOpportunity] = useState([]);

  useEffect(() => {
    // Dispatch your async action to fetch data if needed
    dispatch(getDataFromApi({ API_URL: process.env.REACT_APP_OPPORTUNITY_API_URL, payload: {} }))
      .then((action) => {

        if (action?.payload?.data) {
          setOpportunity(action.payload.data.slice(0,3));
        }


      })
  }, [dispatch]);
 
  return (
    <section className="tf-slider sl7 over-flow-hidden">
      <div className="tf-container">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="content wow fadeInUp">
              <div className="heading">
                <h1 className="banner-heading">Find The Best Job Which Suits Your Growth</h1>
                <p>
                  At Spot In Job, we believe the right job is more than just a paycheck—it's about thriving and growing. Our expert consultants understand your unique skills and career goals, matching you with opportunities that promote both professional and personal development.
                </p>
              </div>
              <div>
                <SearchComponent display="d-none" />
              </div>
              <ul className="mt-2 list-tag">
                <li className="heading">Popular Searches:</li>
                {opportunity.map((search, index) => (
                  <li key={search.id}>
                    <Link
                      title={search.slug}
                      to={`${process.env.REACT_APP_ROUTES_OPPORTUNITY_TYPE}/${search.slug}`}
                      state={search.slug}
                    >{search.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="wd-review-job widget-counter sl7">
              <div className="thumb3">
                {/* <div className="trader-box sl7">
                  <div className="content">
                    <h2 className="number wrap-counter">
                      <CountUp
                        className="number"
                        end={25}
                        suffix="M+"
                        duration={3}
                      />
                    </h2>
                    <div className="des">Jobs Available</div>
                  </div>
                  <div className="shape ani7">
                    <img
                      src={require("../../../assets/images/shape6.webp")}
                      alt="banner-shape6"
                    />
                  </div>
                </div> */}
                {/* <div className="group-user">
                  <div className="user-box">
                    {userImages.map((image, index) => (
                      <img
                        key={index}
                        src={require(`../../../assets/images/${image}`)}
                        alt={`banner-${image.split('.')[0]}`}
                      />
                    ))}
                  </div>
                  <div className="content">
                    <h3 className="wrap-counter text-pri">
                      <span>+</span>
                      <span
                        className="counter-number"
                        data-speed="2000"
                        data-to="4800"
                      >
                        4800
                      </span>
                    </h3>
                    <p>Employers get benefits </p>
                  </div>
                </div> */}
                {/* <div className="group-thumb">
                  <img
                    height="613px"
                    width="319px"
                    src={require("../../../assets/images/thumb7.webp")}
                    alt="banner-image1"
                  />
                  <div className="shape-thumb ani8">
                    <img
                      src={require("../../../assets/images/shape5.webp")}
                      alt="banner-image2"
                    />
                  </div>
                </div> */}
                {/* {icons.map((icon, index) => (
                  <div key={index} className={`icon${index + 1} ani${index + 4}`}>
                    <img
                      src={require(`../../../assets/images/${icon}`)}
                      alt={`banner-${icon.split('.')[0]}`}
                    />
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner09;
