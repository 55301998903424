import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faClock, faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons'
import MetaTag from '../meta-tag';
import Breadcrumb from '../../google_schema/breadcrumb'
import Company_google_schema from '../../google_schema/company_google_schema'
import clgLogo from '../../../assets/images/IIM.jpg';

function Blog_list() {
  const data = [
    {
      id: 1,
      name: 'IIM Ahmedabad - Indian Institute of Management',
      location: "Ahmedabad",
      type: "Govt",
      courses: "9 Courses",
      tuitionFees: '₹1.57 L - 31.5 L',
      mediumSalary: '$20 - 33.05 LPA'
    },
    {
      id: 2,
      name: 'IIM Ahmedabad - Indian Institute of Management',
      location: "Ahmedabad",
      type: "Govt",
      courses: "9 Courses",
      tuitionFees: '₹1.57 L - 31.5 L',
      mediumSalary: '$20 - 33.05 LPA'
    },
    {
      id: 3,
      name: 'IIM Ahmedabad - Indian Institute of Management',
      location: "Ahmedabad",
      type: "Govt",
      courses: "9 Courses",
      tuitionFees: '₹1.57 L - 31.5 L',
      mediumSalary: '$20 - 33.05 LPA'
    },
    {
      id: 4,
      name: 'IIM Ahmedabad - Indian Institute of Management',
      location: "Ahmedabad",
      type: "Govt",
      courses: "9 Courses",
      tuitionFees: '₹1.57 L - 31.5 L',
      mediumSalary: '$20 - 33.05 LPA'
    },
    {
      id: 5,
      name: 'IIM Ahmedabad - Indian Institute of Management',
      location: "Ahmedabad",
      type: "Govt",
      courses: "9 Courses",
      tuitionFees: '₹1.57 L - 31.5 L',
      mediumSalary: '$20 - 33.05 LPA'
    },
    {
      id: 6,
      name: 'IIM Ahmedabad - Indian Institute of Management',
      location: "Ahmedabad",
      type: "Govt",
      courses: "9 Courses",
      tuitionFees: '₹1.57 L - 31.5 L',
      mediumSalary: '$20 - 33.05 LPA'
    }


  ]
  return (
    <>

      <div className='container'>
        <div className='row mt-5'>
          {data.map((item) => (
            <div key={item.id} className="col-lg-6">
              <div className="features-job">
                <div className="job-archive-header">
                  <div className="pt-3 pb-3">
                    <div className='blog d-flex'>
                      <div>
                        <img src={clgLogo} className='clg-logo' alt={item.name} />
                      </div>
                      <div>
                        <h4 className='uni-name'>{item.name}</h4>
                        <div className='uni-address'>
                          <FontAwesomeIcon icon={faLocationDot} />
                          <span className='uni-span'>{item.location}</span> &nbsp;&nbsp;
                          <span className='uni-span'>{item.type}</span>
                        </div>
                      </div>
                      <hr />
                    </div>
                    <hr />
                    <div className='d-flex justify-content-between'>
                      <div>
                        <div>
                          <label className='uni-label'>Courses Offered</label>
                          <div>
                            <Link to="#" className='uni-link' title={item.courses}>{item.courses}</Link>
                            <FontAwesomeIcon icon={faStar} /> 4.7
                          </div>
                        </div>
                        <div>
                          <label className='uni-label'>Total Tuition Fees</label>
                          <div>
                            <Link to="#" className='uni-fees' title={item.tuitionFees}>{item.tuitionFees}</Link>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label className='uni-label'>Exams Accepted</label>
                          <div>
                            <span>CAT,GATE +3 </span>
                          </div>
                        </div>
                        <div>
                          <label className='uni-label'>Median Salary</label>
                          <div>
                            <Link to="#" className='uni-fees' title={item.mediumSalary}>{item.mediumSalary}</Link>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button className='uni-compare-button'>Compare</button>
                      </div>
                    </div>
                    <Link
                    title=''
                      to={process.env.REACT_APP_ROUTES_UNIVERSITY_DETAILS}
                      className="jobtex-link-item"
                      tabIndex="0"
                    ></Link>
                  </div>



                </div>
              </div>

            </div>

          ))}
        </div>
      </div>
    </>
  )
}

export default Blog_list