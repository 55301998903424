import React from 'react'
import Blog_list from '../components/main/blog_list/index'
import Search from '../components/main/search/index'
import MetaTag from '../components/main/meta-tag';
import Company_Header from '../components/main/company/company-header';

function Blog() {
  const schema = {

    "@context": "http://schema.org",
    "@type": "Blog",
    "name": "Your Blog",
    "description": "A collection of insightful articles covering various topics.",
    "url": "http://www.yourblog.com",
    "publisher": {
      "@type": "Organization",
      "name": "Your Blog",
    }
  };

  return (
    <>
 
      <MetaTag title="Blog" name="description" content="Explore exciting job opportunities at YourCompany. Browse and apply for jobs in various fields and locations. Start your career with us!" />

      <div className="wrapper">
        <script type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({ schema }),
          }} />

      </div>
      <div>
      <Company_Header  title="Blog" description="Blogs To Read When You Need A Little Inspiration" contactus_header="contactus_header"/>
        <Blog_list />
      </div>
    </>
  )
}

export default Blog