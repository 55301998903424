import React,{useEffect, useState} from 'react'
import banner from '../../../assets/images/singlejob.jpg'
import { useDispatch } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import Header from '../company/company-header'

function Cookie_policy() {
  const dispatch = useDispatch();
  const [cookie_policy_data,setCookie_policy_data] = useState([])
  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_WEBSITE_STATIC_PAGE_API_URL+'/cookie_policy';
      const payload ={}
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload : payload}))
        .then((action) => {
          const updatedCookieData = action.payload;

          if (updatedCookieData) {
            setCookie_policy_data(updatedCookieData.data);
            // console.log('l-23',updatedCookieData.data.value);

          } else {
             setCookie_policy_data([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
      <Header  title="Cookie Policy" description="" contactus_header="contactus_header"/>

    <section className="term-section">
        <div className='container mt-5'>
        <div dangerouslySetInnerHTML={{ __html: cookie_policy_data.value}} />

        </div>
       

    </section>
    </>
  )
}

export default Cookie_policy