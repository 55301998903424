import React from 'react'
import { Helmet } from 'react-helmet';

function MetaTag({name,content,schema,title}) {
  return (
    <>
    
    <Helmet>
        <title>{title}</title>
         <meta name={name} content={content} />
         {/* <meta name={name}  content="noindex" /> */}
         
        
    </Helmet>
    </>
  )
}

export default MetaTag