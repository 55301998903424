import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { getDataFromApi } from "../../../slice/getDataSlice";
import MetaTag from "../meta-tag";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Company_Header from '../company/company-header'
import Select from 'react-select'

function Upload_CV({details}) {
    const experience = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
    const noticePeriod = ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90'];

    // const [profile, setProfile] = useState([]);
    const [query, setQuery] = useState([]);
    const [dataSelect2, setDataSelect2] = useState([]);
    const dispatch = useDispatch();
    const [loginToken, setLoginToken] = useState('');
    const location = useLocation();
    const opportunity_slug = location.state;
    const login_token = (localStorage.getItem('items'));
    const [uploadFile, setUploadFile] = useState(null);
    const inputFile = useRef(null);
    const [isChecked, setIsChecked] = useState(true);

    const [userProfileData, setUserProfileData] = useState({
        name: details ? details.name : '',
        email: details ? details.email : '',
        phone: details ? details.phone : '',
        address: details ? details.address : '',
        city_id: '',
        state_id: '',
        country_id: 101,
        qualification: details ? details.highestEducation : '',
        designation: details ? details.designation : '',
        total_experience: details ? details.experience : '',
        on_notice_period:'',
        notice_period: '0',
        gender: details ? details.gender : '',
        marital_status: '',
        current_ctc: '',
        expected_ctc: '',
        current_cv: null,
        ready_to_relocate: '',
        relocate_city: ''
    });

    const [Opportunity, setOpportunity] = useState([]);

    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [stateid, setStateid] = useState(0);

    if (opportunity_slug) {
        const store_slug = sessionStorage.setItem('opportunity_slug', opportunity_slug);

    }
    useEffect(() => {
        setLoginToken(login_token);
    }, [])


    useEffect(() => {
        const fetchDesignation = () => {

            const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_API_URL;
            dispatch(getDataFromApi({ API_URL: dynamicUrl }))
                .then((action) => {
                    const updatedOpportunityData = action.payload;
                    if (updatedOpportunityData) {
                        setOpportunity(updatedOpportunityData.data);
                        // console.log(updatedOpportunityData.data);
                    } else {
                        setOpportunity([]);
                    }
                    // console.log('l-58', Opportunity.name);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
        const fetchState = () => {
            const dynamicUrl = process.env.REACT_APP_STATE_API_URL;
            const payload = { country_id: 101 }
            dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                .then((action) => {
                    const updatedStateData = action.payload;
                    if (updatedStateData) {
                        setState(updatedStateData.data);
                    } else {
                        setState([]);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }


        fetchDesignation();
        fetchState();
    }, [dispatch]);

    // fetch select2 city-state
    useEffect(() => {
        if (query.length > 3) { // Check if query has some value
            // console.log('165', query);
            const dynamicUrl = process.env.REACT_APP_RELOCATE_LOCATION_API_URL;
            const payload = { search: query }
            dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                .then((action) => {
                    const updatedSelect2 = action.payload;
                    if (updatedSelect2) {
                        setDataSelect2(updatedSelect2.data)

                    } else {
                        // setState([]);
                    }
                })
                .catch((error) => {
                    // console.error('Error fetching data:', error);
                });
        }
    }, [query]);


    //fetch city data
    useEffect(() => {
        const fetchCity = () => {
            const dynamicUrl = process.env.REACT_APP_CITY_API_URL;
            const payload = { country_id: 101, state_id: stateid }
            dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
                .then((action) => {
                    const updatedCityData = action.payload;
                    if (updatedCityData) {
                        setCity(updatedCityData.data);

                    } else {
                        setCity([]);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
        if (stateid) {

            fetchCity();
        }
    }, [stateid]);

    function senddata(e) {
        e.preventDefault();
        // console.log('l-140', userProfileData);

        let formData = new FormData();
        for (const key in userProfileData) {

            formData.append(key, userProfileData[key]);
            // console.log('l-143', key, userProfileData[key], formData);
        }
        for (var key of formData.entries()) {
            // console.log('l-148', key[0] + ', ' + key[1]);
        }
        // console.log('l-143', formData);
        const url = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOAD_CV_API_URL
        const response = fetch(url, {
            method: 'POST',
            body: formData,

        });
        const isSuccessful = response.ok;
        // console.log('l-177', isSuccessful);
        if (!response.ok) {

            // console.log('l-165', response);
        }
        const result = response;
        if (response && response.status != false) {

            // setUserProfileData({
            //     name: '',
            //     email: '',
            //     phone: '',
            //     address: '',
            //     city_id: '',
            //     state_id: '',
            //     country_id: 101,
            //     qualification: '',
            //     designation: '',
            //     total_experience: '',
            //     notice_period: '0',
            //     gender: '',
            //     marital_status: '',
            //     current_ctc: '',
            //     expected_ctc: '',
            //     current_cv: null,
            //     ready_to_relocate: '-1'
            // })
            if (inputFile.current) {
                inputFile.current.value = "";
                inputFile.current.type = "text";
                inputFile.current.type = "file";
            }
            // console.log('l-165', result, userProfileData);
            // setProfile('');
            withReactContent(Swal).fire({
                title: <i>CV Uploaded</i>,
            });
            // console.log('l-184', userProfileData);
        }
    }
    const webpageSchema = {
        "@context": "https://schema.org",
        "@graph": [{
            "@type": "WebPage",
            "@id": "http://localhost:3000/upload-cv",
            "url": "http://localhost:3000/upload-cv",
            "name": "Upload Your CV",
            "isPartOf": { "@id": "http://localhost:3000/" },
            "primaryImageOfPage": { "@id": "http://localhost:3000/#primaryimage" },
            "image": { "@id": "http://localhost:3000/#primaryimage" },
            "datePublished": "2024-04-13 04:46:36",
            "dateModified": "2024-02-13 04:46:36",
            "inLanguage": "en-US",
            "potentialAction": [
                {
                    "@type": "ReadAction",
                    "target": ["http://localhost:3000/"]
                }
            ]
        }]
    };
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (!e.target.checked) {
            setUserProfileData({
                ...userProfileData,
                relocate_city: '-1' // Reset relocate_city when checkbox is unchecked
            });
        }
    };

    return (
        <div>
            <MetaTag title="Upload Cv" name="description" content="Upload Your Cv To Find A Perfect Job For You" />

            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(webpageSchema),
                }}
            />

            <Company_Header title="Upload Your CV" description="Apply Here by Uploading Your CV" contactus_header="contactus_header" />


            <div className="tf-container">
                <section className="mt-5  tf-slider sl7 over-flow-hidden   opportunityType">
                    <h2 className="h3 text-center">Apply Here by Uploading Your CV</h2>

                    <form onSubmit={senddata} method="post" action={process.env.REACT_APP_ROUTES_HOME}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Full Name <span className="tx-danger">*</span></label>
                                    <input
                                        type="text"
                                        id="fname"
                                        name="name"
                                        placeholder="Enter Full Name"
                                        value={userProfileData.name}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                name: e.target.value
                                            });
                                        }}
                                        required
                                    />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Email <span className="tx-danger">*</span></label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        value={userProfileData.email}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                email: e.target.value
                                            });
                                        }}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Phone <span className="tx-danger">*</span></label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        placeholder="Enter Phone"
                                        value={userProfileData.phone}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                phone: e.target.value
                                            });
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Location <span className="tx-danger">*</span></label>
                                    <input
                                        type="text"
                                        id="location"
                                        name="address"
                                        placeholder="Enter Location"
                                        value={userProfileData.address}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                address: e.target.value
                                            });
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="form-group">
                                    <label for="name">State</label>
                                    {/* <select name="state_id" id="state" className="react-dropdown select-location"
                                        value={userProfileData.state_id}
                                        onChange={(e) => {
                                            setStateid(e.target.value);
                                            setUserProfileData({
                                                ...userProfileData,
                                                state_id: e.target.value
                                            });
                                        }}
                                    >
                                        {(userProfileData.state_id) ? <option value={userProfileData.state_id} selected>{(userProfileData.state_id == state.id && state.name)}</option> : <option value="">Select State</option>}
                                        {
                                            state.map((items) =>
                                                <option key={items.id}
                                                    value={items.id}
                                                    className="react-dropdown select-location"

                                                >
                                                    {items.name}
                                                </option>
                                            )

                                        }
                                    </select> */}
                                    <select name="state_id" required id="state" className="react-dropdown select-location"
                                        value={userProfileData.state_id}
                                        onChange={(e) => {
                                            setStateid(e.target.value);
                                            setUserProfileData({
                                                ...userProfileData,
                                                state_id: e.target.value
                                            });
                                        }}>
                                        <option value="">Select State</option>
                                        {
                                            state.map((items) =>

                                                <option key={items.id}
                                                    value={items.id}
                                                    className="react-dropdown select-location"

                                                >
                                                    {items.name}
                                                </option>
                                            )

                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="form-group">
                                    <label for="name">City</label>
                                    {/* <select name="city_id" id="city" className="react-dropdown select-location"
                                        value={userProfileData.city_id}

                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                city_id: e.target.value
                                            });
                                        }}
                                    >

                                        {(!userProfileData.city_id) ? <option value="">First Select State</option> : <option value={userProfileData.city_id} selected>{userProfileData.city}</option>}

                                        {
                                            (stateid && city) ?

                                                city.map((items) =>
                                                    <option key={items.id}
                                                        value={items.id}
                                                        className="react-dropdown select-location"

                                                    >
                                                        {items.name}
                                                    </option>
                                                ) :
                                                <option value={userProfileData.city_id} selected>{userProfileData.city}</option>
                                        }

                                    </select> */}
                                    <select name="city_id" id="city" className="react-dropdown select-location"
                                        value={userProfileData.city_id}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                city_id: e.target.value
                                            });
                                        }}
                                    >
                                        {(stateid > 0) ? <option value="">Select City</option> : <option value="">First Select State</option>}
                                        {
                                            city && city.map((items) =>
                                                <option key={items.id}
                                                    value={items.id}
                                                    className="react-dropdown select-location"

                                                >
                                                    {items.name}
                                                </option>
                                            )

                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="name">Qualification</label>
                                    <input
                                        type="text"
                                        id="qualification"
                                        name="qualification"
                                        placeholder="Enter Your Qualification"
                                        value={userProfileData.qualification}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                qualification: e.target.value
                                            });
                                        }}
                                        required
                                    />

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="name">Designation</label>


                                    <select name="designation" id="designation" className="react-dropdown select-location"
                                        value={userProfileData.designation}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                designation: e.target.value
                                            });
                                        }}
                                    >
                                        {(Opportunity.designation) ? <option value="">Select Designation</option> : <option value="">Select Designation</option>}
                                        {
                                            Opportunity && Opportunity.map((items) =>
                                                <option key={items.slug}
                                                    value={items.slug}
                                                    className="react-dropdown select-location"

                                                >
                                                    {items.name}
                                                </option>
                                            )

                                        }
                                    </select>

                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Experience  <span className="tx-danger">*</span></label>
                                    <select name="total_experience" id="experience"
                                        value={userProfileData.total_experience}

                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                total_experience: e.target.value
                                            });
                                        }}
                                    >
                                        {userProfileData.total_experience ?  <option value={userProfileData.total_experience}>{userProfileData.total_experience + 'years'}</option>
                                    :  <option value="">---- Select Any Option ----</option>
                                    }
                                       
                                       
                                        {
                                            experience.map((experience) => <option key={experience} value={experience}>{(experience > 0) ? experience + '+years' : 'Fresher'}</option>)
                                        }

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Current Salary (CTC Per Month)<span className="tx-danger"></span></label>
                                    <input
                                        type="number"
                                        min="0"
                                        id="currentSalary"
                                        name="current_ctc"
                                        value={userProfileData.current_ctc}
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                current_ctc: e.target.value
                                            });
                                        }}
                                        placeholder="Enter Current Salary"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Expected Salary (CTC Per Month)<span className="tx-danger"></span></label>
                                    <input
                                        min="0"
                                        type="number"
                                        id="expectedSalary"
                                        name="expected_ctc"
                                        value={userProfileData.expected_ctc}
                                        placeholder="Enter Expected Salary"
                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                expected_ctc: e.target.value
                                            });
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Are You On Notice Period ? <span className="tx-danger">*</span></label>
                                    <select name="on_notice_period" id="on_notice_period"
                                        value={userProfileData.on_notice_period}

                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                on_notice_period: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">---- Select Any Option ----</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Notice Period  <span className="tx-danger">*</span></label>
                                        
                                    <select name="notice_period" id="notice_period"
                                        value={userProfileData.notice_period}

                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                notice_period: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="0">Immediately Joiner</option>

                                        {
                                            noticePeriod.map((notice_period) => <option key={notice_period} value={notice_period}>{notice_period + '+days'}</option>)
                                        }

                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Gender <span className="tx-danger">*</span></label>
                                    <select name="gender" id="gender"
                                        value={userProfileData.gender}

                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                gender: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">---- Select Any Option ----</option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Marital Status <span className="tx-danger">*</span></label>
                                    <select name="marital_status" id="marital_status"
                                        value={userProfileData.marital_status}

                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                marital_status: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">---- Select Any Option ----</option>
                                        <option value="married">Married</option>
                                        <option value="unmarried">Unmarried</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="title">
                                        Ready To Relocate<span className="tx-danger">*</span></label>
                                    <select name="ready_to_relocate" id="ready_to_relocate"
                                        value={userProfileData.ready_to_relocate}

                                        onChange={(e) => {
                                            setUserProfileData({
                                                ...userProfileData,
                                                ready_to_relocate: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">---- Select Any Option ----</option>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>

                            {
                                userProfileData.ready_to_relocate === '1' &&
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="d-flex">
                                            <label htmlFor="title">Relocate City<span className="tx-danger">*</span></label>
                                            <div className="relocate-check-box">
                                                <input

                                                    type="checkbox"
                                                    value="-1"
                                                    id="terms"
                                                    checked={isChecked}
                                                    name="relocate_city"
                                                    onChange={handleCheckboxChange}
                                                  
                                                />
                                                <span>Any Where</span>

                                            </div>
                                        </div>
                                        {!isChecked && (
                                            <Select
                                                isMulti
                                                options={dataSelect2.map(item => ({
                                                    value: item.id,
                                                    label: `${item.name}, ${item.state_name}, ${item.country_name}`
                                                }))}
                                                onChange={selectedOptions => {
                                                    setUserProfileData({
                                                        ...userProfileData,
                                                        relocate_city: selectedOptions.map(option => option.value)
                                                    });
                                                }}
                                                onInputChange={inputValue => {
                                                    // console.log('Input Value:', inputValue);
                                                    setQuery(inputValue);
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            }

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="title">
                                        Upload CV <span class="tx-danger">* (Note: Upload file with extensions  .pdf only. Upload upto 2MB only.)</span></label>
                                    <input
                                        type="file"
                                        name="current_cv"
                                        ref={inputFile}
                                        onChange={(e) => {

                                            setUserProfileData({
                                                ...userProfileData,
                                                current_cv: e.target.files[0]
                                            });

                                        }}
                                        style={{ border: "1px solid #e5e5e5", height: "50px", padding: "12px 0px", width: "100%" }}
                                        required
                                    />
                                </div>
                            </div>

                            <div>
                                <button type="submit" className="loginButton upload-cv-button">Send</button>
                            </div>
                        </div>
                    </form>
                </section >
            </div>
        </div>
    )
}

export default Upload_CV