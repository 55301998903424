import React from "react";
import { BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/layout";
import { useEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import { useState } from "react";
import Preloader from "./components/preloader";
import Home from "./main_module/home";
import AllCompany from "./components/main/company";
import AllOpportunity from "./components/main/opportunity";
import Login from "./components/main/login";
import { Provider } from "react-redux";
import store from "./components/store";
import CompanyDetails from "./components/main/company_details";
import Findjobs from "./main_module/find_jobs";
import Find_Company from "./main_module/find_company";
import Blog from "./main_module/blog";
import Blog_Details from "../src/components/main/blog_list/blog_details";
import ContactUS from "../src/components/main/contact_us";
import Register from "../src/components/main/register/index";
import AboutUs from "./main_module/about";
import Term from "./components/main/terms_and_condition";
import Privacy_policy from "./components/main/privacy_policy";
import Page_not_found from "./components/main/page_not_found";
import Reset_Password from "./components/main/reset_password";
import Profile from "./components/main/profile";
import PrivateRoutes from "./PrivateRoutes";
import Find_work from "./main_module/find_work";
import Change_Password from "../src/components/main/change_password";
import Find_talent from "./main_module/find_talent";
import Find_University from "./main_module/find_university";
import University_list from "../src/components/main/university_list";
import Apply_form from "./components/main/apply_form";
import University_details from "./components/main/university_details"
import LinkedIn from "./components/main/login/linkedIn";
import Job_details from "./components/main/featured_jobs/featured-jobs-details"
import Dashboard from './components/main/dashboard'
import Upload_CV from "./components/main/upload_cv";
import Forgot_password from "./components/main/forgot_password"
import Cookie_policy from "./components/main/cookie_policy"
import CopyRight from "./components/main/copyright"
import Disclaimer from "./components/main/disclaimer"
import Commingsoon from "./components/main/comming_soon";
import DragDrop from "./components/main/drag&drop";
import BlogDetails from "./components/main/blog_list/blog_details/index"
import Complaints from "./components/main/complaints_component";
import Besafe from "./components/main/beSafe";
import BewareOfFraudsters from "./components/main/BewareOfFraudsters"
import SecurityFraud from "./components/main/SecurityFraud"
import TermsOfUse from "./components/main/TermsOfUse"


const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  let { filter } = useParams();
  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: "Structured data for you",
    description: "This is an article that demonstrates structured data.",
    image: "https://upload.wikimedia.org/wikipedia/commons/4/40/JSON-LD.svg",
    datePublished: new Date("2021-09-04T09:25:01.340Z").toISOString(),
    author: {
      "@type": "Person",
      name: "John Reilly",
      url: "https://twitter.com/johnny_reilly",
    },
  };

  const routes = [
    {
      path: process.env.REACT_APP_ROUTES_HOME,
      element: <Home />,
    },
    {
      path: process.env.REACT_APP_ROUTES_COMPLAINTS,
      element: <Complaints />,
    },
    {
      path: process.env.REACT_APP_ROUTES_BESAFE,
      element: <Besafe />,
    },
    {
      path: process.env.REACT_APP_ROUTES_BEAWARE,
      element: <BewareOfFraudsters />,
    },
    {
      path: process.env.REACT_APP_ROUTES_SECURITY,
      element: <SecurityFraud />,
    },
    {
      path: process.env.REACT_APP_ROUTES_TERMSOFUSE,
      element: <TermsOfUse />,
    },
    {
      path: "/auth/linkedin/callback/",
      element: <LinkedIn />,
    },
    {
      path: process.env.REACT_APP_ROUTES_COMPANY_TYPE,
      element: <AllCompany />,
    },
    {
      path: process.env.REACT_APP_ROUTES_COMPANY_DETAILS,
      element: <CompanyDetails />,
    },
    {
      path: process.env.REACT_APP_ROUTES_OPPORTUNITY_TYPE,
      element: <AllOpportunity />,
    },
    {

      path: process.env.REACT_APP_ROUTES_OPPORTUNITY_DETAILS,
      element: <Findjobs />,
    },
    {
      path: process.env.REACT_APP_ROUTES_DETAILS_OF_JOBS,
      element: <Job_details />,
    },
    {
      path:process.env.REACT_APP_ROUTES_BLOG_DETAILS,
      element: <BlogDetails />,
    },
    {
      path:process.env.REACT_APP_ROUTES_FIND_JOBS_PARAMETER,
      element: <Findjobs />,
    },
    {
      path: process.env.REACT_APP_ROUTES_FIND_COMPANY,
      element: <Find_Company />,
    },
    {
      path: process.env.REACT_APP_ROUTES_FIND_WORK,
      element: <Find_work />,
    },
    {
      path: process.env.REACT_APP_ROUTES_FIND_UNIVERSITY,
      element: <Find_University />,
    },
    {
      path: process.env.REACT_APP_ROUTES_BLOG,
      element: <Blog />,
    },
    {
      path: process.env.REACT_APP_ROUTES_BLOG_DETAILS,
      element: <Blog_Details />,
    },
    {
      path: process.env.REACT_APP_ROUTES_CONTACT_US,
      element: <ContactUS />,
    },
    {
      path: process.env.REACT_APP_ROUTES_ABOUT_US,
      element: <AboutUs />,
    },
    {
      path: process.env.REACT_APP_ROUTES_TERMS_AND_SERVICES,
      element: <Term />,
    },
    {
      path: process.env.REACT_APP_ROUTES_PRIVACY_POLICY,
      element: <Privacy_policy />,
    },
    {
      path: process.env.REACT_APP_ROUTES_COOKIE_POLICY,
      element: <Cookie_policy />,
    },
    {
      path: process.env.REACT_APP_ROUTES_COPYRIGHT,
      element: <CopyRight />,
    },
    {
      path: process.env.REACT_APP_ROUTES_DISCLAIMER,
      element: <Disclaimer />,
    },
    {
      path: process.env.REACT_APP_ROUTES_LOGIN,
      element: <Login />,
    },
    {
      path: process.env.REACT_APP_ROUTES_RESET_PASSWORD,
      element: <Reset_Password />,
    },
    {
      path: process.env.REACT_APP_ROUTES_REGISTER,
      element: <Register />,
    },
    {
      path: process.env.REACT_APP_ROUTES_FIND_TALENT,
      element: <Find_talent />,
    },
    {
      path: process.env.REACT_APP_ROUTES_UNIVERSITY,
      element: <University_list />,
    },
    {
      path: process.env.REACT_APP_ROUTES_UNIVERSITY_DETAILS,
      element: <University_details />,
    },
    {
      path: process.env.REACT_APP_ROUTES_UPLOADCV,
      element: <DragDrop />,
    },
    {
      path: process.env.REACT_APP_ROUTES_FORGOT_PASSWORD,
      element: <Forgot_password />,
    },
  ];

  const privateRoute = [
    {
      path: process.env.REACT_APP_ROUTES_PROFILE,
      element: <Profile />,
    },
    {
      path: process.env.REACT_APP_ROUTES_CHANGE_PASSWORD,
      element: <Change_Password />,
    },
    {
      path: process.env.REACT_APP_ROUTES_APPLY_FORM,
      element: <Apply_form />,
    },
    {
      path: process.env.REACT_APP_ROUTES_DASHBOARD,
      element: <Dashboard />,
    },
  ];

  const routeComponents = routes.map(({ element, path }) => (
    <Route key={path} exact path={path} element={element}></Route>
  ));

  const privateRouteComponent = privateRoute.map(({ element, path }) => (
    <Route key={path} exact path={path} element={element}></Route>
  ));
  const [commingsoon, setCommingSoon] = useState(true);

  return (
    <>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(articleStructuredData),
        }}
      /> */}

      {!loading ? (
        <Router basename={process.env.PUBLIC_URL} >
          {!commingsoon &&
            <Routes>

              <Route element={<Commingsoon />} path="/" />
            </Routes>
          }
          {commingsoon &&
            <Layout>
              <ScrollToTop />

              <Provider store={store}>
                <Routes>
                  {routeComponents}

                  <Route element={<PrivateRoutes />}>
                    {privateRouteComponent}
                  </Route>
                  {/* <Route element={<Page_not_found />} path="*" /> */}

                </Routes>
              </Provider>
            </Layout>
          }
        </Router>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AppWrapper;
