import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendUserData } from '../../../slice/postSlice';
import { useNavigate } from 'react-router-dom';


const CustomGoogleSignInButton = ({ onSuccess, onFailure }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [responceToken, setResponceToken] = useState();
  const [oAuthAccessToken, setoAuthAccessToken] = useState();
  const [userData, setUserData] = useState(null);
  const [candidateLoginWithGoogleData, setcandidateLoginWithGoogleData] = useState({
    name: '',
    email: '',
    register_by: '',
    oauth_id: '',
    oauth_type: '',

  });

  const login = useGoogleLogin({
    onSuccess: codeResponse => setResponceToken(codeResponse.code),
    flow: 'auth-code',
  });
  useEffect(() => {
    if (responceToken) {
      try {
        const fetchData = async () => {
          const response = await fetch('https://www.googleapis.com/oauth2/v4/token', {
            method: 'POST',
            body: JSON.stringify({
              code: responceToken,
              client_id: '1040516153534-m8lc4uscqg1u3vct8val8ttu8ae96jff.apps.googleusercontent.com',
              client_secret: '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',
              redirect_uri: 'http://localhost:3000',
              grant_type: 'authorization_code'
            }),
          });
          const responseData = await response.json();

          // console.log('l-41', responseData);
          setoAuthAccessToken(responseData.access_token);
          // Dispatch your action or do further processing here
        };

        fetchData();
      } catch (error) {
        // console.log(error);
      }
    }
  }, [responceToken]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (oAuthAccessToken) {
        try {
          const response = await fetch('https://people.googleapis.com/v1/people/me?personFields=emailAddresses,names,photos', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${oAuthAccessToken}`
            },
          });

          const responseData = await response.json();
          // console.log('l-65', responseData);
          setUserData(responseData);
        } catch (error) {
          // console.log(error);
        }
      }
    };

    fetchUserData();
  }, [oAuthAccessToken]);


  const sendDataToApi = () => {
    const social_login_url = process.env.REACT_APP_EASY_SOCIAL_LOGIN_API_URL;
    try {
      if (candidateLoginWithGoogleData) {
        dispatch(sendUserData({ data: candidateLoginWithGoogleData, pageurl: social_login_url }))
          .then((action) => {
            // console.log('l-82',action);
            const result = action.payload.data;
            // console.log('L-84',action.payload);
            localStorage.setItem('items', JSON.stringify(result.access_token));
            const items = localStorage.getItem('items');

            if (items) {
              // console.log('Items:', items);
              // console.log('History Length:', window.history.length);
              if (window.history.length > 2) {
                // console.log('Inside if condition');
                window.history.back();
              } else {
                // console.log('Inside else condition');
                navigate(process.env.REACT_APP_ROUTES_HOME);
              }
            } else {
              // console.log('Items is not defined or falsy');
            }
          })
          .catch((error) => {
            // console.log(error);
            // withReactContent(Swal).fire({
            //   title: "Somethings wents Wrong!!!",
            //   text: "Try Again"

            // });
          });
      } else {
        alert('username and password in correct');

      }
    } catch (error) {
      console.error('Error sending data to API:', error.message);
    }
  }

  useEffect(() => {
    setcandidateLoginWithGoogleData(
      {
        name: userData?.names[0].displayName,
        email: userData?.emailAddresses[0].value,
        register_by: 'candidate',
        oauth_id: userData?.etag,
        oauth_type: 'google'
      }
    )
  }, [userData])

  useEffect(() => {
    if(candidateLoginWithGoogleData.name || candidateLoginWithGoogleData.email || candidateLoginWithGoogleData.oauth_id){
      sendDataToApi()
    }
  }, [candidateLoginWithGoogleData])

  return (
    <>
      <button onClick={login} className='border-0 p-3'>
      <svg xmlns="http://www.w3.org/2000/svg" width="29.32px" height="30px" viewBox="0 0 256 262"><rect width="256" height="262" fill="none"/><path fill="#4285f4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"/><path fill="#34a853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"/><path fill="#fbbc05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"/><path fill="#eb4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"/></svg>
      </button>
    </>
  );
};

export default CustomGoogleSignInButton;
