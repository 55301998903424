import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";

WidgetCounter.propTypes = {};

function WidgetCounter(props) {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    const onVWEnter = () => {
        setViewPortEntered(true);
    };

    const { className } = props;
    return (
        <div className={`wd-review-job thumb2 widget-counter`}>
            <div className="thumb">
                <img
                    src={require("../../../assets/images/thumb-review.webp")}
                    alt="images"
                />
            </div>
            <div class="user-tag-tag1 tag1">
                <img src={require("../../../assets/images/user1.webp")} alt="images" />
            </div>
            <div className="tes-box ani5 home-job-info">
                <div className="client-box">
                    <div className="avt">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82861 17.9669L10.8172 11.6126L17.1715 9.62402L15.1829 15.9783L8.82861 17.9669Z" stroke="#14A077" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <circle cx="13" cy="13.7949" r="12" stroke="#14A077" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></circle>
                        </svg>
                        <div className="badge"></div>
                    </div>
                    <div className="content">
                        <Waypoint onEnter={onVWEnter}>
                            <p>
                                {viewPortEntered && (
                                    <CountUp
                                        className="number h3"
                                        end={198}
                                        suffix="+"
                                        duration={3}
                                    />
                                )}
                            </p>
                        </Waypoint>
                        <div className="des">Countries</div>
                    </div>
                </div>
            </div>
            <div className="user-tag-tag2 tag2">
                <img src={require("../../../assets/images/user2.webp")} alt="images" />
            </div>
            <div className="tes-box ani5 home-job-info1">
                <div className="client-box">
                    <div className="avt">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M22.6673 10.4618C22.3412 8.11547 21.2527 5.94139 19.5695 4.27451C17.8863 2.60762 15.7017 1.54041 13.3522 1.23725C11.0028 0.934097 8.61881 1.41182 6.56759 2.59684M1.33398 2.46185V7.79518H6.66732" stroke="#14A077" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M1.33398 13.1289C1.66006 15.4753 2.74856 17.6494 4.4318 19.3162C6.11504 20.9831 8.29964 22.0503 10.6491 22.3535C12.9985 22.6567 15.3825 22.1789 17.4337 20.9939M22.6673 21.1289V15.7956H17.334" stroke="#14A077" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        <div className="badge"></div>
                    </div>
                    <div className="content">
                        <Waypoint onEnter={onVWEnter}>
                            <p>
                                {viewPortEntered && (
                                    <CountUp
                                        className="number h3"
                                        end={350}
                                        suffix="k"
                                        duration={3}
                                    />
                                )}
                            </p>
                        </Waypoint>
                        <div className="des">Job Search Success</div>
                    </div>
                </div>
            </div>
            <div className="user-tag-tag4 tag4 active">
                  <img src={require("../../../assets/images/user4.webp")} alt="images" />
                </div>
            <div className="user-tag-tag3 tag3">
                <img src={require("../../../assets/images/user3.webp")} alt="images" />
            </div>
            <div className="tes-box ani5 home-job-info2">
                <div className="client-box">
                    <div className="avt">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.52382 15.5352C5.03915 15.5352 1.20898 16.213 1.20898 18.929C1.20898 21.6438 5.01465 22.3473 9.52382 22.3473C14.0085 22.3473 17.8387 21.6683 17.8387 18.9535C17.8387 16.2387 14.033 15.5352 9.52382 15.5352Z" stroke="#14A077" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.52465 11.6616C12.4833 11.6616 14.854 9.28976 14.854 6.33226C14.854 3.3736 12.4833 1.00293 9.52465 1.00293C6.56715 1.00293 4.19531 3.3736 4.19531 6.33226C4.19531 9.28976 6.56715 11.6616 9.52465 11.6616Z" stroke="#14A077" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M20.4056 7.9082V12.5865" stroke="#3772FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M22.7912 10.2474H18.0195" stroke="#3772FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                        <div className="badge"></div>
                    </div>
                    <div className="content">
                        <Waypoint onEnter={onVWEnter}>
                            <p>
                                {viewPortEntered && (
                                    <CountUp
                                        className="number h3"
                                        end={1}
                                        suffix="million+"
                                        duration={3}
                                    />
                                )}
                            </p>
                        </Waypoint>
                        <div className="des">Candidates</div>
                    </div>
                </div>
            </div>
            <div className="icon2 ani1">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                    <g clipPath="url(#clip0_5631_259134)">
                        <circle cx="19.9964" cy="20.7069" r="15.0028" transform="rotate(-23.3151 19.9964 20.7069)" fill="white"></circle>
                        <path fillRule="evenodd" clipRule="evenodd" d="M25.9343 34.4846C18.3247 37.7642 9.49824 34.2544 6.21866 26.6448C2.93909 19.0352 6.44886 10.2088 14.0584 6.92922C21.668 3.64965 30.4945 7.15942 33.774 14.769C37.0536 22.3786 33.5438 31.205 25.9343 34.4846ZM26.764 21.0106C25.8277 18.838 23.9418 18.1958 20.4373 18.7995L18.7895 14.9761C19.858 14.7546 20.9797 14.7725 22.1847 15.0404L22.5399 12.6451C21.0234 12.2976 19.4422 12.3538 17.9542 12.8081L17.4339 11.6008L15.6169 12.3838L16.1198 13.5506C13.5958 14.8774 12.4627 17.1311 13.399 19.3037C14.3788 21.577 16.2756 22.0715 19.6544 21.5221L21.3541 25.4659C19.9093 25.8497 18.522 25.7318 16.9321 25.3194L16.3847 27.7261C18.2898 28.2108 20.2913 28.1653 22.1724 27.5944L23.0393 29.606L24.8563 28.8229L23.9979 26.8312C26.5547 25.4668 27.7178 23.2236 26.764 21.0106ZM18.6044 19.0881C16.7974 19.3186 16.1671 18.9459 15.8027 18.1003C15.4215 17.2159 15.8068 16.2147 17.0792 15.5469L18.6044 19.0881ZM24.3601 22.2131C24.7764 23.1792 24.3108 24.1671 23.0385 24.8349L21.469 21.1933C23.2979 20.9544 23.97 21.308 24.3604 22.2139L24.3601 22.2131Z" fill="#1E68F6"></path>
                    </g>
                    <defs>
                        <clipPath id="clip0_5631_259134">
                            <rect width="30.0056" height="30.0056" fill="white" transform="translate(0.280762 12.8671) rotate(-23.3151)"></rect>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div class="group-tab menu-tab">

                {/*
                
                */}

            </div>
            {/* <div className="icon1 ani3">
                <img
                    src={require("../../../assets/images/icon1.webp")}
                    alt="images"
                />
            </div>
            <div className="icon3 ani6">
                <img
                    src={require("../../../assets/images/icon3.webp")}
                    alt="images"
                />
            </div> */}
        </div>
    );
}

export default WidgetCounter;
