import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Upload_CV from '../upload_cv';
import Register from '../register';
import Header from '../company/company-header';
import Featured_jobs from '../featured_jobs';
import Swal from 'sweetalert2';

export default function Demo({ onDataReceived }) {
  const [data, setData] = useState(null);
  const [displayForm, setDisplayForm] = useState(false); // State to manage form display
  const url = window.location.href;
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: 'application/pdf',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      handleFileUpload(acceptedFiles[0]);
    }
  });

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}api/get-cv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setData(response.data);
      setDisplayForm(true); // Set displayForm to true upon successful data fetch
      if (url === process.env.REACT_APP_LOCAL_URL + process.env.REACT_APP_ROUTES_FIND_JOBS) {
        onDataReceived(response.data);
      }
    } catch (error) {
      setData(null);
      setDisplayForm(true); // Set displayForm to false upon error
      if (url === process.env.REACT_APP_LOCAL_URL + process.env.REACT_APP_ROUTES_FIND_JOBS) {
        onDataReceived(null);
      }
    }
  };

  return (
    <>
      {displayForm ? (
        // Display form based on conditions
        url === process.env.REACT_APP_LOCAL_URL + process.env.REACT_APP_ROUTES_REGISTER ? (
          <Register details={data} />
        ) : url === process.env.REACT_APP_LOCAL_URL + process.env.REACT_APP_ROUTES_FIND_JOBS ? (
          <Featured_jobs details={data} />
        ) : (
          <Upload_CV details={data} />
        )
      ) : (
        // Display drag and drop area if form is not displayed
        <div className="drag-drop-container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input className="input-zone" {...getInputProps()} />
            <div className="text-center">
              {isDragActive ? (
                <p className="dropzone-content">
                  Release to drop the files here
                </p>
              ) : (
                <p className="dropzone-content">
                  Drag & Drop Your Resume in PDF & Image Format
                </p>
              )}
              <button type="button" onClick={open} className="drag-area-button">
                Click to select files
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
