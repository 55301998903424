import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faClock } from '@fortawesome/free-solid-svg-icons'
import MetaTag from '../meta-tag';
import Breadcrumb from '../../google_schema/breadcrumb'
import Company_google_schema from '../../google_schema/company_google_schema'
import { useDispatch } from 'react-redux';
import { getDataFromApi } from "../../../slice/getDataSlice";

function Blog_list() {
  const [blogData, setBlogData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const dynamicUrl = process.env.REACT_APP_BLOG_API_URL;
    const payload = {};
    try {
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
        .then((action) => {
          const updatedBlogData = action.payload;


          if (updatedBlogData) {
            setBlogData(prevBlog => [...prevBlog, ...updatedBlogData.data.items.data]);

          } else {
            setBlogData([]);

          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    catch (error) {
      console.log('error');
    }
  }, [dispatch])

  function formatDate(formattedDateString) {
    // Split the date and time parts
    const [dateString, timeString] = formattedDateString.split('T');

    // Extract year, month, and day
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);

    // Convert month number to month name (optional)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[parseInt(month) - 1];

    // Combine year, month name, and day
    const formattedDateStr = `${year}-${monthName}-${dateString.substring(8, 10)}`;

    return formattedDateStr;
  }

  return (
    <>

      <div className="wrapper">
        <MetaTag title="Blog Details" name="description" content="A collection of insightful articles covering various topics" />

        <Company_google_schema />
      </div>
      <div className='tf-container'>
        <div className='group-col-3'>

          {blogData.map((idx) => (

            <div key={idx.slug} className="widget-blog-1 style-1 cl3 stc">
              {/* <img src={idx.img} alt="image" className="img-blog" /> */}
              <div className="content">
                <span className="sub-title">{idx.blog_category}</span>
                <h3 className="main-title">
                  <Link to={`${process.env.REACT_APP_ROUTES_BLOG}/${idx.slug}`} className='text-dark' state={idx.slug}>{idx.blog_name}</Link>
                </h3>
                <ul className="meta">
                  <li className="author">
                    <span>by </span>&nbsp;
                    {idx.author_name}
                  </li>
                  <li className="time">
                    <span className="icon-calendar"></span> {formatDate(idx.created_at)}
                  </li>
                </ul>
                <p>{idx.text}</p>
              </div>
            </div>

          ))}

        </div>
      </div>
    </>
  )
}

export default Blog_list