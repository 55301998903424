import React,{useEffect, useState} from 'react'
import banner from '../../../assets/images/singlejob.jpg'
import { useDispatch } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import Header from '../company/company-header';

function Copyright() {
  const dispatch = useDispatch();
  const [copyright_data,setCopyright_data] = useState([])
  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_WEBSITE_STATIC_PAGE_API_URL+'/copyright';
      const payload ={}
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload : payload}))
        .then((action) => {
          const updatedCopyRightData = action.payload;

          if (updatedCopyRightData) {
            setCopyright_data(updatedCopyRightData.data);
          } else {
            setCopyright_data([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
      <Header  title="Copyright" description="" contactus_header="contactus_header"/>
    <section className="term-section">
        <div className='container mt-5 static_page'>
        <div dangerouslySetInnerHTML={{ __html: copyright_data.value}} />

        </div>
       

    </section>
    </>
  )
}

export default Copyright