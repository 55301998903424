import React from 'react'
import img1 from "../../../../assets/images/work-icon-1.jpg";
import img2 from "../../../../assets/images/work-icon-2.jpg";
import img3 from "../../../../assets/images/work-icon-3.jpg";
import { Link } from 'react-router-dom';

function How_Its_Work() {
  return (
    <section className="wd-iconbox style-3 inner-iconbox-section pt-0 pb-0">
    <div className="tf-container">
      <div className="title-iconbox style-3 stc">
        <h4>How it work?</h4>
        <p>
          Pellentesque quis lectus sagittis, gravida erat id, placerat
          tellus.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="group-col-3">
            <div className="tf-iconbox style-3 cl3 stc2">
              <div className="box-header">
                <div className="img1">
                  <img src={img1} alt="work-icon-1" />
                </div>
              </div>
              <div className="box-content">
                <h6 className="box-title">
                  <Link title='Free resume assessments' to="/Blogsingle_v1">Free resume assessments</Link>
                </h6>
                <p>
                  Aenean porta, eros lacus congue lorem, sit amet mollis
                  magna velit ac erat Morbi accumsan purus nec vehicula
                  ornare. Nam quis tincidunt nulla.
                </p>
                <button>
                  Start Now&nbsp;
                  <span className="icon-arrow-right2" />
                </button>
              </div>
            </div>
            <div className="tf-iconbox style-3 cl3 stc2">
              <div className="box-header">
                <div className="img1">
                  <img src={img2} alt="img" alt="work-icon-2"/>
                </div>
              </div>
              <div className="box-content">
                <h6 className="box-title">
                  <Link title='Job fit scoring' to="/Blogsingle_v1">Job fit scoring</Link>
                </h6>
                <p>
                  Aenean porta, eros lacus congue lorem, sit amet mollis
                  magna velit ac erat Morbi accumsan purus nec vehicula
                  ornare. Nam quis tincidunt nulla.
                </p>
                <button>
                  Start Now&nbsp;
                  <span className="icon-arrow-right2" />
                </button>
              </div>
            </div>
            <div className="tf-iconbox style-3 cl3 stc2">
              <div className="box-header">
                <div className="img1">
                  <img src={img3} alt="work-icon-3"/>
                </div>
              </div>
              <div className="box-content ">
                <h6 className="box-title">
                  <Link to="/Blogsingle_v1" title='Help every step of the way'>
                    Help every step of the way
                  </Link>
                </h6>
                <p>
                  Aenean porta, eros lacus congue lorem, sit amet mollis
                  magna velit ac erat Morbi accumsan purus nec vehicula
                  ornare. Nam quis tincidunt nulla.
                </p>
                <button>
                  Start Now&nbsp;
                  <span className="icon-arrow-right2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default How_Its_Work