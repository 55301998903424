import React from "react";

import { Link, useLocation } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import banner from '../../../../assets/images/blog.jpg'
import blogdetails1 from '../../../../assets/images/blog-detail-02.jpg'
import blogdetails2 from '../../../../assets/images/blog-detail-03.jpg';
import MetaTag from '../../meta-tag';
import Article_google_schema from '../../../google_schema/article_google_schema'
import { useDispatch } from "react-redux";
import { getDataFromApi } from "../../../../slice/getDataSlice";
import DOMPurify from "dompurify";

function Blog_details(props) {

  const [blogDetails, setBlogDetails] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const slug_name = location.state ? location.state : '';
  useEffect(() => {
    const dynamicUrl = process.env.REACT_APP_BLOG_API_URL + '/' + slug_name;
    const payload = { blog_slug: slug_name };
    try {
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
        .then((action) => {
          const updatedBlogData = action.payload;
          console.log('L-27', updatedBlogData);
          if (updatedBlogData) {
            setBlogDetails(updatedBlogData.data);

          } else {
            setBlogDetails([]);

          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    catch (error) {
      console.log('error');
    }
  }, [dispatch])
  return (
    <>
      <div className="wrapper">
        <MetaTag title="Blog Details" name="description" content="A collection of insightful articles covering various topics" />
        <Article_google_schema />

      </div>
      <section className="blog-detail-01 page-title">
        <img src={banner} alt="blog banner"></img>
      </section>
      <section>
        <div className="tf-container ">
          <div className="wrap-blog-detail blog-detail-01 blog-detail-side-bar-content">
            <div className="widget-blog-1 style-1">
              <div className="content">
                {/* <span className="sub-title">{blogDetails?.blog_category}</span> */}
                <h1 className="main-heading">
                  {/* {blogDetails?.blog_name} */}
                </h1>
                <ul className="meta">
                  <li className="author">
                    {/* <span>by</span> {blogDetails?.author_name} */}
                  </li>
                  <li className="time">
                    <span className="icon-calendar"></span> 2 days ago
                  </li>
                </ul>
              </div>
            </div>

            <div dangerouslySetInnerHTML={{__html: blogDetails.blog_content }} />
            {/* {blogDetails && blogDetails.map(blog => (
            ))} */}

            <div className="nav-links stc">
              <div className="post-navigation previous-post">
                <div className="title-post">
                  <Link to="#" rel="prev" title="Previous">
                    Previous
                  </Link>
                </div>
                <p>How to choose the right customer </p>
              </div>
              <div className="post-navigation next-post">
                <div className="title-post text-end">
                  <Link to="#" rel="prev" title="Next">
                    Next
                  </Link>
                </div>
                <p>Starting your traveling blog with Vasco</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog_details;
