import React from 'react'

function Article_google_schema() {
  return (
    <>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context": "http://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
        },
        "headline": "Title of article",
        "description": "A concise description of your blog post content.",
        "datePublished": "2024-02-06",
        "dateModified": "2024-02-06",
        "author": {
          "@type": "Person",
          "name": "Your Name"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Your Blog",
          "logo": {
            "@type": "ImageObject",
            "width": 600,
            "height": 60
          }
        },
      })
    }}>

    </script>
  </>
  
  )
}

export default Article_google_schema