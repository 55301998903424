import React from 'react'

function Company_google_schema() {
  return (
    <>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "image": "company image",
        "url": "company website",
        "sameAs": ["https://example.net/profile/example1234", "https://example.org/example1234"],
        "logo": "https://www.example.com/images/logo.png",
        "name": "Example Corporation",
        "description": "The example corporation is well-known for producing high-quality widgets",
        "email": "contact@example.com",
        "telephone": "+47-99-999-9999",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Rue Improbable 99",
          "addressLocality": "Paris",
          "addressCountry": "FR",
          "addressRegion": "Ile-de-France",
          "postalCode": "75001"
        },
        "vatID": "FR12345678901",
        "iso6523Code": "0199:724500PMK2A2M1SQQ228"
      })
    }}>

    </script>
  </>
  )
}

export default Company_google_schema