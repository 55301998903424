import React from "react";
import { Link, useParams } from "react-router-dom";
import JobsBlock from "./jobs-block ";
import Search from '../search';
import CategorySliderImage from '../../../assets/images/slider2.jpg'
import Company_Header from "../company/company-header";
import { useLocation } from 'react-router-dom';


function Featured_jobs({ title, itemPerPage, filterOpportunityType,relatedJobs,blockHeight }) {
  
  let { slug } = useParams();
  // console.log("filterOpportunityType 14",filterOpportunityType);
  // console.log('l-18', itemPerPage);
  const location = useLocation();
  const url = location.pathname;
  // console.log('L-17',url,location.state);
  sessionStorage.setItem('opportunity_name',location.state)
  const base_url = process.env.REACT_APP_FIND_JOB_SEARCH_DETAILS_URL + sessionStorage.getItem('opportunity_name');
  const job_details_url = window.location.href;
  console.log('l-19', job_details_url, base_url,relatedJobs,relatedJobs != 'yes');

  return (
    <>
      {((job_details_url != base_url) && (relatedJobs != 'yes')) &&

        <Company_Header title="Find Jobs" description="Resume-Library is a true performance-based job board. Enjoy custom hiring products and access to up to 10,000 new resume registrations daily, with no subscriptions or user licences."/>
      }
      <section className="category-opportunity-box">
        <div className="wrap-testimonials style-1 over-flow-hidden tf-tab">
          <div className="tf-container">
            <div className="tf-title style-3 margin">
              <div className="group-title">
                <h2 className="text-center">{title ? title : "Featured Jobs"}</h2>
                <p className="text-center">Find the right career opportunity for you</p>
              </div>
              <div>
                {(job_details_url == base_url ) && (
                  <Link to='/opportunity-type' className='mt-5 tf-button all-company' title="All Categories" >All Categories
                    <span className="icon-arrow-right2"></span>
                  </Link>
                )}
              </div>

            </div>
            <div className="content-tab">
              <div className="row wow fadeInUp animation-tab job-tab-item">
              
                <JobsBlock perPageData={itemPerPage} filterOpportunityType={filterOpportunityType} blockHeight={blockHeight} />
                <div className="col-md-12">
                  <div className="wrap-button">
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
}

export default Featured_jobs;
