import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import Skeleton from './skeleton';
import LazyLoad from 'react-lazyload';

function Awards() {

  const dispatch = useDispatch();
  const [AwardsData, setAwardsData] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_AWARDS_API_URL;
      const payload = {}
      dispatch(getDataFromApi({ API_URL: dynamicUrl , payload:payload }))
        .then((action) => {
          const updatedOpportunityData = action.payload;

          if (updatedOpportunityData) {
            setAwardsData(updatedOpportunityData.data);
          } else {
            setAwardsData([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);
  const swiperRefLocal = useRef()

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop()
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start()
  };
  return (
    <>
      {AwardsData?.length > 0 &&
        <section className='w-100 mt-5'>
          <div className="wd-partner-awards mt-5" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {!loading ?

              <div className="tf-container">
                <h2 className="title-partner">
                  Recruitment Agency Awards and Accreditation & Nominations
                </h2>

                <Swiper
                  modules={[Autoplay]}
                  spaceBetween={68.95}
                  slidesPerView={4}
                  ref={swiperRefLocal}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: true,
                  }}
                  className="partner-type-6"
                  loop={true}
                  speed={2000}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    500: {
                      slidesPerView: 3,
                    },
                    800: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 5,
                    },
                    1600: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  {
                    AwardsData.map((item) => (
                      <SwiperSlide key={item.award_title}>
                        <Link title={item.award_title} to={item.redirect_link} className="logo-partner" target="_blank">
                          <div>
                            <LazyLoad height={200} once>
                              <img src={item.award_image_url} alt="award-image" className='AwardsImage' />
                            </LazyLoad>
                          </div>
                          <div className='awardContainer text-center'>{item.award_title}</div>
                        </Link>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
              :
              <div className='d-flex justify-content-between'>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            }
          </div>

        </section>
      }

    </>

  )

}

export default Awards