import React, { useState } from 'react'
import Search from '../search/index'
import CategorySliderImage from '../../../assets/images/slider2.webp'


function Company_Header({ title, description, contactus_header }) {
  // console.log(window.location.href);
  // console.log(window.location.href, process.env.REACT_APP_COMPANY_URL);

  const currentURL = window.location.href;
  const companyURL =`${process.env.REACT_APP_LOCAL_URL}${process.env.REACT_APP_ROUTES_FIND_COMPANY}`;
  const routesFindJobsURL = `${process.env.REACT_APP_LOCAL_URL}${process.env.REACT_APP_ROUTES_FIND_JOBS}`;
  const localFindWorkURL = `${process.env.REACT_APP_LOCAL_URL}${process.env.REACT_APP_ROUTES_FIND_WORK}`;

  const shouldShowSearch = (
    currentURL === companyURL ||
    currentURL === routesFindJobsURL ||
    currentURL === localFindWorkURL
  );

  return (
    <>
      <section className="tf-slider sl5 category-container">
        <div className="">
          <div className="row">
            <div>
              <img className="category-slider-image" src={CategorySliderImage} alt='slider-image'></img>
              <div className={contactus_header ? contactus_header : 'category-slider-content'}>
                <div className="heading text-center">
                  <h1 className="slider-heading text-white">{title ? title : 'Find the job that fits your life'}</h1>
                  <p className="slider-discription text-white">{description ? description : ''}</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="search-component">
        {shouldShowSearch && <Search />}
      </section>

    </>
  )
}

export default Company_Header