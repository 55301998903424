import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";

Couter.propTypes = {};

function Couter() {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const onVWEnter = () => {
    setViewPortEntered(true);
  };

  const total_company = sessionStorage.getItem('total_company') || '400';
  const total_job = sessionStorage.getItem('total_jobs') || '100';

  const counters = [
    { end: total_job, suffix: "+", description: "Jobs Available", delay: "0s", duration: 3 },
    { end: 177, suffix: "k+", description: "New Jobs This Week!", delay: "0.3s", duration: 1 },
    { end: total_company, suffix: "+", description: "Companies Hiring", delay: "0.4s", duration: 1 },
    { end: 5, suffix: "M+", description: "Candidates", delay: "0.5s", duration: 3 },
  ];

  return (
    <section>
      <div className="bg-pri1 count-spacing">
        <div className="tf-container">
          <div className="row align-item-center">
            {counters.map((counter, index) => (
              <div
                key={index}
                className={`col-lg-3 col-md-6 wow fadeInUp`}
                data-wow-delay={counter.delay}
              >
                <div className="wd-counter style-light widget-counter">
                  <div className="inner wrap-counter">
                    <Waypoint onEnter={onVWEnter}>
                      <h2>
                        {viewPortEntered && (
                          <CountUp
                            className="number"
                            end={counter.end}
                            suffix={counter.suffix}
                            duration={counter.duration}
                          />
                        )}
                      </h2>
                    </Waypoint>
                  </div>
                  <p className="description">{counter.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Couter;
