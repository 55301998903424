import React, { useState } from "react";
import Banner from "../components/main/banner/index";
import Counter from "../components/main/counter/index";
import Opportunity from "../components/main/opportunity";
import Company from "../components/main/company";
import Course from "../components/main/university";
import Testimonials from "../components/main/testimonials";
import TrustedByEmployee from "../components/main/trusted_by_employee";
import Breadcrumb from "../components/google_schema/breadcrumb";
import AwardsComponent from "../components/main/awards"
import useGeoLocation from "../components/main/useGeoLocation";
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import MetaTag from "../components/main/meta-tag";
import HomeJobInfo from "../components/main/home_job_info";
import Steps_of_requirement from "../components/main/steps_of_requirement";

function Home() {
  const dispatch = useDispatch();
  const [userLocation, setUserLocation] = useState([{
    road_name: '',
    city: '',
    state: '',
    country: '',
    pincode: ''
  }]);
  // const location = useGeoLocation();


  // useEffect(() => {
  //   const latitude = location.coordinates.lat;
  //   const longitude = location.coordinates.lng;
  //   // console.log('l-90', latitude, longitude);
  //   // Make a request to a Geocoding API (e.g. Google Maps Geocoding API)

  //   // const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=22.299192906179176&longitude=70.78243224201594`;
  //   let url;
  //   if (latitude != '' && longitude != '') {
  //     url = `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=660fc47c874a9229234011crj3ffa35`
  //   }
  //   fetch(url)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log('l-90', data);
  //       setUserLocation({
  //         road_name: data.address.road,
  //         city: '',
  //         state: data.address.state,
  //         country: data.address.country,
  //         pincode: data.address.postcode
  //       })

  //     })
  //     .catch((error) => console.log(error));


  //     const fetch_city_url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}`
  //     fetch(fetch_city_url)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log('l-91', data);
  //       setUserLocation({
  //         city:data.city
  //       })

  //     })
  //     .catch((error) => console.log(error));
  // },[dispatch])

  // console.log('l-68', userLocation);
  const base_url = process.env.REACT_APP_BASE_URL;
  const home = process.env.REACT_APP_ROUTES_HOME;
  const breadcrumb = [
    {
      home: base_url + home,
    },
  ];
  // console.log('l-44', userLocation);
  return (
    <div>

      <MetaTag title="Spot In Job Consultancy | Best Staffing & Recruitment Agency" name="description" content="Spot In Job Consultancy is the Best Staffing and Recruitment Agency Exclusively Specialized in job placement for full-time jobs in India. " />
      <Breadcrumb breadcrumb={breadcrumb} />
      <Banner />
      <Counter />
      <Opportunity currentItemsHome={10} />
      <Company PerPage={8} title="Companies" className=" over-flow-hidden" />
      <HomeJobInfo />
      <Steps_of_requirement />
      {/* <Course currentItemsHome={5} /> */}
      <Testimonials />
      <AwardsComponent />
      {/* <TrustedByEmployee /> */}
    </div>
  );
}

export default Home;
