import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import banner from '../../../assets/images/singlejob.jpg'
// import { fetchSingleCompanyData } from '../../../slice/singleCompanySlice'; 
import { getDataFromApi } from "../../../slice/getDataSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import MetaTag from "../meta-tag";
import Breadcrumb from "../../google_schema/breadcrumb";
import { sendUserData } from '../../../slice/postSlice';
import Swal from 'sweetalert2'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import JobBlockComponent from '../featured_jobs/index'
import withReactContent from 'sweetalert2-react-content'
import DragDrop from "../drag&drop";
import {
  FacebookShareButton,
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

function CompanyDetails({ params }) {
  const [details, setDetails] = useState(null);
  const [dataFetch, setDataFetch] = useState(0);
  const location = useLocation();
  // console.log('L-35', location.state, sessionStorage.getItem('login_user_id'));

  const [reportReason, setReportReason] = useState({
    userid: sessionStorage.getItem('login_user_id') ? sessionStorage.getItem('login_user_id') : '',
    opportunity_slug: location.state ? location.state : '',
    reason: ""
  });
  const handleDataReceived = (data) => {
    setDetails(data);
  };
  const [showPopup, setShowPopup] = useState(false);

  const [slug_name, setslug] = useState(location.state);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [opportunity, setOpportunity] = useState([]);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [postJobDate, setpostJobDate] = useState('');
  const [openingFor, setOpeningFor] = useState(null);
  const [endJobDate, setEndJobDate] = useState(null);
  const login_token = localStorage.getItem('items');
  const [easyApplyData, setEasyApplyData] = useState({
    opportunity_slug: "",
    name: "",
    email: "",
    phone: ""
  });
  const [detailsOpportunityTypeSlug, setDetailsOpportunityTypeSlug] = useState();
  const slug = sessionStorage.getItem('opportunity_name');
  // console.log('slug', slug);
  useEffect(() => {
    const storedOpportunityName = sessionStorage.getItem('opportunity_name');
    if (storedOpportunityName) {
      setslug(storedOpportunityName);
    }
  });
console.log('L-73',location.state,sessionStorage.getItem('opportunity_name'))

  useEffect(() => {
    console.log('L-76','data');
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_JOBS_DETAILS_API_URL + slug_name;
      const payload = {}
      dispatch(getDataFromApi({ API_URL: dynamicUrl,payload: payload }))
        .then((action) => {
          const updatedCompanyData = action.payload;
          if (updatedCompanyData) {
            setOpportunity(updatedCompanyData.data);
            console.log('l-85', updatedCompanyData);
            setDetailsOpportunityTypeSlug(updatedCompanyData.data.opportunity_type_slug)


          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

    };

    fetchData();
  }, [dispatch,slug_name]);
  useEffect(() => {

    const createdAtDate = new Date(opportunity?.created_at);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate.getTime() - createdAtDate.getTime();
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
    if (differenceInDays > 0) {
      setpostJobDate(differenceInDays + 'days ago');
    }
    else {
      setpostJobDate('Today');
    }

    if (opportunity?.end_from) {
      const createdAtDate = new Date(opportunity?.end_from);
      const currentDate = new Date();
      const differenceInMilliseconds = currentDate.getTime() - createdAtDate.getTime();
      const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
      if (differenceInDays > 0) {

        setEndJobDate(differenceInDays);
      }
    }
    if (opportunity?.opening_for) {
      const opening = opportunity?.opening_for
      const capitalizedOpeningFor = opening.charAt(0).toUpperCase() + opportunity?.opening_for.slice(1);
      setOpeningFor(capitalizedOpeningFor);
    }


  }, [opportunity])

  //easy apply

  const apply = (event) => {

    event.preventDefault();

    const dynamicUrl = process.env.REACT_APP_SIMPLE_APPLY_API_URL;


    dispatch(sendUserData({ token: login_token, pageurl: dynamicUrl, slug: slug }))
      .then((action) => {
        const applyResponce = action.payload;

        withReactContent(Swal).fire({
          title: <i>{applyResponce.message}</i>,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }
  const reportjob = (event) => {
    event.preventDefault();
    const url = process.env.REACT_APP_REPORT_JOB_API_URL

    try {
      if (reportReason) {
        // console.log(reportReason);
        dispatch(sendUserData({ data: reportReason, pageurl: url }))
          .then((action) => {

            const result = action.payload.message;
            if(result){
              setReportModal(false);
            }
            withReactContent(Swal).fire({
              title: "Success",
              text: result
            });

          })
          .catch((error) => {
            withReactContent(Swal).fire({
              title: "Error!!!",
              text: "SomeThing Wents To Wrong"

            });
          });
      } else {
        alert('SomeThing Wents To Wrong');

      }
    } catch (error) {
      console.error('Error sending data to API:', error.message);
    }


  }

  const home = process.env.REACT_APP_ROUTES_HOME;
  const opportunity_type = process.env.REACT_APP_ROUTES_OPPORTUNITY_TYPE;

  const breadcrumb = [
    {
      home: base_url + home,
      'opportunity_type': base_url + opportunity_type,
      'opportunity-details': base_url + slug,
    }
  ];
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const handleInputChange = (e) => {

    const { name, value } = e.target;
    const updatedData = {
      ...easyApplyData,
      [name]: value,
      opportunity_slug: opportunity ? opportunity?.opportunity_slug : null
    };
    setEasyApplyData(updatedData);

  }


  const easyApply = (event) => {
    event.preventDefault();
    const url = process.env.REACT_APP_EASY_APPLY_API_URL

    try {
      if (easyApplyData) {
        dispatch(sendUserData({ data: easyApplyData, pageurl: url }))
          .then((action) => {

            const result = action.payload.message;
            withReactContent(Swal).fire({
              title: "Success",
              text: result

            });

          })
          .catch((error) => {
            withReactContent(Swal).fire({
              title: "Error!!!",
              text: "SomeThing Wents To Wrong"

            });
          });
      } else {
        alert('SomeThing Wents To Wrong');

      }
    } catch (error) {
      console.error('Error sending data to API:', error.message);
    }
  }

  return (
    <>

      <div className="wrapper">
        <MetaTag title={opportunity?.title && opportunity?.city_name ? (opportunity?.title + '  ' + opportunity?.city_name) : "Spot In Job Consultancy"} name="company page"
          contact={opportunity?.title && opportunity?.city_name ? ('Apply to' + opportunity?.title + 'Jobs in  ' + opportunity?.city_name) : "Apply on Job That Perfect For"}
        />
        <Breadcrumb breadcrumb={breadcrumb} />
        {/* <Job_posting_google_schema data={Company}/> */}

      </div>
      <section className="single-job-thumb tf-slider sl7 over-flow-hidden opportunityType">
        <img
          src={banner}
          alt="opportunityType-banner"
        />
      </section>

      <section className="form-sticky fixed-space">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wd-job-author stc-em">
                <div className="inner-job-left">
                  <img
                    src={opportunity?.company_logo_url}
                    alt="Spot In Job Consultancy"
                    className="logo-company"
                  />

                  <div className="content">
                    <Link title={opportunity?.company_name} className="opportunity-company-name" to={opportunity?.website_link}>
                      {opportunity?.company_name}
                    </Link>
                    <h1 className="companyDetails">
                      <Link to="#" className="company-name" title={opportunity?.title}>
                        Job For
                        {opportunity?.title}
                        {(opportunity?.city_name || opportunity?.state_name || opportunity?.country_name) && ' in '}
                        {opportunity?.city_name}
                        {(opportunity?.city_name) && ','}
                        {opportunity?.state_name}
                        {(opportunity?.state_name) && ','}

                        {opportunity?.country_name}
                      </Link>&nbsp;<br />

                    </h1>

                    <ul className="d-flex">
                      <li>
                        <span className="icon-map-pin" style={{ marginRight: "2px" }}></span>
                        {opportunity?.city_name} {opportunity?.state_name}
                      </li>
                      {(postJobDate != ' ' && postJobDate != 'NaN') &&

                        <li style={{ marginLeft: "10px" }}>
                          <span className="icon-calendar"></span>&nbsp;

                          {postJobDate}
                        </li>
                      }
                    </ul>
                    <ul className="job-tag d-flex">
                      {
                        opportunity?.job_type &&
                        <li>
                          <Link to="#" title={opportunity?.job_type} className="btn job-type-btn">{opportunity?.job_type.replace(/[^a-zA-Z ]/g, " ")}</Link>
                        </li>
                      }
                      <li style={{ marginLeft: "10px" }}>
                        <button type="button" className="job-type-btn report-job" onClick={() => setReportModal(true)}>REPORT JOB</button>
                      </li>

                    </ul>
                    <Modal
                      size="lg"
                      isOpen={reportModal}
                      toggle={() => setReportModal(!reportModal)}
                    >
                      <ModalHeader
                        toggle={() => setReportModal(!reportModal)}
                      >
                        Enter Your Details
                      </ModalHeader>
                      <ModalBody>
                        {
                          (localStorage.getItem('items')) ?
                            <form onSubmit={reportjob}>
                              <div>
                                <label>Reason For Report Job</label>
                                <textarea
                                  value={reportReason.reason}
                                  onChange={(e) => setReportReason({
                                    ...reportReason,
                                    reason: e.target.value
                                  })}
                                ></textarea>
                              </div>
                              <button type="submit">
                                Apply
                              </button>
                            </form>
                            :
                            <div>Need to report this opportunity? You Need to Login</div>
                        }
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
                <div className="content-right">
                  <div className="top">

                    {showPopup && (
                      <div className="popup">
                        <button type="button"><FacebookShareButton url={window.location.href} ><FacebookIcon size={32} round={true} /></FacebookShareButton></button>
                        <button type="button"><TwitterShareButton url={window.location.href}><TwitterIcon size={32} round={true} /></TwitterShareButton></button>
                        <button type="button"><LinkedinShareButton url={window.location.href}><LinkedinIcon size={32} round={true} /></LinkedinShareButton></button>
                        <button type="button"><WhatsappShareButton url={window.location.href}><WhatsappIcon size={32} round={true} /></WhatsappShareButton></button>
                        <button type="button" onClick={togglePopup}>

                          <span role="img" aria-label="Close" className="close-button" >&#10006;</span>
                        </button>
                      </div>
                    )}
                    <button type="button" onClick={togglePopup} className="share"> <i className="icon-share2" /></button>
                    {/* <Link to="#" className="share" onClick={togglePopup}>
                      <i className="icon-share2" />
                    </Link> */}
                    <Link to="#" className="wishlist" title="wishlist">
                      <i className="icon-heart" />
                    </Link>
                    {
                      login_token ? <button type="button" onClick={apply} className="apply-now-btn"><i className="icon-send apply-icon"></i>Apply Now</button>
                        : <button type="button" onClick={() => setModal(true)} className="apply-now-btn"><i className="icon-send apply-icon"></i>Apply Now</button>
                    }

                    <Modal
                      size="lg"
                      isOpen={modal}
                      toggle={() => setModal(!modal)}
                    >
                      <ModalHeader
                        toggle={() => setModal(!modal)}
                      >
                        Enter Your Details
                      </ModalHeader>
                      <ModalBody>
                        <DragDrop onDataReceived={handleDataReceived} />
                        {
                          (details != null) &&
                          <form onSubmit={easyApply}>

                            <div >
                              <label>Name</label>
                              <input type="text" name="name" placeholder="Enter Your Name" value={easyApplyData.name}
                                onChange={handleInputChange} />
                            </div>
                            <br />
                            <div >
                              <label>Email</label>
                              <input type="email" name="email" placeholder="Enter Your Email" value={easyApplyData.email}
                                onChange={handleInputChange} />
                            </div>
                            <br />
                            <div >
                              <label>Phone No</label>
                              <input type="text" name="phone" placeholder="Enter Your Contact Number" value={easyApplyData.phone}
                                onChange={handleInputChange} />
                            </div>
                            <br />
                            <button type="submit" >
                              Apply
                            </button>
                          </form>
                        }
                        {/* } */}

                      </ModalBody>
                    </Modal>
                  </div>
                  <div>
                    <ul className="d-flex">

                      {(postJobDate != null && postJobDate != 'NaN' && endJobDate > 0) &&

                        <li className="day-to-left">
                          <span className="icon-calendar"></span>&nbsp; {endJobDate} days left to apply

                        </li>
                      }
                    </ul>
                    <div className="price">
                      <p className="opprtunity-salary">
                        ₹ {opportunity?.minimum_salary} to {opportunity?.maximum_salary}
                        <span className="year">/Month</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="tf-container">
          <div className="row">
            <div className="" >

              <div >
                <div className="row">
                  {opportunity?.minimum_experience &&
                    <div className="col-lg-2 col-md-6 mb-4">
                      <div className="card card-opportunity">
                        <div className="card-body">
                          <div className="heading">Experience</div>
                          <Link to="#" title={opportunity?.minimum_experience} className="text">{opportunity?.minimum_experience}</Link>
                        </div>
                      </div>
                    </div>
                  }
                  {opportunity?.number_of_opening &&
                    <div className="col-lg-2 col-md-6 mb-4">
                      <div className="card card-opportunity">
                        <div className="card-body">
                          <div className="heading">Total Opening</div>
                          <div className="text">{opportunity?.number_of_opening}</div>
                        </div>
                      </div>
                    </div>
                  }
                  {(opportunity?.opening_for != null) &&
                    <div className="col-lg-2 col-md-6 mb-4">
                      <div className="card card-opportunity">
                        <div className="card-body">
                          <div className="heading">Opening For</div>
                          <div className="text">{openingFor}

                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {opportunity?.title &&
                    <div className="col-lg-2 col-md-6 mb-4">
                      <div className="card card-opportunity">
                        <div className="card-body">
                          <div className="heading">Designation</div>
                          <Link title={opportunity?.title} to="#" className="text">{opportunity?.title}</Link>
                        </div>
                      </div>
                    </div>
                  }

                  {(opportunity?.working_time_start && opportunity?.working_time_end) &&
                    <div className="col-lg-2 col-md-6 mb-4">
                      <div className="card card-opportunity">
                        <div className="card-body">
                          <div className="heading">Working Hour</div>
                          <div className="text">{opportunity?.working_time_start}  to {opportunity?.working_time_end}</div>
                        </div>
                      </div>
                    </div>
                  }
                  {opportunity?.industry_name &&
                    <div className="col-lg-2 col-md-6 mb-4">
                      <div className="card card-opportunity-last">
                        <div className="card-body">
                          <div className="heading">Industry</div>
                          <Link to="#" title={opportunity?.industry_name} className="text">{opportunity?.industry_name}</Link>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>

      </section>
      <section className="inner-employer-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-8 ml-5" >
              <div >

                <div className="wd-review-job pb-3 ml-5 pl-5">
                  {
                    (opportunity?.title || opportunity?.city_name) &&

                    <h3 className="opportunity-description-heading">Job for {opportunity?.title} in {opportunity?.city_name}</h3>

                  }
                  {
                    opportunity?.overview &&
                    <>
                      <h5 className="opportunity-description-subheading">Overview</h5>
                      <p className="opportunity-description-overview" dangerouslySetInnerHTML={{ __html: opportunity?.overview }}>

                      </p>
                    </>
                  }
                  {
                    opportunity?.job_description &&
                    <>
                      <h5 className="opportunity-description-subheading">Job Description</h5>
                      <p className="opportunity-description" dangerouslySetInnerHTML={{ __html: opportunity?.job_description }}>
                      </p>
                    </>
                  }
                  {
                    opportunity?.responsibilities &&
                    <>
                      <h5 className="opportunity-description-subheading">Responsibilities</h5>
                      <p className="opportunity-description" dangerouslySetInnerHTML={{ __html: opportunity?.responsibilities }}>
                      </p>
                    </>
                  }
                  {
                    opportunity?.benefits &&
                    <>
                      <h5 className="opportunity-description-subheading">Benefits</h5>
                      <p className="opportunity-description-benefits" dangerouslySetInnerHTML={{ __html: opportunity?.benefits }}>
                      </p>
                    </>
                  }
                  {
                    opportunity?.required_skills &&
                    <>
                      <h5 className="opportunity-description-subheading">Required Skills</h5>
                      <p className=" opportunity-description-required-skill" dangerouslySetInnerHTML={{ __html: opportunity?.required_skills }}>
                      </p>
                    </>
                  }

                  {
                    opportunity?.notes &&

                    <div className="notes">

                      <h5 className="opportunity-description-subheading">Notes</h5>
                      <p className="opportunity-description" dangerouslySetInnerHTML={{ __html: opportunity?.notes }}>
                      </p>
                    </div>
                  }

                </div>


              </div>

            </div>

            <div className="col-lg-4">
              <div className="cv-form-details po-sticky job-sg single-stick">
                <ul className="list-infor">
                  {
                    opportunity?.title && (
                      <li>
                        <div className="category">Functional Area:</div>
                        <div className="detail">
                          <Link to={opportunity?.title} title={opportunity?.title}>
                            {opportunity?.title}
                          </Link>
                        </div>
                      </li>
                    )}
                  {
                    opportunity?.website_link && (
                      <li>
                        <div className="category">Website:</div>
                        <div className="detail">
                          <Link to={opportunity?.website_link} title={opportunity?.website_link}>
                            {opportunity?.website_link}
                          </Link>
                        </div>
                      </li>
                    )}
                  {
                    opportunity?.weekdays && (
                      <li>
                        <div className="category">Working Days:</div>
                        <div className="detail">
                          {opportunity?.weekdays
                            .split(',') // Split the string by commas
                            .map(day => day.trim().charAt(0).toUpperCase() + day.trim().slice(1)) // Capitalize first letter of each word
                            .join(', ') // Join the words back together
                          }
                        </div>
                      </li>
                    )
                  }
                  {
                    (opportunity?.lunch_time_start && opportunity?.lunch_time_end) && (
                      <li>
                        <div className="category">Lunch Break:</div>
                        <div className="detail">{opportunity?.lunch_time_start} to {opportunity?.lunch_time_end}</div>
                      </li>
                    )
                  }

                  {/* {
                    totalEmployee != 0 && (
                      <li>
                        <div className="category">Company size</div>
                        <div className="detail">{totalEmployee} employees</div>
                      </li>
                    )
                  } */}
                  {/* {
                    Company.company_street_address && (
                      <li>
                        <div className="category">Headquarters</div>
                        <div className="detail" style={{ marginLeft: "30px" }}>{Company.company_street_address + ', ' + Company.city_name}</div>
                      </li>
                    )
                  } */}

                </ul>
              </div>
            </div>

          </div>
        </div>
      </section>
      <JobBlockComponent title="Related Jobs" itemPerPage="4" relatedJobs="yes" blockHeight='w-50' />




    </>
  );
}

export default CompanyDetails;
