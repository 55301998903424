import {React,useState} from "react";
import Search from "../components/main/search";
import MetaTag from "../components/main/meta-tag";
import Breadcrumb from "../components/google_schema/breadcrumb";
import Trusted_by_employee from '../components/main/trusted_by_employee'
import Commingsoon from "../components/main/comming_soon";


function Find_talent() {
  const base_url = process.env.REACT_APP_LOCAL_URL;
  const home = process.env.REACT_APP_ROUTES_HOME;
  const find_talent = process.env.REACT_APP_ROUTES_FIND_TALENT;


  const breadcrumb = [
    {
      home: base_url + home,
      find_talent: base_url + find_talent,
    },
  ];
  const [displayData, setDisplaydata] = useState(false);

  return (
    <>

      <div className="wrapper">
        <MetaTag
          title="Find Talent"
          name="find talent page"
          content="Explore exciting job opportunities at YourCompany. Browse and apply for jobs in various fields and locations. Start your career with us!"
        />
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <div>
        <Commingsoon />
        {
          displayData &&
          <>
            <Search />
            <Trusted_by_employee />
          </>
        }
      </div>
    </>
  );
}

export default Find_talent;
