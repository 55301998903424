import React, { useState, useEffect } from "react";
import CompanyComponent from './company'
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import MetaTag from "../meta-tag";
import Breadcrumb from '../../google_schema/breadcrumb';
import Job_posting_google_schema from "../../google_schema/job_posting_google_schema";
import { Link } from "react-router-dom";
import SkeletonComponent from './skeleton'
import Preloader from "../../preloader";

function Company({ currentItemsHome, title, className, PerPage }) {

  const dispatch = useDispatch();
  const [Company, setCompany] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState();
  const [urlData, setUrlData] = useState('');
  const base_url = process.env.REACT_APP_BASE_URL;
  const local_url = process.env.REACT_APP_LOCAL_URL;
  const company_url = process.env.REACT_APP_ROUTES_FIND_COMPANY;
  const [totalCompany,setTotalCompany] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showButton,setShowButton] =useState(true);
  useEffect(() => {
    setCurrentPage(1);
  }, [])
  
// console.log('L-29',base_url+company_url);
  const paginateData = () => {
    setShowButton(false);
    setCurrentPage(currentPage + 1);
  }
  useEffect(() => {
    if (PerPage) {
      setItemsPerPage(PerPage)
    }
    else {
      setItemsPerPage(20);
    }
  })
  useEffect(() => {
    const fetchData = () => {
      const url = window.location.href;
      if (url === local_url+company_url) {
        setUrlData(url);
      }
      const dynamicUrl = process.env.REACT_APP_COMPANY_API_URL;
      setIsLoading(true);
      const payload = {page: currentPage,per_page: itemsPerPage }
      try{
      dispatch(getDataFromApi({ API_URL: dynamicUrl,payload: payload }))
        .then((action) => {
          const updatedCompanyData = action.payload;
          const roundedNumber = Math.floor(updatedCompanyData.data.total / 100) * 100;
          setTotalCompany(roundedNumber)
          sessionStorage.setItem('total_company',roundedNumber);
          if (updatedCompanyData) {
            setCompany(prevCompany => [...prevCompany, ...updatedCompanyData.data.items]);
            if(setCompany){
              setShowButton(true);
            }
          } else {
            setCompany([]);
      
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          }    );
       }
       finally{
        setIsLoading(false);
       }
      
    };
    if (currentPage != undefined && itemsPerPage != undefined) {
      fetchData();

    }
  }, [dispatch, currentPage]);

  const home = process.env.REACT_APP_ROUTES_HOME;
  const company_type = process.env.REACT_APP_ROUTES_COMPANY_TYPE;

  const breadcrumb = [
    {
      home: base_url + home,
      'company-type': base_url + company_type,
    }
  ];

  return (
    <>
      <div className="wrapper">
        {/* <MetaTag name="find jobs page" content="Explore exciting job opportunities at YourCompany. Browse and apply for jobs in various fields and locations. Start your career with us!" /> */}
        <Breadcrumb breadcrumb={breadcrumb} />

      </div>
  
      {Company.length > 0 ?
        <section className={className} >
          <div className="tf-container">
            <div className="tf-title style-2 d-flex flex-sm-row flex-column">
              <div className="group-title mt-5 ">
                <h2 className='section-title'>{title}</h2>
                <p>Find the Company that’s perfect for you. about 
                <strong>  {totalCompany}+ </strong>
                  new jobs everyday</p>
              </div>
          
            <div className="all-category">
                {(!urlData) && (
                  <Link title="All Companies" to='/find-company' className='mt-5 tf-button all-company' >All Companies
                    <span className="icon-arrow-right2"></span>
                  </Link>
                )}
              </div>
            </div>
            <div className="row">
              <div className=' mb-5 ' >
                {urlData && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {/* <button className="company-type-button load-more-button">Standard</button>
                    <button className="company-type-button load-more-button"  >Premier</button> */}
                  </div>
                )
                }

              </div>
            </div>
            <CompanyComponent currentItems={Company} />

            {(urlData) && (
              <div className="col-md-12" id="show_more_button">
                <div className="wrap-button">
                  {
                    showButton && <button className="load-more-button" onClick={paginateData}>Load More</button>
                  }
                  {
                    !showButton &&    
                      <div className="spinner" />
                  }
                  
                </div>
              </div>
            )}
          </div>
        </section> : 
        <div className=" mt-5">
          <div className=" d-flex mt-5">

        <SkeletonComponent />
        <SkeletonComponent />
        <SkeletonComponent />
          </div>
        </div>
      }
    </>
  );
}

export default Company;
