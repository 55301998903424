import React,{useState} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useDispatch, useSelector } from 'react-redux';
import { sendUserData } from '../../../slice/postSlice';
import Header from '../company/company-header'
import MetaTag from '../meta-tag';

function Reset_Password() {

  const dispatch = useDispatch();
  const [changeUserPassword, setChangeUserPassword] = useState({
    old_password: '',
    new_password: '',
  });
  const [confirmPasswordChange,setConfirmPassword]=useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if(name === "new_password" && !passwordRegex.test(value)){

      setChangeUserPassword({
        ...changeUserPassword,
        [name]:value
      })
    }
    else{
      setChangeUserPassword({
        ...changeUserPassword,
        [name]:value
      })
    }
  
  };
  const handleConfirmPasswordChange = (e) =>{
    setConfirmPassword(e.target.value)
 }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const url= process.env.REACT_APP_CHANGE_PASSWORD_API_URL;
    try {
      if (changeUserPassword.new_password === confirmPasswordChange ) {
        
        dispatch(sendUserData({data:changeUserPassword,pageurl:url}))
        .then((action) => {
          withReactContent(Swal).fire({
            title: <i>profile updated successfully</i>,
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      
    }
    else{
      withReactContent(Swal).fire({
        title: <i>password and confirmpassword is not same</i>,
      });
    }
   } catch (error) {
      console.error('Error sending data to API:', error.message);
    }
  };


  return (
    <>
      <MetaTag title="Change Password" name="description" content="You can Easily Change Your Password" />


      <Header title="Changes Your Password" description="Reset Your Password Easily!!!" contactus_header="contactus_header" />

    <section className="account-section tf-slider sl7 over-flow-hidden opportunityType">
    <div className="tf-container">
      <div className="row">
        <div className="wd-form-login">
          <h1 className='mt-5 h4'>Change password?</h1>

          <form  onSubmit={handleSubmit}>
            <div className="ip">
            <label>
                Recent Password<span>*</span>
              </label>
              <input
                type="text"
                name='old_password'
                placeholder="Enter Recent Password"
                onChange={handleInputChange}
              />
            </div>
            <div className="ip">
              <label>
                New Password<span>*</span>
              </label>
              <div className="inputs-group auth-pass-inputgroup">
              <input
                type="text"
                name='new_password'
                placeholder="Enter New Password"
                onChange={handleInputChange}
              />
              </div>
            </div>
            <div className="ip">
              <label>
                Confirm Password<span>*</span>
              </label>
              <div className="inputs-group auth-pass-inputgroup">
              <input
                type="text"
                name='confirm_new_password'
                placeholder="Enter Confirm Password"
                onChange={handleConfirmPasswordChange}
              />
              </div>
            </div>
            <button className='wd-form-login-button' type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Reset_Password