import React from 'react'
import { useState } from "react";
import { sendUserData } from '../../../slice/postSlice';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Header from '../company/company-header';
import MetaTag from '../meta-tag';

export default function Index() {
  const [userEmail, setuserEmail] = useState({
    email: ''
  });
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('l-8', userEmail);
    const url = process.env.REACT_APP_FORGOT_PASSWORD_API_URL;
    dispatch(sendUserData({ data: userEmail, pageurl: url }))
      .then((action) => {
        // console.log('l-19', action)
        const result = action.payload;
        withReactContent(Swal).fire({
          title: result.message,
          text: result.data
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  return (
    <>
      <MetaTag title="Forgot Password" name="description" content="Not To Worry, You Can Change Your Password Easily!!!" />

      <Header title="Forgot Password" description="Not To Worry, You Can Change Your Password Easily!!!" contactus_header="contactus_header" />

      <section className="mt-5 account-section tf-slider sl7 over-flow-hidden opportunityType">
        <div className="tf-container">
          <div className="row">
            <div className="wd-form-login mt-5">
              <h1 className='h4'>Forgot password???</h1>

              <form onSubmit={handleSubmit}>
                <div className="ip">
                  <label>
                    EmailAddress<span>*</span>
                  </label>
                  <div className="inputs-group auth-pass-inputgroup">
                    <input
                      type="email"
                      name='email'
                      placeholder="Email"
                      // onChange={(e)=>setuserEmail((e.target.value))}
                      onChange={(e) => {
                        setuserEmail({
                          email: e.target.value
                        })
                      }}
                    />
                  </div>
                </div>
                <button className='wd-form-login-button'>Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


