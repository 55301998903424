import React from 'react'
import AboutComponent from '../../src/components/main/about'
import Banner_counter from '../components/main/about/banner_counter'
import How_its_work from '../components/main/about/how_its_work'
import AboutSection4 from '../components/main/about/about_section4'
import Testimonials from '../components/main/testimonials'
import Partner from '../components/main/about/partners'
import About_Section6 from '../components/main/about/about_section6'
import Award_Section from '../components/main/awards'

import MetaTag from '../components/main/meta-tag'

function About() {
  return (
    <div>
      <MetaTag title="About Us - Our History | Spot In Job Consultancy" name="description" content="Spot In Job Consultancy is a leading placement consultant with over 4+ years of experience & a speciality in IT sector makes it prominent in Rajkot & Ahmedabad." />
      <AboutComponent />
      <Banner_counter />
      <How_its_work />
      <AboutSection4 />
      {/* <Award_Section /> */}
      <Testimonials />
      <Partner />
      <About_Section6 />
            </div>
  )
}

export default About