import React from 'react'
import CTA from '../../../../assets/images/CTA.png'

function About_Section6() {
  return (
    <section className="wd-banner-CTA">
    {/* <img src={CTA} alt='section6 image' className='wd-banner-CTA'/> */}
    <div className="tf-container">
      <div className="row">
        <div className="col-lg-12  wow fadeInUp">
          <div className="group-banner-CTA">
            <h3>Job Opportunities Are Always Open</h3>
            <p>
              Aenean porta, eros lacus congue lorem, sit amet mollis magna
              velit ac erat.
            </p>
            <div className="group-btn">
              <button className="tf-btn">Search Job</button>
              <button className="tf-btn">Find Candidates</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default About_Section6