import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png'
import { useNavigate } from "react-router-dom";
import { CAlert } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLocationPin, faLocationDot, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
Footer.propTypes = {};

function Footer(props) {
    const [visible, setVisible] = useState(true);
    const address = process.env.REACT_APP_ADDRESS;
    const email = process.env.REACT_APP_EMAIL;
    const contacts = JSON.parse(process.env.REACT_APP_CONTACTS || '[]');
    const [year, setYear] = useState(new Date().getFullYear());
    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="top-footer">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="footer-logo">
                                <img src={logo} alt="Spot In Job Consultancy" className="ml-3" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <div className="wd-social d-flex aln-center">
                                <ul className="list-social d-flex aln-center">
                                    <li><Link target="_blank" to="https://www.facebook.com/spotinjob/" title="Facebook"><i className="icon-facebook"></i></Link></li>
                                    <li><Link target="_blank" to="https://www.linkedin.com/company/spotinjob/" title="LinkedIn"><i className="icon-linkedin2"></i></Link></li>
                                    <li><Link target="_blank" to="https://twitter.com/i/flow/login?redirect_after_login=%2Fspotinjob" title="Twitter"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="15" height="15"><path d="M38.008 4.688h6.895L29.844 21.895 47.559 45.313H33.691L22.822 31.113 10.4 45.313H3.496l16.104 -18.408L2.617 4.688h14.219l9.814 12.979zm-2.422 36.504h3.818L14.756 8.594h-4.102z"/></svg></Link></li>
                                    <li><Link target="_blank" to="https://www.pinterest.com/spotinjob/" title="Pinterest"><i className="icon-pinterest"></i></Link></li>
                                    <li><Link target="_blank" to="https://www.instagram.com/spotinjob/" title="Instagram"><i className="icon-instagram1"></i></Link></li>
                                    <li><Link target="_blank" to="https://www.youtube.com/channel/UCtIczlYI36gPuZQlDW0p6yQ" title="YouTube"><i className="icon-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-footer">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <section className="footer-cl-1">
                                <div className="icon-infor d-flex aln-center mb-0">
                                    <div className="icon">
                                        <span className="icon-call-calling"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span>
                                    </div>

                                    <div className="content">
                                        <p>Need help? 24/7</p><br />
                                        <p className='contact_no'><Link to="tel:91 7487(9) 23934" title="+91 7487(9) 23934" className="footer-links">{contacts[0]}</Link></p>
                                        <p className='contact_no'><Link to="tel:91 7487(0) 23934" title="+91 7487(0) 23934" className="footer-links">{contacts[1]}</Link></p>
                                    </div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="">
                                        <svg fill="#000000" width="40px" height="40px" viewBox="0 0 24 24" id="email" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
                                            <rect id="primary" x="2" y="4" width="20" height="16" rx="2" style={{ fill: '#80afcf' }}></rect>
                                            <path id="secondary" d="M21.25,4.45A2,2,0,0,0,20,4H4a2,2,0,0,0-1.25.45A1,1,0,0,0,2.76,6l8,6.29a2,2,0,0,0,2.48,0l8-6.29a1,1,0,0,0,0-1.56Z" style={{ fill: '#052c65' }}></path>
                                        </svg>
                                    </div>
                                    <div className="content">
                                        <p ><Link  to={{ pathname: `mailto:${email}` }} title={process.env.REACT_APP_EMAIL} className="footer-links ml-5 contact_no">{process.env.REACT_APP_EMAIL}</Link></p>
                                    </div>
                                </div>
                                <div className="d-flex footer-address">
                                    <div className="icon">
                                        <svg fill="#000000" width="40px" height="40px" viewBox="0 0 24 24" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <linearGradient id="grad2" x1="0%" x2="100%" y1="0%" y2="0%">
                                                    <stop offset="0%" stopColor="#80afcf" />
                                                    <stop offset="30%" stopColor="#80afcf" />
                                                    <stop offset="60%" stopColor="#0361a0" />
                                                    <stop offset="100%" stopColor="#0361a0" />
                                                </linearGradient>
                                            </defs>
                                            <path d="M17.657 5.304c-3.124-3.073-8.189-3.073-11.313 0-3.124 3.074-3.124 8.057 0 11.13l5.656 5.565 5.657-5.565c3.124-3.073 3.124-8.056 0-11.13zm-5.657 8.195c-.668 0-1.295-.26-1.768-.732-.975-.975-.975-2.561 0-3.536.472-.472 1.1-.732 1.768-.732s1.296.26 1.768.732c.975.975.975 2.562 0 3.536-.472.472-1.1.732-1.768.732z" fill="url(#grad2)" />

                                        </svg>
                                    </div>

                                    <div className="content">

                                        <p className='content-h6 '><Link to="https://www.google.com/maps/place/Spot+In+Job+Consultancy/@22.2992608,70.7798399,17z/data=!3m1!4b1!4m6!3m5!1s0x3959ca27bcac697f:0x50eaaebb92b9790b!8m2!3d22.2992559!4d70.7824148!16s%2Fg%2F11dyb8_kk5?entry=ttu" title="Our Location" className="footer-links contact_no">{address} <br /><br /> Get Direction </Link><FontAwesomeIcon icon={faArrowRight} /></p>

                                    </div>
                                </div>

                                <div>   


                                </div>
                            </section>
                        </div>
                        <div className="col-lg-2 col-md-6 col-6">
                            <section className="footer-cl-2">
                                <h2 className="ft-title">Quick Links</h2>
                                <ul className="navigation-menu-footer">
                                    <li><Link to={process.env.REACT_APP_ROUTES_FIND_JOBS} title="Find Jobs">Find Jobs</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_CONTACT_US} title="Find Company">Find Company</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_FIND_WORK} title="Find Work">Find Work</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_BLOG} title="Blogs">Blogs</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_UPLOADCV} title="Upload CV">Upload CV</Link></li>
                                </ul>
                            </section>
                        </div>
                        <div className="col-lg-2 col-md-6 col-6">
                            <section className="footer-cl-2">
                                <h2 className="ft-title">Jobaboard</h2>
                                <ul className="navigation-menu-footer">
                                    <li><Link to={process.env.REACT_APP_ROUTES_ABOUT_US} title="About Us">About Us</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_CONTACT_US} title="Contact Us">Contact Us</Link></li>
                                    <li><Link to="#" title="Send Feedback">Send Feedback</Link></li>
                                    <li><Link to="#" title="HTML Sitemap">HTML Sitemap</Link></li>
                                    <li><Link to="#" title="XML Sitemap">XML Sitemap</Link></li>

                                </ul>
                            </section>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <section className="footer-cl-3">
                                <h3 className="ft-title">Legal</h3>
                                <ul className="navigation-menu-footer">
                                    <li><Link to={process.env.REACT_APP_ROUTES_SECURITY} title="Security & Fraud">Security & Fraud</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_TERMSOFUSE} title="Terms of Use">Terms of Use</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_BEAWARE} title="Beware of Fraudsters">Beware of Fraudsters</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_BESAFE} title="Be Safe">Be Safe</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_COMPLAINTS} title="Complaints">Complaints</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_PRIVACY_POLICY} title="Privacy Policy">Privacy Policy</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_COOKIE_POLICY} title="Cookie Policy">Cookie Policy</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_COPYRIGHT} title="Copyright">Copyright</Link></li>
                                    <li><Link to={process.env.REACT_APP_ROUTES_DISCLAIMER} title="Disclaimer">Disclaimer</Link></li>
                                </ul>
                            </section>
                        </div>
                        <div className="col-lg-2 col-md-4 col-6">
                            <section className="footer-cl-4">
                                <h4 className="ft-title">Recognition</h4>
                                <ul className="navigation-menu-footer">
                                    <li><Link title="Indeed" target="_blank" to="https://in.indeed.com/Spotinjob-jobs-in-Rajkot,-Gujarat">Indeed</Link></li>
                                    <li><Link title="SimplyHired.co.in" target="_blank" to="https://www.simplyhired.co.in/search?q=spotinjob&job=UKJOLAp233q9j4oG8wV_V4kWUCReYBzI_jLhTSY5G67jtL1ifBWZyw">Simply Hired</Link></li>
                                    <li><Link title="Clutch.co" target="_blank" to="https://clutch.co/profile/spot-job#highlights">Clutch</Link></li>
                                    <li><Link title="Insightssuccess.in" target="_blank" to="https://www.insightssuccess.in/spot-in-job-a-premier-recruitment-consultant/">Insights Success</Link></li>
                                    <li><Link title="Magazines.insightssuccess.in" target="_blank" to="https://magazines.insightssuccess.in/Indias-Most-Admired-HR-Solution-Providers-To-Watch-November2020/#page=20">Magazines Insights Success</Link></li>
                                    <li><Link title="DesignRush" target="_blank" to="https://www.designrush.com/agency/hr-outsourcing/recruiting">Design Rush</Link></li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="tf-container">
                    <div className="row">
                        <div className="text-center">
                            <CAlert color="danger" dismissible visible={visible} onClose={() => setVisible(false)}>
                                <span className="blink">IMPORTANT ALERT: </span>
                                We are headquartered at Rajkot, Gujarat and DO NOT have any branches at any other place. We are not calling and asking any job seeker to pay any amount either to register with us or to get a job.
                            </CAlert>
                            <hr />
                            <div className="copyright">
                                <p>COPYRIGHT ©2013-{year} SPOT IN JOB CONSULTANCY. ALL RIGHTS RESERVED. POWERED BY <Link target="_blank" to='https://websmaniac.com/'><span className='copyright-company'>WEBSMANIAC INC</span></Link>.
                                </p>
                            </div>
                            <div>
                                <Link target="_blank" title="INDIA'S MOST ADMIRED HR SOLUTION PROVIDERS TO WATCH" 
                                name="footer-links" to="https://www.insightssuccess.in/indias-most-admired-hr-solution-providers-to-watch-november2020/">INDIA'S MOST ADMIRED HR SOLUTION PROVIDERS TO WATCH</Link>
                            </div>
                            <p>OUR SITE STORES A VARIETY OF DATA TO PROVIDE RELEVANT JOB OPENINGS. ALSO OUR SITE IS PROTECTED BY
                                <Link to={process.env.REACT_APP_ROUTES_COPYRIGHT} title="copyright laws">
                                    &nbsp;<strong>COPYRIGHT LAWS</strong>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
