import React from 'react'
import banner from '../../../assets/images/IIM_banner.jpg'
import { Link,useLocation } from "react-router-dom";
import uni_logo from '../../../assets/images/IIM.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faClock, faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


function university_details() {
  return (
   <>
     <section className="single-job-thumb tf-slider sl7 over-flow-hidden opportunityType university-banner">
        <img
          src={banner}
          alt="university_details-banner-images"
        />
      </section>

      <section className="form-sticky fixed-space">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wd-job-author stc-em">
                <div className="inner-job-left">
                  <img
                    src={uni_logo}
                    alt="Spot In Job Consultancy"
                    className="logo-company"
                  />
                  <div className="content">
                    <h1 className="companyDetails">
                      <Link to="#" className="company-name" title='IIM Ahmedabad Courses & Fees 2024'>IIM Ahmedabad Courses & Fees 2024</Link>&nbsp;
                     
                    </h1>
                    <div className="job-info">
                      <span className="icon-map-pin uni-span"></span>&nbsp;
                      <span className='uni-link'> Vastrapur, Ahmedabad</span>
                      <FontAwesomeIcon icon={faStar} /> 4.7/5 Reviews
                    </div>
                    <div>
                      <span className='uni-span'>Public/Government Institute</span> &nbsp;&nbsp;
                      <span className='uni-span'>Estd. 1961</span>
                    </div>
                  </div>
                </div>  
                <div className="content-right">
              <div className="top">
                <Link to="#" className="share" title='share'>
                  <i className="icon-share2" />
                </Link>
                <Link to="#" className="wishlist" title='wishlist'>
                  <i className="icon-heart" />
                </Link>
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-employer-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <Tabs className="job-article tf-tab single-job single-employer">
                <TabList className="menu-tab">
                  <Tab className="ct-tab">College Info</Tab>
                  <Tab className="ct-tab">Course & Fees</Tab>
                  <Tab className="ct-tab">Review</Tab>
                  <Tab className="ct-tab">Blog</Tab>
                  <Tab className="ct-tab">Career</Tab>
                </TabList>
                <div className="content-tab">
                <TabPanel className=" animation-tab ">
                <Tabs className="job-article tf-tab single-job single-employer">
                <TabList className="menu-tab">
                  <Tab className="ct-tab">College Info</Tab>
                  <Tab className="ct-tab">Hostal & Campus</Tab>
                  <Tab className="ct-tab">FAQ</Tab>
                </TabList>
                <div className="content-tab">
                <TabPanel className=" animation-tab ">
                <TabList className="menu-tab">
                    
                    </TabList>
                   
                  </TabPanel>
               
                </div>
                </Tabs>
                   
                  </TabPanel>
               
                </div>
              </Tabs>
            </div>
         
          </div>
        </div>
      </section>

   </>
  )
}

export default university_details