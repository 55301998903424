import React from "react";
import { Swiper }  from 'swiper/react'
import { SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { Link } from "react-router-dom";
import img1 from '../../../assets/images/Logo-1.png'
import img2 from '../../../assets/images/Logo-3.png'
import img3 from '../../../assets/images/Logo-4.png'
import img4 from '../../../assets/images/Logo-8.png'
import img5 from '../../../assets/images/Logo-2.png'
import img6 from '../../../assets/images/Logo-5.png'

function index() {
    const data = [
        {
          id: 1,
          img: img1,
        },
        {
          id: 2,
          img: img2,
        },
        {
          id: 3,
          img: img3,
        },
        {
          id: 4,
          img: img4,
        },
        {
          id: 5,
          img: img5,
        },
        {
          id: 6,
          img: img6,
        },
      ];
  return (
    <section>
      <div className="wd-partner mt-5">
        <div className="tf-container">
          <h2 className="title-partner">
            Over 100,000 recruiters use Spot In Job Consultancy to modernize their hiring
          </h2>

          <Swiper
            modules={[Autoplay]}
            spaceBetween={68.95}
            slidesPerView={6}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
            }}
            className="partner-type-6"
            loop={true}
            speed={2000}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              500: {
                slidesPerView: 3,
              },
              800: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
              1600: {
                slidesPerView: 6,
              },
            }}
          >
            {data.map((item) => (
              <SwiperSlide key={item.id}>
                <Link to="#" className="logo-partner" title="" key={item.id}>
                  <img src={item.img} alt="Spot In Job Consultancy" />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default index