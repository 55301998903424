import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import Skeleton from '../company/skeleton';

function Find_work_component() {
  const dispatch = useDispatch();

  const data = [
    {
      id: 1,
      title: "TOP Agency, Inc",
      name: "Full Stack Development",
      address: "Las Vegas, NV 89107, USA",
      jobs1: 'full time',
      job2: 'Remote',
      price: '8.33%',
    },
  ]
  const [showButton, setShowButton] = useState(true);
  const [opportunityData, setOpportunityData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(
    12
  );
  const base_url = process.env.REACT_APP_BASE_URL;
  const opportunity_url = process.env.REACT_APP_ROUTES_OPPORTUNITY_TYPE;
  const paginateData = () => {
    setShowButton(false);
    setCurrentPage(currentPage + 1);
  }
  useEffect(() => {
    const fetchData = () => {

      const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_DETAILS_API_URL;
      const payload = { page: currentPage, per_page: itemsPerPage }
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
        .then((action) => {
          const updatedOpportunityData = action.payload;

          if (updatedOpportunityData) {

            setOpportunityData(prevOpportunity => [...prevOpportunity, ...updatedOpportunityData.data.items]);
            setShowButton(true);

          } else {
            setOpportunityData([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    fetchData();
  }, [dispatch, itemsPerPage, currentPage]);

  return (
    <>
      {
        opportunityData.length <= 0 &&
        <div className='d-flex'>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      }
      <section className='find-work'>
        <Tabs className="wrap-testimonials style-1 over-flow-hidden tf-tab">
          <div className="tf-container">
            <div className="tf-title style-3 margin">
              {opportunityData.length > 0 &&
                <>
                  <div className="group-title">
                    <h2 className='mt-5'>Find Your Work</h2>
                    <p className=''>Send The Right Candidate For Opportunity </p>
                  </div>
                  <TabList className="menu-tab">
                    <Tab className="user-tag">Beginner</Tab>
                    <Tab className="user-tag">Mediator</Tab>
                    <Tab className="user-tag">Premium</Tab>
                  </TabList>
                </>
              }
            </div>
            <div className="content-tab">
              <TabPanel className="row wow fadeInUp animation-tab job-tab-item">
                {
                  opportunityData.map((idx) => (

                    <div key={idx.id} className="col-lg-3">
                      <div className="features-job find-work">
                        <div className="job-archive-header">
                          <div className="inner-box">
                            <div className="logo-company">
                              <img src={idx.company_logo_url} alt="Spot In Job Consultancy" />
                            </div>
                            <div className="box-content ">
                              <h4 className="opportunityTypeCompanyName" style={{ marginBottom: "0px" }}>
                                <Link to="#" title={idx.company_name}>{idx.company_name}</Link>
                              </h4>
                              <h3 className="opportunityWorkTypeName">
                                <Link to="#" title={idx.title}> {idx.title} </Link>

                              </h3>
                              <ul>
                                {
                                  idx.city_name || idx.state_name &&

                                  <li>
                                    <span className="icon-map-pin"></span>
                                    &nbsp;
                                    {idx.city_name}
                                    {idx.state_name && ','}
                                    {idx.state_name}
                                  </li>
                                }
                                {idx.number_of_opening &&
                                  <li>
                                    <span className="icon-calendar"></span>
                                    &nbsp;
                                    <span>Total opening - &nbsp;</span>
                                    <span className="h6">{idx.number_of_opening}</span>
                                  </li>
                                }
                                {(idx.minimum_experience || idx.minimum_experience) &&
                                  <li>
                                    <span className="icon-calendar"></span>
                                    &nbsp;
                                    <span className="h6"> {idx.minimum_experience}</span>

                                    {(idx.maximum_experience) && ' -'}
                                    <span className="h6">{idx.maximum_experience}</span> year(s) of experience
                                  </li>
                                }
                              </ul>

                            </div>
                          </div>
                        </div>
                        <div className="job-archive-footer">
                          <div className="job-footer-left findwork">
                            <ul className="job-tag">
                              <li>
                                <Link to="#" title={idx.job_type}>{idx.job_type}</Link>
                              </li>
                            </ul>

                          </div>
                          <div className="job-footer-right">
                            <div className="price">
                              <p >
                                <span className="text-dark"> {idx.minimum_salary}
                                  {(idx.minimum_salary) && '-'}
                                  {idx.maximum_salary}/month</span>
                              </p>
                            </div>
                            <button className='send_resume'>Send</button>
                          </div>
                        </div>
                        <Link
                          title=''
                          to="#"
                          className="jobtex-link-item"
                          tabIndex="0"
                        ></Link>
                      </div>
                    </div>

                  ))

                }
                {(opportunityData.length >= 12) && (
                  <div className="col-md-12" id="show_more_button">
                    <div className="wrap-button">

                      {
                        showButton && <button className="load-more-button" onClick={paginateData}>Load More</button>
                      }
                      {
                        !showButton &&
                        <div className="spinner" />
                      }
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="wrap-button">
                    {/* <Button2 title="Load More" link="#" /> */}
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="row wow fadeInUp animation-tab job-tab-item">
                <div className="no-vacancy-container">
                  <div className="no-vacancy-message">
                    <h5>Right now there is no vacancy for the Mediator, so we will update you when there will be vacancy</h5>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="wrap-button">
                    {/* <Button2 title="Load More" link="#" /> */}
                  </div>
                </div>
              </TabPanel>
              <TabPanel className="row wow fadeInUp animation-tab job-tab-item">
                <div className="no-vacancy-container">
                  <div className="no-vacancy-message">
                    <h5>Right now there is no vacancy for the Premium, so we will update you when there will be vacancy</h5>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="wrap-button">
                    {/* <Button2 title="Load More" link="#" /> */}
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </section>
    </>
  )

}

export default Find_work_component