import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function skeleton() {
    return (
        <div>
            <div className="title-partner">
                <p><Skeleton style={{ width: "80px", height: "80px", borderRadius: "50%" }} /></p>
                <p>
                    <Skeleton style={{ width: "80px" }} />
                </p>
            </div>
        </div>
    )
}

export default skeleton