import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import { sendUserData } from "../../../slice/postSlice";
import { storeTokenOnLocalStorage } from "../../../utils/helper";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


const LinkedInOAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState('');

  // const LINKEDIN_CALLBACK_URL = "http://192.168.1.58:8000/admin/auth/linkedin/callback";
  // const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(LINKEDIN_CALLBACK_URL)}&scope=openid+profile+email&state=jobaboard`;

  const LINKEDIN_CALLBACK_URL = process.env.REACT_APP_LOCAL_URL + process.env.REACT_APP_ROUTES_LOGIN;
  const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${LINKEDIN_CALLBACK_URL}&scope=openid+profile+email&state=jobaboard`;
  /** CallBack */
  useEffect(() => {
    // console.log('L-21','test');
    handleLinkedInCallback();
  },[linkedinOAuthURL]);

  const handleLinkedInCallback = () => {
    const queryString = window.location.search;
    // console.log('queryString',queryString);

    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    // console.log('L-28',code);
   
    if (code) handleLogin(code);
  };

  const handleLogin = async (code) => {
    try {
     
      const urlencoded = new URLSearchParams();
      urlencoded.append("register_by", 'candidate');
      urlencoded.append("oauth_type", 'linkedin');
      urlencoded.append("access_token", code);
      urlencoded.append("redirect_uri", LINKEDIN_CALLBACK_URL);
      
     
      const response = await fetch("http://192.168.1.57:8000/api/social-login-with-access-token", {
        method: "POST",
        body: urlencoded
      });
      
    
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      

      const result = await response.json();
      
      if (result?.data?.token) {

        localStorage.setItem('items', result.data.token);
        if(localStorage.getItem('items')){
          // console.log('History Length:', window.history.length);
          if (window.history.length > 2) {
            window.history.back();
          } else {
            navigate(process.env.REACT_APP_ROUTES_HOME);
          }
          }
        
      }
    
      // console.log(result);
      
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  
 

  return (
    <>
      <Link to={linkedinOAuthURL} className="">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" width="50" height="40" viewBox="0 0 48 48">
          <path fill="#0078d4" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5	V37z"></path><path d="M30,37V26.901c0-1.689-0.819-2.698-2.192-2.698c-0.815,0-1.414,0.459-1.779,1.364	c-0.017,0.064-0.041,0.325-0.031,1.114L26,37h-7V18h7v1.061C27.022,18.356,28.275,18,29.738,18c4.547,0,7.261,3.093,7.261,8.274	L37,37H30z M11,37V18h3.457C12.454,18,11,16.528,11,14.499C11,12.472,12.478,11,14.514,11c2.012,0,3.445,1.431,3.486,3.479	C18,16.523,16.521,18,14.485,18H18v19H11z" opacity=".05"></path><path d="M30.5,36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198c-1.295,0-1.935,0.912-2.243,1.677	c-0.082,0.199-0.071,0.989-0.067,1.326L25.5,36.5h-6v-18h6v1.638c0.795-0.823,2.075-1.638,4.238-1.638	c4.233,0,6.761,2.906,6.761,7.774L36.5,36.5H30.5z M11.5,36.5v-18h6v18H11.5z M14.457,17.5c-1.713,0-2.957-1.262-2.957-3.001	c0-1.738,1.268-2.999,3.014-2.999c1.724,0,2.951,1.229,2.986,2.989c0,1.749-1.268,3.011-3.015,3.011H14.457z" opacity=".07"></path><path fill="#fff" d="M12,19h5v17h-5V19z M14.485,17h-0.028C12.965,17,12,15.888,12,14.499C12,13.08,12.995,12,14.514,12	c1.521,0,2.458,1.08,2.486,2.499C17,15.887,16.035,17,14.485,17z M36,36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698	c-1.501,0-2.313,1.012-2.707,1.99C24.957,25.543,25,26.511,25,27v9h-5V19h5v2.616C25.721,20.5,26.85,19,29.738,19	c3.578,0,6.261,2.25,6.261,7.274L36,36L36,36z"></path>
        </svg></Link>{" "}
    </>
  );
}

export default LinkedInOAuth;
