import { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {

const token = localStorage.getItem('items');

    if(token){
        return <Outlet />
    }
  

    return <Navigate to={process.env.REACT_APP_ROUTES_LOGIN} />;
};

export default PrivateRoutes;
