import React,{useEffect, useState} from 'react'
import banner from '../../../assets/images/singlejob.jpg'
import { useDispatch } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import Header from '../company/company-header'

function Privacy_policy() {
  const dispatch = useDispatch();
  const [privacy_policy_data,setPrivacy_policy_data] = useState([])
  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_WEBSITE_STATIC_PAGE_API_URL+'/privacy_policy';
      const payload ={}
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload : payload}))
        .then((action) => {
          const updatedPrivacyData = action.payload;

          if (updatedPrivacyData) {
            setPrivacy_policy_data(updatedPrivacyData.data);
          } else {
            setPrivacy_policy_data([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);

  return (  
    <>
  
  <Header  title="Privacy Policy" description="" contactus_header="contactus_header"/>

    <section className="term-section">
        <div className='container mt-5'>
        <div dangerouslySetInnerHTML={{ __html: privacy_policy_data.value}} />

        </div>
       

    </section>
    </>
  )
}

export default Privacy_policy