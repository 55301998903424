import React, { useEffect, useState } from 'react'
import OpportunityCategory from './opportunityCategory';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import { Link } from "react-router-dom";
import SkeletonComponent from "./skeleton";


function Opportunity({ currentItemsHome }) {
  const dispatch = useDispatch();

  const url = process.env.APP_URL;

  const [showSkeletonComponent, setShowSkeletonComponent] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [Opportunity, setOpportunity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLetter, setSelectedLetter] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(currentItemsHome ? currentItemsHome : 12);
  const [urlData, setUrlData] = useState('');
  const opportunity_type_url = process.env.REACT_APP_ROUTES_OPPORTUNITY_TYPE;


  useEffect(() => {
    const fetchData = () => {
      if (isLoading) return;

      setIsLoading(true);
      const url = window.location.pathname;
      
      if (url == opportunity_type_url) {
        setUrlData(url);
      }
      // console.log("url 33", url, opportunity_type_url, urlData);

      let payload = { page: currentPage, per_page: itemsPerPage, order_by: 'name', order_by_type: 'asc' };

      dispatch(getDataFromApi({ API_URL: process.env.REACT_APP_OPPORTUNITY_API_URL, payload: payload }))
        .then((action) => {
          if (action?.payload && action?.payload?.status) {
            if (action.payload.status) {
              if (action?.payload?.data?.total) {
                setTotalItems(action?.payload?.data?.total);
              }
              if (action?.payload?.data?.items) {
                setIsLoading(false);
                setOpportunity(prevData => [...prevData, ...action.payload.data.items]);
              } else if (action?.payload?.data?.data) {
                setIsLoading(false);
                setOpportunity(prevData => [...prevData, ...action.payload.data.data]);
              }
            } else {
              setOpportunity([]);
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setShowSkeletonComponent(false);
        });
    }
    fetchData();
  }, [dispatch, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginateData = () => {
    setCurrentPage(currentPage + 1);
  }
  function fetchSortedData(e) {
    setSelectedLetter(e);
  }
  return (
    <>
      {Opportunity.length > 0 &&

        <section className="tf-slider sl7 over-flow-hidden opportunityType">
          <div className="tf-container">
            <div className="tf-title style-2 d-flex flex-sm-row flex-column">
              <div className="group-title mt-5 ">
                <h2 className='section-title h1'>Opportunity Types</h2>
                <p>Recruitment Made Easy in 100 seconds</p>
              </div>
              {!urlData && (
                <Link to={opportunity_type_url} className='mt-5 tf-button all-company' title='All Categories' >All Categories
                  <span className="icon-arrow-right2"></span>
                </Link>
              )}
            </div>
            <div className='all-category'>
              
            </div>
          

            <OpportunityCategory currentItems={Opportunity} selectedLetter={selectedLetter} urlData={urlData} showSkeletonComponent={isLoading} fetchSortedData={fetchSortedData} />
          

            {(urlData && currentPage < totalPages && !selectedLetter) && (
              <div className="col-md-12" id="show_more_button">
                <div className="wrap-button">
                  <button className='load-more-button' onClick={paginateData}>Load More</button>
                </div>
              </div>
            )}
          </div>
        </section>
      }
    </>
  )
}

export default Opportunity