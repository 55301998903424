import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function skeleton() {
  return (
    <div className="col-lg-3">
    <div className="features-job" style={{height:'150px',width:"300px"}}>
      <div className="job-archive-header" style={{height:'100px'}}>
        <div className="inner-box">
          <div className="logo-company">
          <Skeleton circle style={{height:"70px",width:"70px"}} />
          </div>
          <div className="box-content">
            <h3>
            <Skeleton />
            <Skeleton style={{width:"150px"}}/>
            <Skeleton style={{width:"150px"}}/>
            <Skeleton style={{width:"150px"}}/>
            </h3>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default skeleton