import React from 'react'
import { Value } from 'sass';

function Breadcrumb({name,breadcrumb}) {  
  // if(name){
  //   const itemListElements = Object.entries(name).map(([key, value], index) => ({
  //     "item": `${base_url+find_jobs+value.company_name}`
  //   }));
  // }
  // console.log('l-10',breadcrumb);
  const breadcrumbElements = breadcrumb[0] ? Object.keys(breadcrumb[0]).map((key, index, array) => ({
    "@type": "ListItem",
    "position": index + 1,
    "item": [
      ...(index > 0 ? array.slice(0, index).filter(item => item !== null) : []),
      key
    ]
  })) : [];
  
  


  
  
  const breadcrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "name":"BreadcrumbList",
    "itemListElement": breadcrumbElements
  };
  

  
  

  return (
    <>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
    __html: JSON.stringify(breadcrumbList)
  }} />

   
  </>
  )
}

export default Breadcrumb