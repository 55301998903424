import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css/bundle";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';

function Testimonials(props) {
  const dispatch = useDispatch();
  const [testimonial, setTestimonial] = useState([]);
  const [showFullReview, setShowFullReview] = useState({});

  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_TESTIMONIALS_API_URL;
      const payload = {}
      console.log('L-17', dynamicUrl);
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
        .then((action) => {
          const updatedOpportunityData = action.payload;

          if (updatedOpportunityData) {
            console.log('l-33', updatedOpportunityData.data);
            setTestimonial(updatedOpportunityData.data);
          } else {
            setTestimonial([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);

  const [dataBlock] = useState({
    title: "What Our Clients Are Saying",
    text: "Showing Companies Based On Reviews And Recent Job Openings",
  });

  const swiperRefLocal = useRef();

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  const toggleShowMore = (id) => {
    setShowFullReview((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <section className='mt-5'>
      <div className="wrap-testimonials over-flow-hidden">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tf-title style-2">
                {testimonial.length > 0 &&
                  <div className="group-title company-title">
                    <h2 className="text-center">{dataBlock.title}</h2>
                    <p className="text-center">{dataBlock.text}</p>
                  </div>
                }
              </div>
            </div>
            <div className="col-lg-12 wow fadeInUp" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <Swiper
                ref={swiperRefLocal}
                spaceBetween={30}
                slidesPerView={3}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={false}
                breakpoints={{
                  375: {
                    slidesPerView: 1,
                  },
                  425: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2.5,
                  },
                  1024: {
                    slidesPerView: 3.5,
                  },
                }}
              >
                {testimonial.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="wd-testimonials">
                      <div>
                        <p className="description">
                          {showFullReview[item.id] ? item.review : item.review.substring(0, 200)}
                          {item.review.length > 200 && (
                            <>
                              {!showFullReview[item.id] && <span>....</span>}
                              <button
                                className="btn testimonial-show-more"
                                onClick={() => toggleShowMore(item.id)}
                              >
                                {showFullReview[item.id] ? 'Show Less' : 'Show More'}
                              </button>
                            </>
                          )}
                        </p>
                      
                      </div>

                      <div className="author-group">

                        <div className="infor">
                          <p>{item.reviewer_name}</p>
                          <ul className="rating d-flex">
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.94418 8.42155L0.367213 6.2883C-0.11917 5.89933 0.094156 5.11714 0.711378 5.02896L4.36635 4.86612L5.92719 0.953019C6.03598 0.736138 6.25713 0.599609 6.49961 0.599609C6.74209 0.599609 6.96324 0.736849 7.07203 0.953019L8.63286 4.86612L12.2878 5.02896C12.9051 5.11714 13.1184 5.89933 12.632 6.2883L10.055 8.42155L10.7583 12.5864C10.8394 13.1545 10.2492 13.5798 9.73647 13.3231L6.49961 11.2659L3.26275 13.3224C2.74935 13.5791 2.15986 13.1538 2.24092 12.5857L2.94418 8.42155Z"
                                fill="#FFB321"
                              />
                            </svg>
                          </li>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.94418 8.42155L0.367213 6.2883C-0.11917 5.89933 0.094156 5.11714 0.711378 5.02896L4.36635 4.86612L5.92719 0.953019C6.03598 0.736138 6.25713 0.599609 6.49961 0.599609C6.74209 0.599609 6.96324 0.736849 7.07203 0.953019L8.63286 4.86612L12.2878 5.02896C12.9051 5.11714 13.1184 5.89933 12.632 6.2883L10.055 8.42155L10.7583 12.5864C10.8394 13.1545 10.2492 13.5798 9.73647 13.3231L6.49961 11.2659L3.26275 13.3224C2.74935 13.5791 2.15986 13.1538 2.24092 12.5857L2.94418 8.42155Z"
                                fill="#FFB321"
                              />
                            </svg>
                          </li>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.94418 8.42155L0.367213 6.2883C-0.11917 5.89933 0.094156 5.11714 0.711378 5.02896L4.36635 4.86612L5.92719 0.953019C6.03598 0.736138 6.25713 0.599609 6.49961 0.599609C6.74209 0.599609 6.96324 0.736849 7.07203 0.953019L8.63286 4.86612L12.2878 5.02896C12.9051 5.11714 13.1184 5.89933 12.632 6.2883L10.055 8.42155L10.7583 12.5864C10.8394 13.1545 10.2492 13.5798 9.73647 13.3231L6.49961 11.2659L3.26275 13.3224C2.74935 13.5791 2.15986 13.1538 2.24092 12.5857L2.94418 8.42155Z"
                                fill="#FFB321"
                              />
                            </svg>
                          </li>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.94418 8.42155L0.367213 6.2883C-0.11917 5.89933 0.094156 5.11714 0.711378 5.02896L4.36635 4.86612L5.92719 0.953019C6.03598 0.736138 6.25713 0.599609 6.49961 0.599609C6.74209 0.599609 6.96324 0.736849 7.07203 0.953019L8.63286 4.86612L12.2878 5.02896C12.9051 5.11714 13.1184 5.89933 12.632 6.2883L10.055 8.42155L10.7583 12.5864C10.8394 13.1545 10.2492 13.5798 9.73647 13.3231L6.49961 11.2659L3.26275 13.3224C2.74935 13.5791 2.15986 13.1538 2.24092 12.5857L2.94418 8.42155Z"
                                fill="#FFB321"
                              />
                            </svg>
                          </li>
                          <li>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.94418 8.42155L0.367213 6.2883C-0.11917 5.89933 0.094156 5.11714 0.711378 5.02896L4.36635 4.86612L5.92719 0.953019C6.03598 0.736138 6.25713 0.599609 6.49961 0.599609C6.74209 0.599609 6.96324 0.736849 7.07203 0.953019L8.63286 4.86612L12.2878 5.02896C12.9051 5.11714 13.1184 5.89933 12.632 6.2883L10.055 8.42155L10.7583 12.5864C10.8394 13.1545 10.2492 13.5798 9.73647 13.3231L6.49961 11.2659L3.26275 13.3224C2.74935 13.5791 2.15986 13.1538 2.24092 12.5857L2.94418 8.42155Z"
                                fill="#FFB321"
                              />
                            </svg>
                          </li>
                        </ul>
                        </div>
                      </div>

                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {testimonial.length > 0 &&
                <div className="testimonial-bottom-content">
                  <p className="total-review">5 / 5 Rating based on 226 Google Reviews</p>
                  <Link title="Add Your Review" to="https://www.google.com/search?hl=en-IN&gl=in&q=Spot+In+Job+Consultancy,+Office+-+535,+The+City+Center+Old+Amrapali+Cinema,+Raiya+Rd,+Rajkot,+Gujarat+360007&ludocid=5830664788228274443&lsig=AB86z5V0iQhFYTI6zZoQKGriarcb#lrd=0x3959ca27bcac697f:0x50eaaebb92b9790b,3" className='btn add-more-review ' target="_blank">
                    Add Your Review
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
