import React from 'react'
import Counter from '../../counter'
function Banner_counter() {
  return (
    <div>
    <section className="wd-banner-counter">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <div className="group-title-counter stc">
              <h3>The numbers don't lie</h3>
              <p>About 800+ new jobs everyday</p>
            </div>
            
          </div>
        </div>
      </div>
      <Counter />
    </section>
    </div>
  )
}

export default Banner_counter