import React from 'react'

function Job_posting_google_schema({data}) {
  const title=data.company_name;
  const address = data.company_street_address;
  const postalCode=data.postal_code;
  const country=data.country_name;
  const break_time=data.lunch_time_start+data.lunch_time_end;
  const total_time=data.working_time_start+data.working_time_end
  const working_hour=total_time - break_time;
  return (
    <>
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        "@context" : "https://schema.org/",
        "@type" : "JobPosting",
        "title" : {title},
        "description" : "<p>Google aspires to be an organization that reflects the globally diverse audience that our products and technology serve. We believe that in addition to hiring the best talent, a diversity of perspectives, ideas and cultures leads to the creation of better products and services.</p>",
        "identifier": {
          "@type": "PropertyValue",
          "name": "Google",
          "value": "1234567"
        },
        "datePosted" : "2017-01-18",
        "validThrough" : "2017-03-18T00:00",
        "employmentType" : "CONTRACTOR",
        "hiringOrganization" : {
          "@type" : "Organization",
          "name" : "Google",
        },
        "jobLocation": {
        "@type": "Place",
          "address": {
          "@type": "PostalAddress",
          "streetAddress":{address},
          "postalCode": {postalCode},
          "addressCountry": {country}
          }
        },
        "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "USD",
          "value": {
            "@type": "QuantitativeValue",
            "value": {working_hour},
            "unitText": "HOUR"
          }
        }
      })
    }}>

    </script>
  </>
  )
}

export default Job_posting_google_schema