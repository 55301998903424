import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import SkeletonComponent from './skeleton';
import { getDataFromApi } from "../../../slice/getDataSlice";
import { useDispatch } from 'react-redux';

function Company({ currentItems }) {
  const [loading, setLoading] = useState(true);
  const [filterLetter, setFilterLetter] = useState('');
  const [filteredLetters, setFilteredLetters] = useState({});
  const [displayFilterLetter, setDisplayFilterLetter] = useState(0);
  const [topCompanyData, setTopCompanyData] = useState({});
  const dispatch = useDispatch();
  const url = window.location.href;
  // console.log('L-12',url);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  });

  useEffect(() => {
    if (url == process.env.REACT_APP_LOCAL_URL + process.env.REACT_APP_ROUTES_FIND_COMPANY) {
      setDisplayFilterLetter(1);
    }
  }, [window.location.href])


  const filterByLetter = (letter) => {
    setFilterLetter(letter);
  };

  useEffect(() => {
    const lettersMap = {};
    currentItems.forEach((item) => {
      const firstLetter = item.company_name.charAt(0).toLowerCase();
      lettersMap[firstLetter] = true;
    });
    setFilteredLetters(lettersMap);
  }, [currentItems]);

  const filteredCompanies = filterLetter
    ? currentItems.filter((item) =>
      item.company_name.toLowerCase().startsWith(filterLetter.toLowerCase())
    )
    : topCompanyData && Array.isArray(topCompanyData) ? topCompanyData : currentItems;

  // Array of letters from A to Z
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const topCompany = () => {

    const dynamicUrl = process.env.REACT_APP_TOP_COMPANY_API_URL;
    // console.log('L-54',dynamicUrl);
    dispatch(getDataFromApi({ API_URL: dynamicUrl }))
      .then((action) => {
        const updatedOpportunityData = action.payload;
        // console.log('L-58',action.payload,updatedOpportunityData);
        if (updatedOpportunityData) {
          setTopCompanyData(updatedOpportunityData);

        } else {
          setTopCompanyData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  // console.log('L-70',topCompanyData);
  return (
    <>
      <div className="row wow fadeInUp ">

        <div className="col-lg-12">
          {(displayFilterLetter === 1) &&
            <div className='mb-5'>
              <div className="job-archive-footer d-flex justify-content-center align-items-start">
                <button
                className='d-none'
                  style={{ width: "100px", padding: "7px" }}
                  onClick={topCompany}
                >
                  Top 15
                </button>
                {alphabet.map((letter) => (
                  <button
                    key={letter}
                    style={{ width: "40px", padding: "7px" }}
                    className={filteredLetters[letter] ? '' : 'opacity-50'}
                    onClick={() => filterByLetter(letter)}
                  >
                    {letter.toLocaleUpperCase()}
                  </button>
                ))}

              </div>
            </div>
          }
        </div>
        <div className="col-lg-12 tf-tab">
          <div className="content-tab">
            <div className="inner">
              <div className="group-col-4">
                {!loading ? (
                  filteredCompanies.map((idx) => (
                     <div key={idx.id} className="employer-block style-2 cl3">
                     <div className="inner-box">
                       <div className="logo-company">
                         <img src={idx.company_logo_url} alt={idx.company_name} />
                       </div>
                       <div className="box-content">
                         <h3>
                           <Link to={`/company-details/${idx.company_slug}`} state={idx.company_slug} title={idx.company_slug} className='company-name'>{idx.company_name}   &nbsp;
                           <span className="icon-bolt"></span></Link>
                         
                         </h3>
                         <p className="info">
                           <span className="icon-map-pin"></span>
                           {idx.company_street_address}
                           {topCompanyData ? idx?.company_head_quarter_address?.company_street_address : idx.company_street_address}
                         </p>
                       </div>
                       <div className="button-readmore company-heart-icon">
                         {/* <span className="icon-heart"></span> */}
                         
                         <Link
                            className='btn-employer'
                              to={`/find-jobs/${idx.company_slug}`}
                              state={{ companySlug: idx.company_slug }}
                              title={idx.company_slug}
                            >
                             {idx.opportunity_type} Job Opening
                            </Link>
                       </div>
                     </div>
                   </div>
                  ))
                ) : (
                  <>
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Company;
