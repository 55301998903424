import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/images/logo.png";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Collapse } from "react-collapse";

function Header() {
  const contacts = JSON.parse(process.env.REACT_APP_CONTACTS || '[]');
  const [isMobile, setIsMobile] = useState(false);
  const [toggle, setToggle] = useState({ key: "", status: false });
  const [isShowMobile, setShowMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const tokenData = localStorage.getItem("items");

  // Handles mobile view state changes
  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 990px)");

    const handleMobileChange = (event) => setIsMobile(event.matches);

    mobileMediaQuery.addEventListener("change", handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    const company_slug = sessionStorage.getItem("slug");
    const opportunity_slug = sessionStorage.getItem("opportunity_name");
    const Opportunity_type_slug = sessionStorage.getItem("Opportunity_type_slug");

    const excludedPaths = [
      process.env.REACT_APP_ROUTES_BLOG_DETAILS,
      `/company-details/${company_slug}`,
      `/featured-jobs-details/${opportunity_slug}`,
      `/featured-jobs-details/${Opportunity_type_slug}`,
      `/opportunity-type/${Opportunity_type_slug}`
    ];

    if (!excludedPaths.includes(pathname)) handleMobile();

    return () => mobileMediaQuery.removeEventListener("change", handleMobileChange);
  }, [pathname]);

  // Handles toggling of sub-menus
  const handleToggle = (key) => {
    setToggle((prevToggle) => ({
      key,
      status: prevToggle.key !== key ? true : !prevToggle.status,
    }));
  };

  // Toggles the mobile menu visibility
  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    if (isShowMobile && isMobile) {
   
      getMobile.classList.add("modal-menu--open");
    } else {
      getMobile.classList.remove("modal-menu--open");
    }
  };

  // Handles user logout
  const handleLogOut = (event) => {
    event.preventDefault();
    localStorage.removeItem("items");
    sessionStorage.removeItem('login_user_desigantion');
    sessionStorage.removeItem('login_user_id');
    navigate(process.env.REACT_APP_ROUTES_LOGIN);
  };

  // Sets user dropdown visibility based on token data
  const [userDropDown, setUserDropdown] = useState('');
  useEffect(() => {
    setUserDropdown(tokenData);
  }, [tokenData]);

  const menuItems = [
    // { path: process.env.REACT_APP_ROUTES_HOME, label: "Home" },
    { path: process.env.REACT_APP_ROUTES_FIND_JOBS, label: "Jobs" },
    { path: process.env.REACT_APP_ROUTES_FIND_COMPANY, label: "Company" },
    { path: process.env.REACT_APP_ROUTES_FIND_WORK, label: "Work" },
    { path: process.env.REACT_APP_ROUTES_FIND_TALENT, label: "Talent" },

    // { path: process.env.REACT_APP_ROUTES_CONTACT_US, label: "Contact Us" },
  ];

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to={process.env.REACT_APP_ROUTES_HOME} title="Spot In Job Consultancy">
                <img className="Spot In Job Consultancy" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
            </TabList>

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      {menuItems.map((item) => (
                        <li key={item.label} className="menu-item menu-item-has-children-mobile current-item">
                          <Link
                            title={item.label}
                            to={item.path}
                            onClick={() => handleToggle(item.label.toLowerCase())}
                          >
                            {item.label}
                          </Link>
                          <Collapse isOpened={toggle.key === item.label.toLowerCase()}>
                            <ul className="sub-menu-mobile" style={{ display: `${toggle.key === item.label.toLowerCase() ? "block" : "none"}` }}>
                              {/* Add sub-menu items here if necessary */}
                            </ul>
                          </Collapse>
                        </li>
                      ))}
                      {(tokenData !== undefined) && (
                        <li className="menu-item menu-item-has-children-mobile">
                          <Link
                            title="User Details"
                            to={process.env.REACT_APP_ROUTES_PROFILE}
                            onClick={() => handleToggle("userDetails")}
                          >
                            User Details
                          </Link>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          <div className="header-customize-item button">
            {(tokenData !== undefined) ? (

              <button type="submit" className="mr-3" onClick={handleLogOut}>
                Logout
              </button>
            ) : (
              <Link to={process.env.REACT_APP_ROUTES_LOGIN} title="Login">Login</Link>
            )}
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <h6>
                  <Link to={`tel:${contacts[0]}`} title={contacts[0]}>{contacts[0]}</Link>
                  <br />
                  <Link to={`tel:${contacts[1]}`} title={contacts[1]}>{contacts[1]}</Link>
                </h6>
              </div>
            </div>

            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li><Link to="#" title="Facebook"><i className="icon-facebook"></i></Link></li>
                <li><Link to="#" title="Linkedin"><i className="icon-linkedin2"></i></Link></li>
                <li><Link to="#" title="Twitter"><i className="icon-twitter"></i></Link></li>
                <li><Link to="#" title="Pinterest"><i className="icon-pinterest"></i></Link></li>
                <li><Link to="#" title="Instagram"><i className="icon-instagram1"></i></Link></li>
                <li><Link to="#" title="Youtube"><i className="icon-youtube"></i></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <header id="header" className="header header-default">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div className="sticky-area-wrap">
                <div className="header-ct-left">
                  <div id="logo" className="logo">
                    <Link to={process.env.REACT_APP_ROUTES_HOME} title="Spot In Job Consultancy">
                      <img className="site-logo" id="trans-logo" src={logo} alt="Spot In Job Consultancy" />
                    </Link>
                  </div>
                </div>

                <div className="header-ct-center">
                  <div className="nav-wrap">
                    <nav id="main-nav" className="main-nav desk-main-nav">
                      <ul id="menu-primary-menu" className="menu">
                        {menuItems.map((item) => (
                          <li key={item.label} className="menu-item menu-item-has-children">
                            <Link to={item.path}>{item.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>

                <div className="header-ct-right st-1 mb-3">
                  <div className="header-customize-item button">
                    <Link to={process.env.REACT_APP_ROUTES_UPLOADCV} className="tf-button style-1" title="Upload CV">
                      Upload CV
                    </Link>
                  </div>
                  <div className="ml-5 profilepage">
                    {userDropDown && (
                      <div className="nav-wrap">
                        <nav id="main-nav" className="main-nav desk-main-nav">
                          <ul id="menu-primary-menu" className="menu">
                            <li className="ml-5 menu-item menu-item-has-children">
                              <Link
                                title="User Details"
                                to="#"
                                className="p-2 border rounded-circle profile-a-tag"
                              >
                                <FontAwesomeIcon icon={faUser} />
                              </Link>
                              <ul className="sub-menu st1">
                                <li className="nav-sub">
                                  <Link to={process.env.REACT_APP_ROUTES_PROFILE} title="Profile">Profile</Link>
                                </li>
                                <li className="nav-sub">
                                  <NavLink to={process.env.REACT_APP_ROUTES_CHANGE_PASSWORD} title="Change Password">
                                    Change Password
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink onClick={handleLogOut}>
                                    Logout
                                  </NavLink>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    )}
                  </div>

                  <div className="header-customize-item button">
                    {!tokenData && (
                      <Link to={process.env.REACT_APP_ROUTES_LOGIN} className="tf-button style-1" title="Login">
                        Login
                      </Link>
                    )}
                  </div>
                </div>
                <div className="nav-filter" onClick={handleMobile}>
                  <div className="nav-mobile">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
