import React, { useState } from "react";
import Search from "../components/main/search";
import Find_work_component from "../components/main/find_work";
import Trusted_by_employee from "../components/main/trusted_by_employee";
import MetaTag from "../components/main/meta-tag";
import Breadcrumb from "../components/google_schema/breadcrumb";
import Company_Header from "../components/main/company/company-header";
import Commingsoon from "../components/main/comming_soon";


function Find_work() {
  const base_url = process.env.REACT_APP_LOCAL_URL;
  const home = process.env.REACT_APP_ROUTES_HOME;
  const find_work = process.env.REACT_APP_ROUTES_FIND_WORK;

  const breadcrumb = [
    {
      home: base_url + home,
      find_work: base_url + find_work,
    },
  ];
  const [displayData, setDisplaydata] = useState(false);
  return (
    <>

      <div className="wrapper">
        <MetaTag
          title="Find Work"
          name="description"
          content="Explore exciting job opportunities at YourCompany. Browse and apply for jobs in various fields and locations. Start your career with us!"
        />
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <Commingsoon />
      {
        
        displayData &&
        <>
          <Company_Header title="Find Work" description="Resume-Library is a true performance-based job board. Enjoy custom hiring products and access to up to 10,000 new resume registrations daily, with no subscriptions or user licences." />
          <div className="mt-5">

            <Find_work_component />
            <Trusted_by_employee />
          </div>
        </>
      }
    </>
  );
}

export default Find_work;
