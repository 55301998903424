import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Routes, Route, useNavigate} from 'react-router-dom';
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonComponent from "./skeleton";
function OpportunityCategory({
  currentItems,
  fetchSortedData,
  urlData,
}) {
  const [loading, setLoading] = useState(true);
  let filteredAndSortedData = currentItems;
 
  const navigate = useNavigate();
  useEffect(()=>{
    localStorage.setItem('parent_slug', 0)
  },[])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    },200);
    
  }, [currentItems]);


let parent_slug = 0;
let parent_id = 0;
  const handleClick = (slug,id) =>{

     parent_slug = slug;
     parent_id = id;
    
    navigate(
      `/find-university/${parent_slug}`,
      {
        state: {
         parent_slug:parent_slug,
         id:parent_id
        }
      }
    );
  }

  return (
    <>
      <div className="">
        <div className="row">
          <div className=" mb-5 w-100" style={{ marginLeft: "50px" }}>
      
          </div>
        </div>
      </div>
      <div className="row">
  
        {!loading ? (
          filteredAndSortedData.map((idx) => (
            <div key={idx.id} className="col-md-2 course_box"  onClick={()=>handleClick(idx.course_slug,idx.id)}
            >
              <div className="w-100 d-flex justify-content-center">
                <div className="" style={{ height: "70px", width: "70px" }}>
                  <div>
                    <img
                      src={idx.logo_url}
                      alt={idx.course_name}
                      className="ml-2 p-1 avtarImage"
                      style={{ height: "70px", width: "100px" }}
                     
                    />
                  </div>
                </div>
              </div>
              <p className="text-center ">{idx.course_name}</p>
            </div>
          ))
        ) : (
          <>
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
            <SkeletonComponent />
          </>
        )}

        {currentItems.length == 5 && (
          <div className="col-md-2 course_box">
            <div className="w-100 d-flex justify-content-center">
              <div className="">
                <div
                  className="view_more"
                  
                >
                  <Link to={process.env.REACT_APP_ROUTES_FIND_UNIVERSITY} title="View More">View More</Link>
                 
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OpportunityCategory;
