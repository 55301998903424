import React, { useEffect, useState } from 'react'
import banner from '../../../assets/images/singlejob.jpg'
import { useDispatch } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import Header from '../company/company-header';

function Disclaimer() {
  const dispatch = useDispatch();
  const [disclaimerData, setDisclaimerData] = useState([])
  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_WEBSITE_STATIC_PAGE_API_URL + '/terms-of-use';
      const payload = {}
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload: payload }))
        .then((action) => {
          const updatedDisclaimerData = action.payload;

          if (updatedDisclaimerData) {
            setDisclaimerData(updatedDisclaimerData.data);
            // console.log('l-20',window.location.href,process.env.REACT_APP_LOCAL_URL + process.env.REACT_APP_ROUTES_DISCLAIMER);
          } else {
            setDisclaimerData([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
  }, [dispatch]);

  return (
    <>
      <Header title="Terms of Use" description="" contactus_header="contactus_header" />

      <section className="term-section">
        <div className='container mt-5 static_page'>
          <h3>Terms of Use</h3>
          <div dangerouslySetInnerHTML={{ __html: disclaimerData.value }} />

        </div>


      </section>
    </>
  )
}

export default Disclaimer