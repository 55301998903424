import React, { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from 'react-router-dom';


function Reset_Password() {
  const [confirm_password, setConfirm_password] = useState();
  const [resetPassword, setResetPassword] = useState();
const [showPass, setShowPass] = useState(false);


  const handleInputChange = (e) => {
    setResetPassword(e.target.value);
  }
  const handleSubmit = (e) =>{
    e.preventDefault();

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (passwordRegex.test(resetPassword)) {
      if (resetPassword === confirm_password) {
        withReactContent(Swal).fire({
          title: <i>Success!!</i>,
          text: "Your Password is Updated"
        });
      }
      else {
        withReactContent(Swal).fire({
          title: <i>Error!!!!</i>,
          text: "Password And Confirm Password Not Match"
        });
      }
    }
    else {
      withReactContent(Swal).fire({
        title: <i>Error!!!!</i>,
        text: "Password must contain at least one lowercase letter, one uppercase letter, one number and one special character"
      });
    }
  }

  return (
    <section className="account-section tf-slider sl7 over-flow-hidden opportunityType">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <h4>Reset password?</h4>

            <form onSubmit={handleSubmit}>
              <div className="ip">
                <label>
                  Password<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    name='resetPassword'
                    placeholder="Enter Password"
                    onChange={handleInputChange}
                  />
                  <Link
                    title={showPass ? "Show Password" : "Hide Password"}
                    className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"
                      }`}
                    id="password-addon"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <div className="ip">
                <label>
                  Confirm Password<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    name='confirm_password'
                    placeholder="Enter Confirm Password"
                    onChange={(e) => setConfirm_password(e.target.value)}
                  />
                   <Link
                     title={showPass ? "Show Password" : "Hide Password"}
                    className={`password-addon ${showPass ? "icon-eye" : "icon-eye-off"
                      }`}
                    id="password-addon"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <button className='wd-form-login-button' type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reset_Password