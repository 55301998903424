
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const getDataFromApi = createAsyncThunk('company/getDataFromApi', async ({ API_URL, payload }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  let url = API_URL;
  console.log('L-6', payload.length, API_URL);

  let queryString = Object.entries(payload).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join("&");
  url += '?' + queryString;


  const response = await fetch(baseUrl + url, {
    method: 'GET',
  })
  const jsonData = await response.json();
  return jsonData;
});

export const getDataSlice = createSlice({
  name: 'getdata',
  initialState: { companyData: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDataFromApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataFromApi.fulfilled, (state, action) => {
        state.loading = false;
        state.companyData = action.payload;

      })
      .addCase(getDataFromApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default getDataSlice.reducer;
