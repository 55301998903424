import React from "react";
import Search from "../components/main/search";
import MetaTag from "../components/main/meta-tag";
import Breadcrumb from "../components/google_schema/breadcrumb";
import Find_Course from "../components/main/university/index"; 


function Find_University() {
  const base_url = process.env.REACT_APP_LOCAL_URL;
  const home = process.env.REACT_APP_ROUTES_HOME;
  const find_university = process.env.REACT_APP_ROUTES_FIND_UNIVERSITY;


  const breadcrumb = [
    {
      home: base_url + home,
      find_university: base_url + find_university,
    },
  ];

  return (
    <>
     
      <div className="wrapper">
        <MetaTag
        title="Find University"
          name="description"
          content="Find The best University for Various UG, PG and Diploma Programs"
        />
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <div>
       
        <Find_Course/>
      </div>
    </>
  );
}

export default Find_University;
