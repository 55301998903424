import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SelectLocation from "../featured_jobs/drop-down";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Dropdown from '../featured_jobs/drop-down';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromApi } from '../../../slice/getDataSlice';
import useGeoLocation from "../useGeoLocation";
import { useNavigate } from "react-router";
import Select from 'react-select';


function FormSearch({ props, display }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(null);
  const [searchUrl, setSearchUrl] = useState('');
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [slug, setSlug] = useState();
  const [Opportunity, setOpportunity] = useState([]);

  const [stateid, setStateid] = useState(0);
  const blog_url = process.env.REACT_APP_ROUTES_BLOG_URL;

  const changeStateId = (e) => {
    setStateid(e.target.value);
  }
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const fetchData = () => {
    const url = window.location.href;

    if (url === blog_url) {
      setSearchUrl(url);
    }
  }
  // useEffect(() => {
  //   fetchData()
  // }, []);

  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_STATE_API_URL;
      const payload = {country_id: 101}
      dispatch(getDataFromApi({ API_URL: dynamicUrl, payload:payload }))
        .then((action) => {
          const updatedStateData = action.payload;
          if (updatedStateData) {
            setState(updatedStateData.data);
          } else {
            setState([]);
          }
        })
        .catch((error) => {
        });
    }
    const fetchDesignation = () => {

      const dynamicUrl = process.env.REACT_APP_OPPORTUNITY_API_URL;

      dispatch(getDataFromApi({ API_URL: dynamicUrl }))
        .then((action) => {
          const updatedOpportunityData = action.payload;
          // console.log('l-66',action.payload); 
          if (updatedOpportunityData) {
            setOpportunity(updatedOpportunityData.data);
            // console.log(updatedOpportunityData.data);
          } else {
            setOpportunity([]);
          }
          // console.log('l-58', Opportunity);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
    fetchData();
    fetchDesignation();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = () => {
      const dynamicUrl = process.env.REACT_APP_CITY_API_URL;
      const payload ={
        country_id: 101,
        state_id: stateid
      }
      if (stateid > 0) {

        dispatch(getDataFromApi({ API_URL: dynamicUrl, payload:payload }))
          .then((action) => {
            const updatedCityData = action.payload;
            if (updatedCityData) {
              setCity(updatedCityData.data);
            } else {
              setCity([]);
            }
          })
          .catch((error) => {
          });
      }
    }
    if (stateid) {

      fetchData();
    }
  }, [dispatch, stateid]);

  const filterSearch = () => {
      // console.log('l-81', slug?.designation);
   sessionStorage.setItem('search_slug', slug?.designation)
     if(slug?.designation){
       navigate(`/opportunity-type/${slug?.designation}`)
     }
    
  }


  return (
    <>
      <section className="form-sticky-slider stc1">
        <div className="tf-container">


          <div className="job-search-form inner-form-map st1">
            <form>
              <div className="row-group-search">

                <div className="form-group-1">
                  <div className="input-filter-search">

                    <Select
                      aria-label="Search"
                      placeholder={<div>Search Designation</div>}
                      options={Opportunity && Opportunity.map(option => ({
                        value: option.slug,
                        label: option.name,
                      }))}
                      name="designation"
                      className="search"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      maxMenuHeight={155}
                      onChange={(selectedOption) => {
                        setSlug({
                          designation: selectedOption.value,
                        });
                      }}
                    />

                  </div>
                </div>
                {
                  (!searchUrl) && (
                    <>

                      <div className="form-group-2">
                        {/* <span className="icon-map-pin"></span> */}
                        <select aria-label="Search" name="state" required id="state" className="react-dropdown select-location" onChange={changeStateId}>
                          <option value="">Select State</option>
                          {
                            state.map((items) =>

                              <option key={items.id}
                                value={items.id}
                                className="react-dropdown select-location"

                              >
                                {items.name}
                              </option>
                            )

                          }
                        </select>
                      </div>
                      {
                        (stateid > 0) &&
                        <div className="form-group-2" >
                          {/* <span className="icon-map-pin"></span> */}
                          <select aria-label="Search" name="city" id="city" className="react-dropdown select-location">
                            {(stateid > 0) ? <option value="">Select City</option> : <option value="">First Select State</option>}
                            {
                              city && city.map((items) =>
                                <option key={items.toString()}
                                  value={items.id}
                                  className="react-dropdown select-location"

                                >
                                  {items.name}
                                </option>
                              )

                            }
                          </select>
                        </div>
                      }


                    </>
                  )
                }
                <div className="form-group-4">
                  <button type="button" className="btn btn-find search-btn" onClick={filterSearch} aria-label="search"> <FontAwesomeIcon icon={faSearch} /></button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </section>
    </>
  );
}

export default FormSearch;
